import { httpRequest } from '../utils';

export const getSupportTeamMembersAsync = async (token) => {
    const response = await httpRequest('/api/groupDelegation/support-team-members', 'get', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const addSupportTeamMemberAsync = async (email, isTeamAdmin, token) => {
    const response = await httpRequest('/api/groupDelegation/support-team-members', 'post', { email, isTeamAdmin },  { Authorization: `Bearer ${token}` });
    return response;
};

export const revokeSupportTeamMemberAsync = async (userId, token) => {
    const response = await httpRequest(`/api/groupDelegation/support-team-members/${userId}`, 'delete', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const getGroupDelegationsAsync = async (token) => {
    const response = await httpRequest('/api/groupDelegation', 'get', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const getGroupDelegationAsync = async (id, token) => {
    const response = await httpRequest(`/api/groupDelegation/${id}`, 'get', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const addGroupDelegationAsync = async (delegatorEmail, token) => {
    const response = await httpRequest('/api/groupDelegation', 'post', { email : delegatorEmail },  { Authorization: `Bearer ${token}` });
    return response;
};

export const cancelPendingGroupDelegationAsync = async (id, token) => {
    const response = await httpRequest(`/api/groupDelegation/${id}/cancel`, 'post', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const revokeGroupDelegationAsync = async (id, token) => {
    const response = await httpRequest(`/api/groupDelegation/${id}/revoke`, 'delete', null,  { Authorization: `Bearer ${token}` });
    return response;
};


export const resendGroupDelegationRequestAsync = async (id, token) => {
    const response = await httpRequest(`/api/groupDelegation/${id}/resend`, 'post', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const approveGroupDelegationAsync = async (id, token) => {
    const response = await httpRequest(`/api/groupDelegation/${id}/approve`, 'post', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const rejectGroupDelegationAsync = async (id, token) => {
    const response = await httpRequest(`/api/groupDelegation/${id}/reject`, 'post', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const getOrganizationDomainsAsync = async (token) => {
    const response = await httpRequest(`/api/groupDelegation/domains`, 'get', null,  { Authorization: `Bearer ${token}` });
    return response;
};
