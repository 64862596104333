import React, { useCallback, createContext, useContext, useState } from 'react';
import { toast } from '@galilee/lilee';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import {
    addGroupDelegationAsync,
    addSupportTeamMemberAsync,
    getGroupDelegationsAsync,
    getGroupDelegationAsync,
    getSupportTeamMembersAsync,
    revokeGroupDelegationAsync,
    revokeSupportTeamMemberAsync,
    cancelPendingGroupDelegationAsync,
    approveGroupDelegationAsync,
    rejectGroupDelegationAsync,
    resendGroupDelegationRequestAsync,
    getOrganizationDomainsAsync
} from 'actions/GroupDelegation';
import { addDelegationAsync, deleteDelegationAsync, getDelegationsAsync } from 'actions/Delegation';
import { GroupDelegationStatus } from 'utils/constants';

const DelegationContext = createContext();

function useDelegationHook () {
    const {
        actions: { getToken },
    } = useTrackNTrace();

    const [isLoading, setIsLoading] = useState(false);
    const [delegations, setDelegations] = useState([]);
    const [groupDelegations, setGroupDelegations] = useState([]);
    const [supportTeamMembers, setSupportTeamMembers] = useState([]);
    const [domains, setDomains] = useState([]);

    const getGroupDelegations = useCallback(async () => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            const result = await getGroupDelegationsAsync(token);
            setGroupDelegations(result);

        } catch (err) {
            toast.error('Failed to get group delegations.');
        } finally {
            setIsLoading(false);
        }
    }, [getToken]);

    const getGroupDelegation = useCallback(async (id) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            return await getGroupDelegationAsync(id, token);
        } catch (err) {
            throw new Error(err);
        } finally {
            setIsLoading(false);
        }
    }, [getToken]);

    const approveGroupDelegation = useCallback(async (id) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            return await approveGroupDelegationAsync(id, token);
        } catch (err) {
            toast.error('Failed to approve group delegation.');
        } finally {
            setIsLoading(false);
        }
    }, [getToken]);

    
    const rejectGroupDelegation = useCallback(async (id) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            return await rejectGroupDelegationAsync(id, token);
        } catch (err) {
            toast.error('Failed to reject group delegation.');
        } finally {
            setIsLoading(false);
        }
    }, [getToken]);

    const addGroupDelegation = useCallback(async (delegatorEmail) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            const delegationId = await addGroupDelegationAsync(delegatorEmail, token);
            const exists = groupDelegations.some(d => d.id === delegationId);

            const updatedDelegations = exists ? groupDelegations.map(d => d.id === delegationId ? {...d, status : GroupDelegationStatus.PENDING } : d) : [...groupDelegations, { id: delegationId, delegatorEmail, status: GroupDelegationStatus.PENDING }];
            setGroupDelegations(updatedDelegations);
        } catch (err) {
            toast.error(`Failed to add group delegation.`);
        } finally {
            setIsLoading(false);
        }
    }, [groupDelegations, getToken]);

    const revokeGroupDelegation = useCallback(async (id) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            await revokeGroupDelegationAsync(id, token);
            const updatedDelegations = groupDelegations.map(d => d.id === id ? { ...d, status : GroupDelegationStatus.REVOKED} : d);
            setGroupDelegations(updatedDelegations);
        } catch (err) {
            toast.error(`Failed to revoke group delegation.`);
        } finally {
            setIsLoading(false);
        }
    }, [groupDelegations, getToken]);

    const cancelPendingGroupDelegation = useCallback(async (id) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            await cancelPendingGroupDelegationAsync(id, token);
            const updatedDelegations = groupDelegations.map(d => d.id === id ? { ...d, status : GroupDelegationStatus.CANCELLED} : d);
            setGroupDelegations(updatedDelegations);
        } catch (err) {
            toast.error(`Failed to cancel group delegation.`);
        } finally {
            setIsLoading(false);
        }
    }, [groupDelegations, getToken]);

    const resendGroupDelegationRequest = useCallback(async (id) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            await resendGroupDelegationRequestAsync(id, token);
            const updatedDelegations = groupDelegations.map(d => d.id === id ? { ...d, status : GroupDelegationStatus.PENDING} : d);
            setGroupDelegations(updatedDelegations);
        } catch (err) {
            toast.error(`Failed to resend group delegation.`);
        } finally {
            setIsLoading(false);
        }
    }, [groupDelegations, getToken]);

    const getSupportTeamMembers = useCallback(async () => {
        try {
            setIsLoading(true);
            const token = await getToken();
            if (!token) return;

            const result = await getSupportTeamMembersAsync(token);
            setSupportTeamMembers(result);

        } catch (err) {
            toast.error('Failed to get support team members.');
        } finally {
            setIsLoading(false);
        }
    }, [getToken]);

    const addSupportTeamMember = useCallback(async (email, isTeamAdmin) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);

            const teamMemberId = await addSupportTeamMemberAsync(email, isTeamAdmin, token);
            const existingMember = supportTeamMembers.find(m => m.id === teamMemberId);

            const updatedTeamMembers = existingMember ? supportTeamMembers.map(m => m.id === teamMemberId ? {...m, isTeamAdmin : isTeamAdmin } : m) : [...supportTeamMembers, { id : teamMemberId,  isTeamAdmin, email }];
            setSupportTeamMembers(updatedTeamMembers);
        } catch (err) {
            toast.error(`Failed to add support team member.`);
        } finally {
            setIsLoading(false);
        }
    }, [supportTeamMembers, getToken]);

    const revokeSupportTeamMember = useCallback(async (id) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            await revokeSupportTeamMemberAsync(id, token);
            setSupportTeamMembers([...supportTeamMembers.filter(m => m.id !== id)]);
        } catch (err) {
            toast.error(`Failed to revoke support team member.`);
        } finally {
            setIsLoading(false);
        }
    }, [supportTeamMembers, getToken]);

    const getOrganizationDomains = useCallback(async () => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            const result = await getOrganizationDomainsAsync(token);
            setDomains(result);
        } catch (err) {
            toast.error(`Failed to revoke support team member.`);
        } finally {
            setIsLoading(false);
        }
    }, [getToken]);

    const getDelegations = useCallback(async () => {
        try {
            const token = await getToken();
            if (!token) return;
    
            setIsLoading(true);
            const result = await getDelegationsAsync(token);
            setDelegations(result);
        } catch (err) {
            toast.error('Failed to get delegations.');
        } finally {
            setIsLoading(false);
        }
    }, [getToken]);

    const addDelegation = useCallback(async (delegateeEmail) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            const id = await addDelegationAsync(token, delegateeEmail);
            setDelegations([...delegations, { id, email: delegateeEmail }]);
        } catch (error) {
            toast.error('Failed to add delegations.');
        } finally {
            setIsLoading(false);
        }
    },[getToken, delegations]);

    const deleteDelegation = useCallback(async (delegationId) => {
        try {
            const token = await getToken();
            if (!token) return;

            setIsLoading(true);
            await deleteDelegationAsync(token, delegationId);
            setDelegations([...delegations.filter(i => i.id !== delegationId)]);
        } catch (error) {
            toast.error('Failed to delete delegations.');
        } finally {
            setIsLoading(false);
        }
    }, [getToken, delegations]);

    return {
        isLoading,
        delegations,
        groupDelegations,
        supportTeamMembers,
        domains,
        addGroupDelegation,
        revokeGroupDelegation,
        cancelPendingGroupDelegation,
        resendGroupDelegationRequest,
        addSupportTeamMember,
        revokeSupportTeamMember,
        getGroupDelegations,
        getGroupDelegation,
        getSupportTeamMembers,
        approveGroupDelegation,
        rejectGroupDelegation,
        getOrganizationDomains,
        getDelegations,
        addDelegation,
        deleteDelegation
    };
}

export const useDelegation = () => useContext(DelegationContext);

export default function DelegationProvider(props) {
    const value = useDelegationHook();

    return <DelegationContext.Provider value={value} {...props} />
}