import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Flex, Text } from '@galilee/lilee';

const PaginationContainer = styled(Flex)`
    align-items: center;
    gap: ${(p) => p.theme.space[4]};
    flex-wrap: wrap;
`;


export const BoldText = styled(Text)`
    font-weight: 500;
`;

export const StyledLink = styled.a`
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    color: #1C27DA;
`;

export const Pagination = ({count, currentPage, onClick}) => {
    if(!count) return null;
    
    const pages = new Array(count).fill();
    return (
        <PaginationContainer>
            <BoldText mr="4">Page: </BoldText>
            {
                pages.map((_, idx) => { 
                    const page = idx + 1;
                    return page === currentPage ? <BoldText key={page}>{page}</BoldText> 
                    : <StyledLink key={page} onClick={() => onClick({ page })}>{page}</StyledLink>
                })
            }           
        </PaginationContainer>
    );
};

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired
}