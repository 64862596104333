/* eslint-disable max-len */
import React from 'react';
import Helmet from 'react-helmet';

const ThirdPartScripts = () => {
    const isLocal = window.location.hostname === 'localhost';
    const isDevelop = window.location.host.includes('develop.orion.galilee.com.au');
    const isStaging = window.location.host.includes('staging.orion.galilee.com.au');
    const isProduction = window.location.host === 'orion.galilee.com.au';
    return (
        <Helmet>
            {!isLocal && (
                <script>
                    {`
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1752483,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
                </script>
            )}

      {(isDevelop || isStaging) && (
      <script>
        {`
          window.KANISWIDGET_CONFIG = {
            WIDGET_URL : "https://kaniswidgetdev.z8.web.core.windows.net",
            EMBED_TOKEN : "3da60ea0-a308-4f23-be35-96891e7cf6ed"
          }; 
          (function () {
            const tag = document.createElement('script');
            tag.type = "application/JavaScript";
            tag.async = true;
            tag.src = window.KANISWIDGET_CONFIG.WIDGET_URL + '/js/widget-loader.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(tag, s);
          })();
        `}
      </script>
      )}

      {(isProduction) && (
      <script>
        {`
          window.KANISWIDGET_CONFIG = {
            WIDGET_URL : "https://kaniswidgetprod.z8.web.core.windows.net",
            EMBED_TOKEN : "e1169307-cdfb-44c8-a2aa-79997287c161"
          };  
          (function () {
            const tag = document.createElement('script');
            tag.type = "application/JavaScript";
            tag.async = true;
            tag.src = window.KANISWIDGET_CONFIG.WIDGET_URL + '/js/widget-loader.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(tag, s);
          })();
        `}
      </script>
      )}
        </Helmet>
    );
};

export default ThirdPartScripts;
