import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/Layout';
import Logo from 'components/Logo';
import { Flex, H3, Button } from '@galilee/lilee';
import styled from 'styled-components/macro';
import ReactPlayer from 'react-player/vimeo';
import { Routes } from 'utils/constants';

const Title = styled(H3)`
    margin-left:  ${(p) => p.theme.space[6]};
    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        display: none;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        margin-top: ${(p) => p.theme.space[2]};
        font-size: ${(p) => p.theme.fontSizes[4]};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[4]}) {
        font-size: ${(p) => p.theme.fontSizes[5]};
    }
`;

const HeaderContainer = styled(Flex)`
    height: 200px;
    justify-content: center;
    align-items: center;
    padding-left: 84px;
    padding-right: 36px;
    padding-top: 44px;
    padding-bottom: 44px;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[1]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        padding-left: 36px;
        padding-right: 36px;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        padding-left: 24px;
        padding-right: 24px;
        flex-direction: row;
    }
`;


const Header = () => (
    <>
        <HeaderContainer bg="base" color="white">
            <Flex justifyContent="space-between" mb="0">
                <Logo />
                <Title>
                    New Feature: Delegate access to Orion
                </Title>
            </Flex>
        </HeaderContainer>
    </>
);

const DelegationInstruction = () => {
    const [showButton, setShowButton] = useState(false);
    const history = useHistory(); 
    const videoUrl ="https://player.vimeo.com/video/878122903?h=a145cfc8e2&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479";
    return (
        <Layout header={<Header />}>
            <Flex flexDirection="column" alignItems="center" mt="6">
                <ReactPlayer
                    url={videoUrl}
                    controls
                    onReady={() => setShowButton(true)}
                />
                {showButton && <Button mt="8" color="tertiary" onClick={() => history.push(Routes.delegate)}>
                    Delegate now
                </Button>
                }
            </Flex>
        </Layout>
    );
};

export default DelegationInstruction;
