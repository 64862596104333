import React from 'react';
import TrackNTraceAdminProvider from 'state/track/TrackNTraceAdminProvider';
import CallbackList from '../components/CallbackList';

const Admin = () => (
    <TrackNTraceAdminProvider>
        <CallbackList />
    </TrackNTraceAdminProvider>
);

export default Admin;
