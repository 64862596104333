import React, { useState } from 'react';
import { Flex, Button, H3, Text, Input, Box, H6, TextArea, ResponsiveGroup, mediaQueries, FieldError, Column } from '@galilee/lilee';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import isWeekend from 'date-fns/isWeekend';

const { greaterThan, lessThan } = mediaQueries;

const Container = styled(Flex)`
    background: white;
`;

const InputWrapper = styled(Box)`
    padding: ${(p) => p.theme.space[5]} 0;
`;

const OperationHourWrapper = styled(Box)`
    align-self: center;
`;

const CancelRequestWrapper = styled(Flex)`
    min-width: 100%;
    max-width: 700px;
    padding: ${(p) => p.theme.space[6]};
    margin-top: ${(p) => p.theme.space[6]};
    flex-direction: row;
    border: 1px solid ${(p) => p.theme.colors.base20};
    border-radius: ${(p) => p.theme.radii[3]};
    ${greaterThan[0]`
        min-width: 512px;
    `}
    ${lessThan[0]`
        .QRCode { 
            display: none;
        }
    `}

    .QRCode {
        justify-content: center;
        align-items: center;
    }

    .QRCode > img {
        width: 100%;
        max-width: 200px;
    }
`;

const StyledButton = styled(Button)`
    background: ${(p) => p.theme.colors.success};
`

function nth(n) {
    return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
}

const PhoneSupport = ({ matterId }) => {
    const {
        callback,
        actions: { sendCallbackRequest, cancelCallbackRequest },
    } = useTrackNTrace();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [note, setNote] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);

    const handleRequestButtonClick = async () => {
        if (!phoneNumber) {
            setIsInvalid(true);
        }
        if (isInvalid || !phoneNumber) return;
        const matterUrl = matterId ? `/${matterId}` : '';

        await sendCallbackRequest(phoneNumber, note, name, matterUrl);
    };

    const handleValidation = () => {
        const phoneRegex = /^\d{7,14}$/;
        const result = phoneRegex.test(phoneNumber);
        setIsInvalid(!result);
    };

    const outOfOperatingHours = (date) => {
        if (!date) return false;
        const weekend = isWeekend(date);
        if (weekend) return true;

        const hour = date.getHours();
        return hour < 8 || hour > 18;
    };

    return (
        <Container flexDirection="column" alignItems="center" mx="auto" px="6" py="9">
            <H3 textAlign="center" mb="6">
                Phone support
            </H3>
            <Text textAlign="center" mb="6" maxWidth="500px">
                If you can’t find what you need or require further information you can request a callback from one of our team members.
            </Text>
            {!callback && (
                <Flex flexDirection="column" mt="6" minWidth={['100%', '100%', '100%','550px']}>
                    <InputWrapper>
                        <H6 mt="5" mb="5">
                            Your Name
                        </H6>
                        <Input fontSize="2" value={name} onChange={(e) => setName(e.target.value)} />
                    </InputWrapper>
                    <InputWrapper>
                        <H6 mt="5" mb="5">
                            Your Phone number
                        </H6>
                        <Input fontSize="2" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} onBlur={handleValidation} />
                        {isInvalid && <FieldError error="Invalid phone number" />}
                    </InputWrapper>
                    <InputWrapper>
                        <H6 mt="5" mb="5">
                            Add a note
                        </H6>
                        <TextArea
                            id="note"
                            name="note"
                            rows="4"
                            placeholder="Please specify the matter IDs and the nature of your enquiry."
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </InputWrapper>
                    <ResponsiveGroup fullWidthBreakpointIndex={0} mt="8">
                        <StyledButton mr="6" fullWidth onClick={handleRequestButtonClick}>
                            Request a call
                        </StyledButton>
                        <OperationHourWrapper mt={[6, 0]}>
                            <Text>8am to 6pm AEST</Text>
                            <Text>Monday to Friday</Text>
                        </OperationHourWrapper>
                    </ResponsiveGroup>
                </Flex>
            )}
            {!!callback && (
                <CancelRequestWrapper mx="auto">
                    <Column>
                        <Text fontWeight="medium">Callback request received</Text>
                        {outOfOperatingHours(new Date()) ? (
                            <Text my="4">There is currently nobody available to call you. Our operating hours are between 8am and 6pm, Monday to Friday.</Text>
                        ) : (
                            <Text my="4">
                                You are currently&nbsp;
                                <strong>{`${callback.positionInQueue}${nth(callback.positionInQueue)}`}</strong>
                                &nbsp;in the queue.
                            </Text>
                        )}
                        <Text>
                            {outOfOperatingHours(new Date())
                                ? 'A team member will call you at the nearest opportunity.'
                                : 'Please keep your phone close by and have the ring volume turned up.'}
                        </Text>
                        <Text fontWeight="medium" my="4">
                            Who's Calling?
                        </Text>
                        <Text>Scan the QR code to add Galilee to your contacts so that you know it's us.</Text>
                        <ResponsiveGroup fullWidthBreakpointIndex={0} mt="6">
                            <StyledButton onClick={() => cancelCallbackRequest()}>Cancel Callback</StyledButton>
                        </ResponsiveGroup>
                    </Column>
                    <Column className="QRCode">
                        <img src="qr.png" alt="qr code"/>
                    </Column>
                </CancelRequestWrapper>
            )}
        </Container>
    );
};

PhoneSupport.propTypes = {
    matterId: PropTypes.string,
};

PhoneSupport.defaultProps = {
    matterId: '',
};

export default PhoneSupport;
