import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from '@galilee/lilee';
import { useHistory } from 'react-router';
import { requireAccessCodeAsync, getTokenAsync, createCallbackAsync, getCallbackStatusAsync, deleteCallbacksAsync, getMattersAsync, searchAsync, exportSearchResultsAsync, getHtmlContentAsync, logoutUserAsync } from 'actions/Track';
import useLocalStorage from 'hooks/useLocalStorage';
import jwtDecode from 'jwt-decode';
import { Routes, Role } from 'utils/constants';

const TT_LOCAL_STORAGE_PREFIX = '_orion_tt';

const CallbackStatus = {
    Queued: 'Queued',
    Active: 'Active',
    Finalised: 'Finalised',
};

const hasExpired = (token) => {
    const decodedToken = jwtDecode(token);
    return +decodedToken.exp * 1000 < new Date().getTime();
};

const normalizeUser = (user) => {
    const roles = typeof(user.role) === "string" ? [user.role] : user.role;
    const normalizedUser = {
        id: user.UserId,
        email: user.EmailId,
        roles: roles,
    };

    return normalizedUser;
};

const TrackNTraceContext = createContext();

function useTrackNTraceHook() {
    const [loginPendingUser, setLoginPendingUser] = useLocalStorage(`${TT_LOCAL_STORAGE_PREFIX}_login_pending_user`, null);
    const [credential, setCredential, removeCredential] = useLocalStorage(`${TT_LOCAL_STORAGE_PREFIX}_credential`, undefined);
    const [callback, setCallback] = useLocalStorage(`${TT_LOCAL_STORAGE_PREFIX}_callback_request`, null);

    const [user, setUser] = useState(undefined);
    const [selectedUser, setSelectedUser] = useState(null);
    const [matters, setMatters] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [searchResults, setSearchResults] = useState(undefined);

    const [reload, setReload] = useState(0);
    const [returnUrl, setReturnUrl] = useState("");

    const history = useHistory();

    const callbackRef = useRef();
    const setCallbackRef = useRef();
    const setCredentialRef = useRef(setCredential);

    const getToken = useCallback(
        async () => {
            if (!credential) {
                toast.error(`Please log in to continue.`);
                return null;
            }
            if (hasExpired(credential.token)) {
                toast.error(`Your session has expired. Please login again.`);
                removeCredential();
                return null;
            }

            return credential.token;
        },
        [credential, removeCredential]
    );

    const getMatters = useCallback(
        async () => {
            try {
                const token = await getToken();
                if (!token) return;

                setIsLoading(true);
                const { searchResults } = await getMattersAsync(token);
                setMatters([...searchResults]);
            } catch (err) {
                if (`${err}` === `Unauthorized`) history.push(Routes.login.base);
                else toast.error(`Failed to load matters. ${err}`);
            } finally {
                setIsLoading(false);
            }
        },
        [getToken, history]
    );

    //Remove old local storage item. This useEffect hook can be removed later.  
    useEffect(() => {
        const oldCredentialItem =  localStorage.getItem(`${TT_LOCAL_STORAGE_PREFIX}_credentials`);
        if (!!oldCredentialItem) localStorage.removeItem(`${TT_LOCAL_STORAGE_PREFIX}_credentials`);
    }, []);

    useEffect(() => {
        if (!setCredentialRef.current) {
            setCredentialRef.current = setCredential;
        }
    }, [setCredential]);

    useEffect(() => {
        if (!callbackRef.current) {
            callbackRef.current = callback;
        }
    }, [callback]);

    useEffect(() => {
        if (!setCallbackRef.current) {
            setCallbackRef.current = setCallback;
        }
    }, [setCallback]);

    useEffect(() => {
        if (!credential || hasExpired(credential.token)) return;

        const normalizedUser = normalizeUser(jwtDecode(credential.token))
        setUser(normalizedUser);
    }, [credential]);

    useEffect(() => {
        async function updateCallbackStatus(callbackId) {
            try {
                const result = await getCallbackStatusAsync(callbackId);
                if (result && result.status === CallbackStatus.Queued) {
                    setCallbackRef.current(result);
                    return;
                }

                setCallbackRef.current(null);
            } catch (err) {
                setCallbackRef.current(null);
            }
        }

        if (!callbackRef.current || !callbackRef.current.callBackId) return;
        updateCallbackStatus(callbackRef.current.callBackId);
    }, [reload]);

    const requireAccessCode = async (email) => {
        try {
            setIsLoading(true);
            await requireAccessCodeAsync(email);
            setLoginPendingUser(email);
            history.push(Routes.login.code);
        } catch (e) {
            toast.error(`Failed to get verification code.${e}`);
        } finally {
            setIsLoading(false);
        }
    };

    const login = async (email, code) => {
        try {
            setIsLoading(true);
            const result = await getTokenAsync(email, code);

            setLoginPendingUser(null);

            const newCredential = {
                id: email,
                token: result.token,
                refreshToken: result.refreshToken,
            };

            setCredential(newCredential);

            const userInfo = jwtDecode(newCredential.token);
            // If there is only one role. the backend return the role as a string. otherwise an array.
            const roles = typeof(userInfo.role) === "string" ? [userInfo.role] : userInfo.role;
            const currentUserIsAdmin = roles && roles.includes(Role.Admin);

            if (currentUserIsAdmin) {
                history.push(Routes.admin.base);
                return;
            }

            const toUrl = `/${returnUrl || ""}`;
            history.push(toUrl);
        } catch (e) {
            toast.error(`Failed to login. ${e}`);
        } finally {
            setIsLoading(false);
        }
    };

    const logout = async (email) => {
        const token = await getToken();
        if (!token) return;

        await logoutUserAsync(email, token); 

        removeCredential();
        setUser(undefined);
        setMatters([]);
        history.push(Routes.login.base);
    };

    const sendCallbackRequest = async (phoneNumber, note, name, matterUrl) => {
        try {
            const callbackId = await createCallbackAsync(phoneNumber, note, name, matterUrl);
            const result = await getCallbackStatusAsync(callbackId);
            setCallback(result);
        } catch (err) {
            toast.error(err);
        }
    };

    const cancelCallbackRequest = async () => {
        if (!callback) return;

        try {
            deleteCallbacksAsync('', callback.callBackId);
            setCallback(null);
        } catch (err) {
            toast.error(err);
        }
    };

    const search = async (params) => {
        try {
            const token = await getToken();
            if (!token) return;

            const response = await searchAsync(params, token);
            setSearchResults({
                matters: response.advancedSearchResults || [],
                total: response.total,
                t24AccountsTotal: response.t24AccountsTotal,
                ultracsAccountsTotal: response.ultracsAccountsTotal,
                currentPage: params.searchPage
            });
        }
        catch (e) {
            toast.error(`Failed to search matters.${e}`);
        }
    }

    const exportSearchResults = async (params) => {
        try {
            const token = await getToken();
            if (!token) return;

            const blob = await exportSearchResultsAsync(params, token);
            const blobUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', 'SearchResultExport.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();

        }
        catch (e) {
            toast.error(`Failed to export search results. ${e}`);
        }
    }

    const getHtmlContent = async (id, type) => {
        try {
            const token = await getToken();
            if (!token) return;

            const res = await getHtmlContentAsync(id, type, token);
            return res.htmlString;
        }
        catch (e) {
            toast.error(`Failed to get html content. ${e}`);
        }
    }

    const isAuthenticated = !!credential && !hasExpired(credential.token);
    const isAdmin = user?.roles.includes(Role.Admin);

    const actions = {
        requireAccessCode,
        login,
        logout,
        getToken,
        setSelectedUser,
        sendCallbackRequest,
        cancelCallbackRequest,
        setReload,
        search,
        getMatters,
        exportSearchResults,
        getHtmlContent,
        setReturnUrl
    };

    return {
        isLoading,
        isAuthenticated,
        isAdmin,
        user,
        loginPendingUser,
        selectedUser,
        matters,
        callback,
        searchResults,
        actions,
    };
}

export const useTrackNTrace = () => useContext(TrackNTraceContext);

export default function TrackNTraceProvider(props) {
    const value = useTrackNTraceHook();
    return <TrackNTraceContext.Provider value={value} {...props} />;
}
