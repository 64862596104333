import { httpRequest, queryBuilder } from '../utils';
export const requireAccessCodeAsync = async (emailAddress,) => {
    await httpRequest('/auth/2fa', 'post', { emailAddress }, null);
};

export const getTokenAsync = async (email, code) => {
    const response = await httpRequest('/auth/token', 'post', { email, twoFactorCode: code }, null);
    return response;
};

export const refreshTokenAsync = async (token, refreshToken) => {
    const response = await httpRequest('/auth/refresh', 'post', { token, refreshToken }, null);
    return response;
};

export const getMe = async (token) => {
    const response = await httpRequest('/api/users/me', 'get', null, { Authorization: `Bearer ${token}` });
    return response;
};

export const logoutUserAsync = async (emailAddress, token) => {    
    await httpRequest('/auth/logout', 'post', { emailAddress }, { Authorization: `Bearer ${token}` });
};

export const createCallbackAsync = async (phoneNumber, note, name, matterURL) => {
    const response = await httpRequest(
        '/api/createCallback',
        'post',
        {
            phoneNumber,
            note,
            name,
            matterURL,
        },
        null
    );
    return response;
};

export const getCallbacksAsync = async (token) => {
    const response = await httpRequest('/api/getCallbacks', 'get', null, { Authorization: `Bearer ${token}` });
    return response;
};

export const getCallbackStatusAsync = async (callbackId) => {
    const response = await httpRequest(`/api/getCallbackStatus/${callbackId}`, 'get', null, null);
    return response;
};

export const pickupCallbacksAsync = async (emailAddress, callBackId, token) => {
    const response = await httpRequest('/api/pickupCallback', 'post', { emailAddress, callBackId }, { Authorization: `Bearer ${token}` });
    return response;
};

export const deleteCallbacksAsync = async (emailAddress, callBackId) => {
    const response = await httpRequest('/api/deleteCallback', 'post', { emailAddress, callBackId }, null);
    return response;
};

export const finalizeCallbacksAsync = async (emailAddress, callBackId, token) => {
    const response = await httpRequest('/api/finaliseCallback', 'post', { emailAddress, callBackId }, { Authorization: `Bearer ${token}` });
    return response;
};

export const releaseCallbacksAsync = async (emailAddress, callBackId, token) => {
    const response = await httpRequest('/api/releaseCallback', 'post', { emailAddress, callBackId }, { Authorization: `Bearer ${token}` });
    return response;
};

export const setCallbackUnansweredAsync = async (emailAddress, callBackId, token) => {
    const response = await httpRequest(
        '/api/setCallbackUnanswered',
        'post',
        { emailAddress, callBackId },
        { Authorization: `Bearer ${token}` },
    );
    return response;
};

export const getMattersAsync = async (token) => {
    const response = await httpRequest('/api/trackNTrace', 'get', null, { Authorization: `Bearer ${token}` });
    return response;
};

export const getMatterJourneyAsync = async (matterId, token) => {
    const response = await httpRequest(`/api/trackNTrace/${matterId}`, 'get', null, { Authorization: `Bearer ${token}` });
    return response;
};


export const getMatterDetailsAsync = async (matterId, token) => {
    const response = await httpRequest(`/api/trackNTrace/details/${matterId}`, 'get', null, { Authorization: `Bearer ${token}` });
    return response;
}; 

export const searchAsync = async (searchParams, token) => {
    const queryString = queryBuilder(searchParams);
    const response = await httpRequest(`/api/trackNTrace/advancedSearch?${queryString}`, 'get', null, { Authorization: `Bearer ${token}` });
    return response;
}

export const downloadDocument = async (documentId, token) => {
    const response = await httpRequest(`/api/trackNTrace/document/${documentId}`, 'get', null, { Authorization: `Bearer ${token}` });
    return response;
};

export const exportSearchResultsAsync = async (searchParams, token) => {
    const queryString = queryBuilder(searchParams);
    const response = await httpRequest(`/api/trackNTrace/advancedSearchExport?${queryString}`, 'get', null, { Authorization: `Bearer ${token}` });
    return response;
}

export const getHtmlContentAsync = async (id, type, token) => {
    const response = await httpRequest(`/api/trackNTrace/htmlPage/${id}/${type}`, 'get', null, { Authorization: `Bearer ${token}` });
    return response;
}

export const addFileNoteAsync = async (matterId, fileNoteParams, token) => {        
    const response = await httpRequest(`/api/trackNTrace/addFileNote/${matterId}`, 'post', fileNoteParams, { Authorization: `Bearer ${token}` });    
    return response;
}