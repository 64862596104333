import React from 'react';
import PropTypes from 'prop-types';
import { CustomThemeProvider } from '@galilee/lilee';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from 'components/GlobalStyle';
import ErrorBoundary from 'components/ErrorBoundary';
import ApplicationProvider from './ApplicationProvider';
import { OrionTheme } from 'theme';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const RootProvider = ({ children }) => (
    <ApplicationProvider>
        <CustomThemeProvider theme={OrionTheme}>
            <BrowserRouter basename={baseUrl}>
                <GlobalStyle />
                <ErrorBoundary>{children}</ErrorBoundary>
            </BrowserRouter>
        </CustomThemeProvider>
    </ApplicationProvider>
);

RootProvider.defaultProps = {
    children: null,
};

RootProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default RootProvider;
