import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Flex, H1, PageLoading, Text, Button, HR, toast } from '@galilee/lilee';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import Layout from 'components/Layout';
import Container from 'components/Container';
import FourOhFour from 'components/FourOhFour';
import { DelegationHeader } from '../shared/delegation';
import { useDelegation } from 'state/DelegationProvider';
import { DelegationPanel, StyledBox } from '../shared/delegation';
import { GroupDelegationStatus, Role } from 'utils/constants';
import PhoneSupport from 'components/PhoneSupport';

const GroupDelegationBroker = () => {
    const { user } = useTrackNTrace();

    const { 
        isLoading,
        getGroupDelegation,
        approveGroupDelegation,
        rejectGroupDelegation
    } = useDelegation();

    const match = useRouteMatch();
    const delegationId = match?.params?.delegationId;

    const [delegation, setDelegation] = useState(undefined);
    const [errored, setErrored] = useState(false);
    const [delegationNotFound, setDelegationNotFound] = useState(false);

    useEffect(() => {
        async function fetchGroupDelegation () {
            if(isNaN(+delegationId)) return;
            try {
                const result = await getGroupDelegation(+delegationId);
                setDelegation(result);
                setErrored(false);
                setDelegationNotFound(false);
            } catch (err) {
                if (err.message === 'Not Found'){
                    setDelegationNotFound(true);
                } else {
                    toast.error('Failed to get group delegation.');
                    setErrored(true);
                }
            }
        }
        fetchGroupDelegation();
    }, [delegationId, getGroupDelegation]);

    const shouldDisableButton = isLoading || delegation?.status !== GroupDelegationStatus.PENDING;

    const handleGroupDelegationApprove = async () => {
        await approveGroupDelegation(+delegationId);
        setDelegation({ ...delegation, status: GroupDelegationStatus.APPROVED });
    }

    const handleGroupDelegationReject = async () => {
        await rejectGroupDelegation(+delegationId);
        setDelegation({ ...delegation, status: GroupDelegationStatus.REJECTED });
    }

    if (!user)
        return <PageLoading />;

    if (isNaN(+delegationId) || errored || !user?.roles.includes(Role.Broker))
        return <FourOhFour />

    return (
        <>
            {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
            <Layout header={<DelegationHeader title='Group delegation' />}>
                <Container center>
                    <DelegationPanel mx="auto">
                    <StyledBox>
                        {
                            !delegationNotFound && (
                                <>
                                <H1 fontSize="4" color="tertiary" mb="5">Approve or Reject Delegation</H1>
                                <Text mb="7" mx="auto" textAlign="left" fontSize="1">
                                    {delegation?.organisationName || ''} has requested access to your matters so that their admin or support staff can update your customers with progress on matters on your behalf.
                                </Text>
                                <Text mb="8" mx="auto" textAlign="left" fontSize="1">
                                    Note: delegation gives access to all active matters in Orion and any future matters. Do not grant access to anyone outside of your organisation.
                                </Text>
                                {
                                    delegation && [GroupDelegationStatus.CANCELLED, GroupDelegationStatus.REVOKED].includes(delegation.status) && (
                                    <>
                                        <HR mb="6"/>
                                        <Text mb="7" mx="auto" textAlign="left" fontSize="1">{`The request has been ${delegation.status.toLowerCase()} by the team admin.`}</Text>
                                    </>
                                )}
                                {
                                    delegation && [GroupDelegationStatus.APPROVED, GroupDelegationStatus.REJECTED, GroupDelegationStatus.PENDING].includes(delegation.status) && (<Flex mt="6">
                                    <Button mr="6" onClick={async () => await handleGroupDelegationApprove()} disabled={shouldDisableButton} color="tertiary">
                                        {delegation.status === GroupDelegationStatus.APPROVED ? 'Approved' : 'Approve'}
                                    </Button>
                                    <Button onClick={async () => await handleGroupDelegationReject()} disabled={shouldDisableButton} color="primary">
                                        {delegation.status === GroupDelegationStatus.REJECTED ? 'Rejected' : 'Reject'}
                                    </Button>
                                </Flex>
                                )}
                            </>
                        )}
                        {
                           delegationNotFound && (
                           <>
                                 <H1 fontSize="4" color="tertiary" mb="5">Invalid Email</H1>
                                 <Text mb="6" mx="auto" textAlign="left" fontSize="1">
                                    The request for delegation belongs to another email address.
                                </Text>
                                <Text mb="8" mx="auto" textAlign="left" fontSize="1">
                                    Please log in with the correct email address to approve or reject this delegation.
                                </Text>
                            </>
                        )}
                    </StyledBox>
                    </DelegationPanel>
                </Container>
            </Layout>
            <PhoneSupport />
        </>
    );
}

export default GroupDelegationBroker;