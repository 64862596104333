import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { P, Text } from '@galilee/lilee';
import ColinWentWrong from 'images/ColinWentWrong.png';
import ErrorPage from 'components/ErrorPage';

function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

const FakeLink = styled(Text)`
    display: inline-block;
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary};
    &:hover {
        color: ${(p) => p.theme.colors.primaryDark};
    }
`;

const Error = ({ error }) => {
    const message = isString(error) ? error : 'something went wrong';
    return (
        <ErrorPage image={ColinWentWrong} title={`Sorry, ${message}`}>
            <P>
                Even the latest and best technology can break sometimes.
            </P>
            <P>
                From here your best bet is to <FakeLink onClick={() => window.location.reload()}>reload the page</FakeLink> and try again.
            </P>
        </ErrorPage>
    );
};

Error.defaultProps = {
    error: null,
};

Error.propTypes = {
    error: PropTypes.string,
};

export default Error;
