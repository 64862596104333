import React, { createContext, useContext, useReducer } from 'react';
import { base64EncodeUnicode } from 'utils';
import { toast } from '@galilee/lilee';

const applicationContext = createContext();

const initialState = {
    disconnectionErrors: {},
    socketConnectionErrors: {},
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_APPLICATION_ERROR':
            return toast.error(action.payload);
        case 'SET_WEBSOCKET_DISCONNECTION_ERROR': {
            const id = base64EncodeUnicode(action.payload);
            return { ...state, disconnectionErrors: { ...state.disconnectionErrors, [id]: action.payload } };
        }
        case 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR': {
            const payloadId = base64EncodeUnicode(action.payload);
            const disconnectionErrors = {};
            Object.keys(state.disconnectionErrors).forEach((id) => {
                if (id !== payloadId) disconnectionErrors[id] = state.disconnectionErrors[id];
            });
            return { ...state, disconnectionErrors };
        }
        case 'SET_WEBSOCKET_ERROR': {
            const id = base64EncodeUnicode(action.payload);
            return { ...state, socketConnectionErrors: { ...state.socketConnectionErrors, [id]: action.payload } };
        }
        case 'REMOVE_WEBSOCKET_ERROR': {
            const payloadId = base64EncodeUnicode(action.payload);
            const socketConnectionErrors = {};
            Object.keys(state.socketConnectionErrors).forEach((id) => {
                if (id !== payloadId) socketConnectionErrors[id] = state.socketConnectionErrors[id];
            });
            return { ...state, socketConnectionErrors };
        }
        default:
            return state;
    }
};

const useApplicationHook = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return { state, dispatch };
};

export const useApplication = () => useContext(applicationContext);

const ApplicationProvider = (props) => {
    const value = useApplicationHook();
    return <applicationContext.Provider value={value} {...props} />;
};

export default ApplicationProvider;
