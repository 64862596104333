import React from 'react';
import PropTypes from 'prop-types';
import Error from 'components/Error';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    render() {
        const { hasError, error } = this.state;
        const { children } = this.props;
        if (hasError) {
            return <Error error={error} />;
        }

        return children;
    }
}

ErrorBoundary.defaultProps = {
    children: null,
};

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ErrorBoundary;
