import React, { useState } from 'react';
import { Flex, Button, Text, Panel, Input, Box, H6,toast, PageLoading } from '@galilee/lilee';

import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import styled from 'styled-components/macro';

const AccessCodePanel = styled(Panel)`
    max-width: 700px;
    margin-bottom: ${(p) => p.theme.space[8]};
    border-radius: ${(p) => p.theme.sizes[3]};
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding: 0px;
    }
`;

const AccessCodeFormWrapper = styled(Box)`
    padding-top: ${(p) => p.theme.space[6]};
    padding-bottom: ${(p) => p.theme.space[6]};
    padding-left: 48px;
    padding-right: 48px;
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const ResendEmailButton = styled(Box)`
    margin-top: ${(p) => p.theme.space[6]};
    font-size: ${(p) => p.theme.fontSizes[2]};
    font-weight: 500;
    color: ${(p) => p.theme.colors.tertiary};
    cursor: pointer;
    :hover {
        color: ${(p) => p.theme.colors.tertiaryDark};
    }
`;

const GobackButton = styled(Box)`
    margin-top: ${(p) => p.theme.space[2]};
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-weight: 500;
    color: ${(p) => p.theme.colors.tertiary};
    cursor: pointer;
    :hover {
        color: ${(p) => p.theme.colors.tertiaryDark};
    }
`;

const AccessCodeForm = () => {
    const { loginPendingUser, isLoading, actions } = useTrackNTrace();

    const { login, requireAccessCode } = actions;

    const [code, setCode] = useState('');

    const handleButtonClick = async () => {
        if (!code || !loginPendingUser) return;
        await login(loginPendingUser, code);
    };

    const handleEmailResent = async () => {
        if (!loginPendingUser) return;
        await requireAccessCode(loginPendingUser);
        toast.success('Verification code sent.');
    };

    const handleGoBack = async () => {
        window.history.back();
    };

    return (
        <>
            {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
            <AccessCodePanel mx="auto">
                <AccessCodeFormWrapper>
                    <Text mt="5" mb="5" textAlign="center" fontSize="3" fontWeight="medium">
                        We&#8217;ve sent you an email with a unique code!
                    </Text>
                    <Text mb="8" mx="auto" textAlign="center">
                        Please find the code and enter it below to continue. It may take a few minutes to appear in your inbox.
                    </Text>
                    <H6 mt="5" mb="5">
                        6 Digit code
                    </H6>
                    <form>
                        <Input type="password" fontSize="2" onChange={(e) => setCode(e.target.value)} />
                    </form>
                    <Button mt="8" onClick={handleButtonClick} color="tertiary">
                        Continue
                    </Button>
                    <Flex flexDirection="column" alignItems="center" mt="10">
                        <Text fontSize="3" mb="6" fontWeight="medium" textAlign="center">
                            Can&#8217;t find the Email?
                        </Text>
                        <Text>Either resend the email or go back and check the email you entered</Text>
                        <ResendEmailButton onClick={handleEmailResent}>
                            Resend Email
                        </ResendEmailButton>
                        <GobackButton onClick={handleGoBack}>
                            Go Back
                        </GobackButton>
                    </Flex>
                </AccessCodeFormWrapper>
            </AccessCodePanel>
        </>
    );
};

export default AccessCodeForm;
