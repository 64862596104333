import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Label, Column, Icon, Box, Text } from '@galilee/lilee';
import styled from 'styled-components/macro';
import Dropdown from './Dropdown';

const Wrapper = styled(Box)`
  position: relative;
  width: 100%;
`;

const SelectField = ({ fieldConfig, noLabel, onChange, value }) => {
    const { name, displayName, options } = fieldConfig;
  
    const selectOptions =  useMemo(() => (options || []).map((opt,idx) => ({
        id: `${opt.name}_${idx}`,
        value: opt.value,
        label: <Text fontSize="1" fontWeight="medium">{opt.name}</Text>,
        controlLabel: <Text fontSize="0" fontWeight="medium" uppercase ellipsis>{opt.name}</Text>,
      })), [options]);

    return (
        <Column mb={[7, 7, 7, 9]}>
            {!noLabel && (
                <Label fontSize="0" color="base" textTransform="uppercase" htmlFor={name}>
                    {displayName}
                </Label>
            )}
            <Wrapper>
                <Dropdown
                    rightIcon={<Icon color="base" name="NavDown" size="12px"/>}
                    onChange={(val) => onChange(name, val.value)}
                    options={selectOptions}
                    value={value}
                />
            </Wrapper>
        </Column>
    );
};

SelectField.defaultProps = {
    noLabel: false,
    validate: null,
    value: ''
};

SelectField.propTypes = {
    noLabel: PropTypes.bool,
    fieldConfig: PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, value: PropTypes.any }))
    }).isRequired,
    onChange:  PropTypes.func.isRequired,
    value: PropTypes.any
};

export default SelectField;
