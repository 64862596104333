import { Flex, Hide, toast } from '@galilee/lilee';
import { StyledSubtitle, UnmarginedPanel, BackToTopLink, StyledP, ItemTitle } from "./index";
import { format } from 'date-fns';
import styled from 'styled-components/macro';
import {useState} from 'react';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import { getUTCDate } from 'utils'

const DocumentContainer = styled(Flex)`
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        flex-direction: column;
    }
`;

const Header = styled(Flex)`
    border-bottom: 1px solid ${(p) => p.theme.colors.base10};
    justify-content: space-between;
    margin-bottom: 0;
`;

const StyledLink = styled.a`
    font-size: ${(p) => p.theme.fontSizes[0]};
    color: ${(p) => p.theme.colors.link};
`
const DocumentsCard = ({ documents }) => {
    const [IsDownloading, setIsDownloading ] = useState(false);
    const { downloadDocumentAction } = useTrackNTraceMatter();

    const handleClick = async (id, fileName) => {
        if(IsDownloading) return;
        try {
            if(isNaN(Number(id))) return;
            downloadDocumentAction(+id, fileName);
            setIsDownloading(true);
       
        } catch {
            toast.error('Failed to download the document.');
        } finally {
            setIsDownloading(false);
        }
    };


    return (
        <UnmarginedPanel id="documents">
            <Flex justifyContent="space-between">
                <StyledSubtitle>Documents</StyledSubtitle>
                <BackToTopLink />
            </Flex>
            {/* <UploadInstructionButton mb="4" fullWidth color="outline" leftIcon={<SizedIcon name="Document" width="12px" height="12px" />}>
                Upload Document(s)
            </UploadInstructionButton> */}
            <Header>
                <ItemTitle>Document name</ItemTitle>
                <Hide breakpoints={[0, 1]}>
                    <ItemTitle>Date uploaded</ItemTitle>
                </Hide>
            </Header>
            <Flex flexDirection="column">
                {documents?.map((document, idx) => (
                    <DocumentContainer key={document.date.toString() + idx} mt="4">
                      <StyledLink href={document.id} rel="noreferrer" onClick={(e) => {
                        e.preventDefault();
                        handleClick(document.id, document.name)
                      }}>
                            {document.name}
                        </StyledLink>
                        {!!document.date && <StyledP>{format(getUTCDate(new Date(document.date)) , 'dd LLL yy hh:mm a')}</StyledP>}
                    </DocumentContainer>
                ))}
            </Flex>
        </UnmarginedPanel>
    );
};

export default DocumentsCard;