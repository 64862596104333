import { Switch, useRouteMatch } from 'react-router';
import ProtectedRoute from 'components/ProtectedRoute';
import DelegationProvider from 'state/DelegationProvider';
import GroupDelegationManagement from './GroupDelegationManagement';
import GroupDelegationBroker from './GroupDelegationBroker';

export const GroupDelegation = () => {
    const match = useRouteMatch();
    return (
        <DelegationProvider>
            <Switch>
                <ProtectedRoute exact path={match.path} component={GroupDelegationManagement} />
                <ProtectedRoute path={`${match.path}/:delegationId`} component={GroupDelegationBroker} />
            </Switch>
        </DelegationProvider>
    );
};

export default GroupDelegation;
