import { P, Flex } from '@galilee/lilee';
import { CardTitle, StyledSubtitle, UnmarginedPanel, BackToTopLink, SmallP } from "./index";
import { format } from 'date-fns';
import { getUTCDate } from 'utils';

const SecurityCard = ({ securities }) => {
    return (
        <UnmarginedPanel id="securityDetails">
            <Flex justifyContent="space-between">
                <StyledSubtitle>Security Details</StyledSubtitle>
                <BackToTopLink />
            </Flex>
            {securities?.map((security, position) => (
                <div key={position}>
                    <CardTitle>Security {position + 1}</CardTitle>
                    <SmallP>{security.addressLine1}</SmallP>
                    <P mb={4} fontSize={0}>
                        {security.locality} {security.state} {security.postcode}
                    </P>
                    <SmallP>Security purpose: {security.type}</SmallP>                    
                    <P mb={4} fontSize={0}>Title Reference: {security.titleRef}</P>
                    {security.pexaWorkspaceId && (                        
                        <P mb={4} fontSize={0}>PEXA Workspace ID: {security.pexaWorkspaceId}</P>
                    )}                      
                    <SmallP>Valuation ${security.valuation}</SmallP>
                    <SmallP>Valuation date: {!!security.valuationDate ? format(getUTCDate(new Date(security.valuationDate)), 'dd LLL yyyy') : ''}</SmallP>
                    <P mb={4} fontSize={0}>Valuation expiry date: {!!security.valuationExpDate ? format(getUTCDate(new Date(security.valuationExpDate)), 'dd LLL yyyy') : ''}</P>
                    <SmallP>Minimum Insurance ${security.minimumInsurance}</SmallP>
                    <SmallP>Insurance Start date: {!!security.insuranceStartDate ? format(getUTCDate(new Date(security.insuranceStartDate)), 'dd LLL yyyy') : ''}</SmallP>
                    <P mb={4} fontSize={0}>Insurance expiry date: {!!security.insuranceExpDate ? format(getUTCDate(new Date(security.insuranceExpDate)), 'dd LLL yyyy') : ''}</P>
                </div>
            ))}
        </UnmarginedPanel>
    );
};
export default SecurityCard;