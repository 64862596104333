import { Flex, Box, Panel, H1,  Row, Column, Icon, Text } from '@galilee/lilee';
import styled from "styled-components/macro";
import Logo from 'components/Logo';
import HamburgerMenu from 'components/HamburgerMenu';
import Breadcrumbs from 'components/Breadcrumbs';
import LoggedInUser from 'components/LoggedInUser';
import { isValidEmail } from 'utils';

export const DelegationList = styled(Flex)`
    flex-direction: column;
    margin-top: ${(p) => p.theme.space[8]};
    gap: ${(p) => p.theme.space[4]};
`;

export const StyledBox = styled(Box)`
    padding-top: ${(p) => p.theme.space[6]};
    padding-bottom: ${(p) => p.theme.space[6]};
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

export const DelegationPanel = styled(Panel)`
    max-width: 700px;
    min-width: 664px;
    margin-top: ${(p) => p.theme.space[8]};
    margin-bottom: ${(p) => p.theme.space[8]};
    border-radius: ${(p) => p.theme.sizes[3]};
    padding: 24px 42px;
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding: 0px;
    }
`;

export const DelegationHeaderTitle = styled(H1)`
    margin-top: ${(p) => p.theme.space[2]};
    font-size: ${(p) => p.theme.fontSizes[3]};

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        display: none;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        margin-top: ${(p) => p.theme.space[2]};
        font-size: ${(p) => p.theme.fontSizes[4]};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[4]}) {
        font-size: ${(p) => p.theme.fontSizes[5]};
    }
`;

export const DelegationHeaderContainer = styled(Flex)`
    height: 200px;
    justify-content: center;
    align-items: center;
    padding-left: 84px;
    padding-right: 36px;
    padding-top: 44px;
    padding-bottom: 44px;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[1]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        padding-left: 36px;
        padding-right: 36px;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        padding-left: 24px;
        padding-right: 24px;

        flex-direction: row;
    }
`;

export const DelegationHeader = ({ title }) => (
    <>
        <DelegationHeaderContainer bg="base" color="white">
            <Column colspan={10} justifyContent="space-between" mb="0">
                <Flex flexDirection="column" justifyContent="center">
                    <Logo />
                </Flex>
                <DelegationHeaderTitle>
                    {title}
                </DelegationHeaderTitle>
                <Row mt="6">
                    <Breadcrumbs />
                </Row>
                <Row>
                    <LoggedInUser />
                </Row>
            </Column>
        </DelegationHeaderContainer>
        <HamburgerMenu />
    </>
);

const Link = styled(Text)`
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-weight: 350;
    text-decoration: underline;
    cursor: pointer;
    color: #1C27DA;
`;

export const DelegationItem = ({ email, role, status, buttonText, hideButton, onClick }) => {
    return (
        <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
                <Icon size="5" color="black" name="User" mr="4"/>
                <Text fontSize="1">{email}</Text>
            </Flex>
            <Flex>
                {!!role && <Text fontSize="1" mr="4">{role}</Text>} 
                {!!status && <Text fontSize="1" mr="4">{status}</Text>} 
                {!hideButton && <Link onClick={() => onClick()}>{buttonText}</Link>}
            </Flex>
        </Flex>
    );
}

export const validateEmail = (email) => {
    if (!email)
        return 'Please provide an email';
     
    const isValid = isValidEmail(email);
    if (!isValid)
        return 'Invalid email address';
      
    return '';
}