import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, mediaQueries } from '@galilee/lilee';

const { lessThan } = mediaQueries;

const centerStyle = ({ center, theme, maxWidth }) => {
    if (!center) {
        return `
      flex: 1;
    `;
    }
    return `
  margin: auto;
  width: 90%;
  max-width: ${maxWidth || '1100px'};
  padding: ${theme.space[7]} 0;
  ${lessThan[1]`
    padding: ${theme.space[4]} 0;
    width: 95%;
  `}
  ${lessThan[0]`
  width: 100%;
  padding: 0;
  `}
`;
};

const Container = styled(Box)`
    ${centerStyle}
`;

Container.defaultProps = {
    children: null,
};

Container.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Container;
