import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Label, Column, Box, Icon, FieldError } from '@galilee/lilee';
import styled from 'styled-components/macro';

const Wrapper = styled(Column)`
    position: relative;
`;

const IconWrapper = styled(Box)`
  position: absolute;
  top: 42px;
  right: ${(p) => p.theme.space[4]};
  :hover {
    cursor: pointer;
  }
`;

const TextBoxField = ({ fieldConfig, value, noLabel, onChange }) => {
    const { name, displayName, placeholder, validator, isDate } = fieldConfig;
    const [isValid, setIsValid] = useState(true);
    const inputRef = useRef(null);

    const handleClearButtonClick = () => {
        onChange(name, '');
        inputRef.current?.focus();
    }

    return (
        <Wrapper mb={[7, 7, 7, 9]}>
            {!noLabel && (
                <Label fontSize="0" color="base" textTransform="uppercase" htmlFor={name}>
                    {displayName}
                </Label>
            )}
            <Input
                type={isDate ? 'date': 'text'}
                fontSize="1"
                name={name} 
                value={isDate && !!value ? value.split('T')[0] : value}
                placeholder={placeholder}
                onChange={(e) => {
                    const value = isDate ? new Date(e.target.value).toJSON() : e.target.value;
                    onChange(name, value);
                }}
                onBlur={(e) => {
                    if(validator) {
                        const res = validator(e.target.value);
                        setIsValid(res);
                    }
                }}
                ref={inputRef}
            />
            {!isValid && <FieldError error={`Invalid ${displayName}`} />}
            {!!value && <IconWrapper onClick={handleClearButtonClick}>
                <Icon name="Cross" size="10px" color="base"/>
          </IconWrapper>}
        </Wrapper>
    );
};

TextBoxField.defaultProps = {
    noLabel: false,
    value: '',
};

TextBoxField.propTypes = {
    noLabel: PropTypes.bool,
    fieldConfig: PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        placeholder: PropTypes.string,
        validator: PropTypes.func,
    }).isRequired,
    onChange:  PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default TextBoxField;
