import { Hide, Flex, P, Box, Modal } from '@galilee/lilee';
import { StyledSubtitle, UnmarginedPanel, InlineText, SizedIcon, ItemTitle, BackToTopLink } from "./index";
import { format } from 'date-fns';
import styled from 'styled-components/macro';
import { getUTCDate } from 'utils';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import parse from 'html-react-parser'; 
import { useState } from 'react';
import Helmet from 'react-helmet';

const iconColorMapping = {
    FlagCircle: 'primary',
    Appointment: 'tertiary'
}

const LinkSpan = styled.a`
    color: ${(p) => p.theme.colors.link};
`;

const LogContainer = styled(Box)`
    display : grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    grid-gap: ${(p) => p.theme.space[3]};
    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        grid-template-columns: 1fr;
    }
`;

const Header = styled(Box)`
    display : grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid ${(p) => p.theme.colors.base10};
    justify-content: space-between;
    margin-bottom: 0;
`;

const extractStyle = (html) => {
    if(!html.length || !html.includes('<style')) return null;
    const styleTagStartPos = html.indexOf('<style');
    const closingTagPos = html.indexOf('</style>');
    const pos = html.indexOf('>', styleTagStartPos) + 1;
    return html.substring(pos, closingTagPos);
}

const extractHtmlBody = (html) => {
    if(!html.length || !html.includes('<body>')) return null;
    const styleTagStartPos = html.indexOf('<body>');
    const closingTagPos = html.indexOf('</body>');
    return html.substring(styleTagStartPos + 6, closingTagPos);
}

const MatterLogCard = ({ logs }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const { actions: { getHtmlContent } } = useTrackNTrace();

    const handleLogClicked = async (id, type) => {
        if(!id || !type) return;
        const html = await getHtmlContent(id, type);
        if(!html) return;
       setHtmlContent(html);
    }

    const renderToken = (token, idx) => {
        switch (token.type) {
            case 'JumpTo':
                return (
                    <LinkSpan key={idx} href="#">
                        {token.display.join(' ')}{' '}
                    </LinkSpan>
                );
            case 'NewTab':
                return (
                    <LinkSpan key={idx} href="#" target="_blank">
                        {token.display.join(' ')}{' '}
                    </LinkSpan>
                );
            case 'EmailHtml':
                return (
                    <LinkSpan key={idx} href="#" target="_blank" onClick={(e) => {
                        e.preventDefault();
                        handleLogClicked(token.meta, token.type);
                    }}>
                        {token.display.join(' ')}{' '}
                    </LinkSpan>
                );
            case 'StatusUpdateHtml':
                return (
                    <LinkSpan key={idx} href="#" target="_blank" onClick={(e) => {
                        e.preventDefault();
                        handleLogClicked(token.meta, token.type);
                    }}>
                        {token.display.join(' ')}{' '}
                    </LinkSpan>
                );
            default:
                return <span key={idx}>{token.display.join(' ')} </span>;
        }
    };
    
    const htmlStyle = extractStyle(htmlContent);
    const html = extractHtmlBody(htmlContent);

    return (
        <UnmarginedPanel id="matterLog">
            <Flex justifyContent="space-between">
                <StyledSubtitle>
                    Matter log
                </StyledSubtitle>
                <BackToTopLink />
            </Flex>
            <Header>
                <ItemTitle>Description</ItemTitle>
                <Hide breakpoints={[0, 1]}>
                    <ItemTitle>Date advised</ItemTitle>
                </Hide>
            </Header>
            <Flex flexDirection="column" mt="5">
                {logs?.map((log, idx) => (
                    <LogContainer key={log.date.toString() + idx}>
                        <Flex alignItems="flex-start" mb="2">
                            <SizedIcon name={log.type}  mr="4" color={iconColorMapping[log.type] || 'base40'}/>
                            <InlineText>{log.tokens.map(renderToken)}</InlineText>
                        </Flex>
                        {!!log.date && <P fontSize={0} ml={[7, 7, 0]} color="base60">{format(getUTCDate(new Date(log.date)), 'dd LLL yy HH:mm a')}</P>}
                    </LogContainer>
                ))}
            </Flex>
            {
                !!htmlStyle && (
                    <Helmet>
                        <style type="text/css">
                            {htmlStyle}
                        </style>
                    </Helmet>
                )
            }
            {!!html && (
                <Modal>
                    <Modal.Close onClose={() => setHtmlContent('')} />
                    <Modal.Body>
                        {parse(html)}
                    </Modal.Body>
                </Modal>
            )}
        </UnmarginedPanel>
    );
};

export default MatterLogCard;