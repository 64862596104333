import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Layout from 'components/Layout';
import Container from 'components/Container';
import { Flex, H1, PageLoading, Box, Text, Button, H6, Input, FieldError, HR, P, Checkbox } from '@galilee/lilee';
import { DelegationPanel, StyledBox, DelegationList, DelegationItem, validateEmail } from '../shared/delegation';
import { GroupDelegationStatus, Role } from 'utils/constants';
import { useDelegation } from 'state/DelegationProvider';
import { DelegationHeader } from '../shared/delegation';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';

const InlineBoldText = styled(P)`
    display: inline;
    font-weight: bold;
    margin: 0;
    font-size: ${(p) => p.theme.fontSizes[0]};
`;

const InlineUnderlineText = styled(P)`
    display: inline;
    text-decoration: underline;
    margin: 0;
    font-size: ${(p) => p.theme.fontSizes[1]};
`;

const Grid = styled(Box)`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${(p) => p.theme.space[6]};

    @media screen and (max-width: 1640px) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
    }
`;

const GroupDelegationManagement = () => {
    const [newTeamMemberEmail, setNewTeamMemberEmail] = useState('');
    const [newDelegatorEmail, setNewDelegatorEmail] = useState('');

    const [isTeamAdmin, setIsTeamAdmin] = useState(false);

    const [teamMemberValidationMessage, setTeamMemberValidationMessage] = useState('');
    const [delegationValidationMessage, setDelegationValidationMessage] = useState('');

    const { user : me } = useTrackNTrace();

    const { 
        isLoading,
        domains,
        supportTeamMembers,
        getGroupDelegations,
        getSupportTeamMembers,
        groupDelegations,
        addGroupDelegation,
        revokeGroupDelegation,
        cancelPendingGroupDelegation,
        resendGroupDelegationRequest,
        addSupportTeamMember,
        revokeSupportTeamMember,
        getOrganizationDomains
    } = useDelegation();

    useEffect(() => {
        async function fetchData() {
            if (!me || (!me.roles.includes(Role.GroupDelegationAdmin) && !me.roles.includes(Role.CustomerSupportStaff))) return;

            if (me.roles.includes(Role.GroupDelegationAdmin)) {
                await getSupportTeamMembers();
                await getOrganizationDomains();
            }

            await getGroupDelegations();
        }
        fetchData();
    }, [me, getSupportTeamMembers, getGroupDelegations, getOrganizationDomains]);

    const handleAddNewTeamMember = async () => {
        if (teamMemberValidationMessage || !newTeamMemberEmail) return;
        await addSupportTeamMember(newTeamMemberEmail, isTeamAdmin);
        setNewTeamMemberEmail('');
        setIsTeamAdmin(false);
    }

    const handleRemoveTeamMember = async (id) => {
        if(!id) return;
        await revokeSupportTeamMember(id);
    }

    const handleAddNewGroupDelegation = async () => {
        if (delegationValidationMessage || !newDelegatorEmail) return;
        await addGroupDelegation(newDelegatorEmail, isTeamAdmin);
        setNewDelegatorEmail('');
    }

    const handleTeamMemberEmailValidation = () => {
        const message = validateEmail(newTeamMemberEmail);
        setTeamMemberValidationMessage(message);
    }

    const handleDelegatorEmailValidation = () => {
        const message = validateEmail(newDelegatorEmail);
        setDelegationValidationMessage(message);
    }

    const getGroupDelegationAction = (groupDelegation) => {
        if (groupDelegation.status === GroupDelegationStatus.APPROVED)
            return { handler: revokeGroupDelegation, label : 'Revoke' }

        if (groupDelegation.status === GroupDelegationStatus.PENDING)
            return { handler: cancelPendingGroupDelegation, label : 'Cancel'};
        
        if ([GroupDelegationStatus.REJECTED, GroupDelegationStatus.CANCELLED, GroupDelegationStatus.REVOKED].includes(groupDelegation.status))
           return { handler: resendGroupDelegationRequest, label : 'Resend'};

        return { handler: null, label: '' };
    }

    return (
        <>
            {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
            <Layout header={<DelegationHeader title='Group delegation' />}>
                <Container center maxWidth="max-content">
                    <Grid>
                        <Box maxWidth="700px">
                            <H1 fontSize="4" color="tertiary" mb="6">How it works</H1>
                            <Text mb="5" mx="auto" textAlign="left" fontSize="1">
                                Orion allows you to delegate access to admin or support staff within your organisation so anyone who needs up to date information can access it easily. 
                            </Text>
                            <Text mb="6" mx="auto" textAlign="left" fontSize="1">
                                Note: delegation gives access to all active matters in Orion and any future matters. Do not grant access to anyone outside of your organisation.
                            </Text>
                        </Box>
                        <Box />
                        {me?.roles.includes(Role.GroupDelegationAdmin) &&  (
                        <DelegationPanel mx="auto">
                            <StyledBox>
                                <H1 fontSize="4" color="tertiary" mb="5">Support Team</H1>
                                <Text mb="7" mx="auto" textAlign="left" fontSize="1">
                                    <InlineBoldText>Step 1:</InlineBoldText> Add the email address for admin or support staff. They will be notified that they have been granted access.
                                </Text>
                                {
                                    !!domains.length && (<Text mb="8" mx="auto" textAlign="left" fontSize="1">Note: all support team members will have access to brokers assigned in Step 2 and to matters assigned to brokers whose email address end in {domains.map((domain, index) => <Fragment key={domain}><InlineUnderlineText>{domain}</InlineUnderlineText>{`${index === domains.length -1 ? '' : ' & '}`}</Fragment>)}</Text>)
                                }
                                {
                                    !domains.length && <Text mb="8" mx="auto" textAlign="left" fontSize="1">Note: all support team members will have access to brokers assigned in Step 2.</Text>
                                }
                                <HR />
                                <H6 mt="8" mb="5" fontSize="0">
                                    Add new
                                </H6>
                                <Input fontSize="2" placeholder="Email address" value={newTeamMemberEmail} onChange={(e) => setNewTeamMemberEmail(e.target.value)} onBlur={() => handleTeamMemberEmailValidation()} disabled={isLoading}/>
                                {teamMemberValidationMessage && <FieldError error={teamMemberValidationMessage} />}
                                <Flex alignItems="center" flexDirection="row" my="6">
                                    <Checkbox
                                        label={<P>Make user a team admin</P>}
                                        checked={isTeamAdmin}
                                        onChange={() => setIsTeamAdmin(!isTeamAdmin)}
                                    />
                                </Flex>
                                <Button mt="6" onClick={handleAddNewTeamMember} disabled={isLoading} color="tertiary">
                                    Add
                                </Button>
                                {!!supportTeamMembers.length && <DelegationList>
                                    <Box>
                                        <Text fontSize="0" fontWeight="500" color="base40" uppercase>Support Team</Text>
                                        <HR mt="2" />
                                    </Box>
                                    {
                                        supportTeamMembers
                                            .map((user) => <DelegationItem key={user.id} email={user?.email} role={user.isTeamAdmin ? 'Team Admin' : ''} buttonText='Revoke' hideButton={user.email === me?.email } onClick={() => handleRemoveTeamMember(user.id)} />)
                                    }
                                </DelegationList>}
                            </StyledBox>
                        </DelegationPanel>
                        )}
                        <DelegationPanel mx="auto">
                            <StyledBox>
                                <H1 fontSize="4" color="tertiary" mb="5">Brokers</H1>
                                <Text mb="7" mx="auto" textAlign="left" fontSize="1">
                                   {me?.roles.includes(Role.GroupDelegationAdmin) && <InlineBoldText>Step 2:</InlineBoldText> } Request access to a particular brokers matters by entering their email address. We will notify them that you are requesting access.
                                </Text>
                                <HR />
                                <H6 mt="8" mb="5" fontSize="0">
                                    Add new
                                </H6>
                                <Input fontSize="2" placeholder="Email address" value={newDelegatorEmail} onChange={(e) => setNewDelegatorEmail(e.target.value)} onBlur={() => handleDelegatorEmailValidation()} disabled={isLoading}/>
                                {delegationValidationMessage && <FieldError error={delegationValidationMessage} />}
                                <Button mt="6" onClick={handleAddNewGroupDelegation} disabled={isLoading} color="tertiary">
                                    Add
                                </Button>
                                {!!groupDelegations.length && <DelegationList>
                                    <Box>
                                        <Text fontSize="0" fontWeight="500" color="base40" uppercase>Brokers</Text>
                                        <HR mt="2" />
                                    </Box>
                                    {
                                        groupDelegations.map((delegation) => {
                                            const action = getGroupDelegationAction(delegation);
                                            return(
                                                <DelegationItem
                                                    key={delegation.id}
                                                    email={delegation.delegatorEmail}
                                                    status={delegation.status}
                                                    buttonText={action.label}
                                                    onClick={async () => await action.handler?.call(null, delegation.id)}
                                                />
                                            );
                                        })
                                    }
                                </DelegationList>}
                            </StyledBox>
                        </DelegationPanel>
                    </Grid>
                </Container>
            </Layout>
        </>
    );
};

export default GroupDelegationManagement;