/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useCallback } from 'react';

const getFriendlyErrorMessage = (status) => {
    if (status === 200) return 'OK';
    if (status === 201) return 'Created';
    if (status === 202) return 'Accepted';
    if (status === 203) return 'Non-Authoritative Information';
    if (status === 204) return 'No Content';
    if (status === 205) return 'Reset Content';
    if (status === 206) return 'Partial Content';
    if (status === 300) return 'Multiple Choices';
    if (status === 301) return 'Moved Permanently';
    if (status === 302) return 'Found';
    if (status === 303) return 'See Other';
    if (status === 304) return 'Not Modified';
    if (status === 305) return 'Use Proxy';
    if (status === 306) return 'Unused';
    if (status === 307) return 'Temporary Redirect';
    if (status === 400) return 'Bad Request';
    if (status === 401) return 'Unauthorized';
    if (status === 402) return 'Payment Required';
    if (status === 403) return 'Forbidden';
    if (status === 404) return 'Not Found';
    if (status === 405) return 'Method Not Allowed';
    if (status === 406) return 'Not Acceptable';
    if (status === 407) return 'Proxy Authentication Required';
    if (status === 408) return 'Request Timeout';
    if (status === 409) return 'Conflict';
    if (status === 410) return 'Gone';
    if (status === 411) return 'Length Required';
    if (status === 412) return 'Precondition Required';
    if (status === 413) return 'Request Entry Too Large';
    if (status === 414) return 'Request-URI Too Long';
    if (status === 415) return 'Unsupported Media Type';
    if (status === 416) return 'Requested Range Not Satisfiable';
    if (status === 417) return 'Expectation Failed';
    if (status === 418) return "I'm a teapot";
    if (status === 500) return 'Internal Server Error';
    if (status === 501) return 'Not Implemented';
    if (status === 502) return 'Bad Gateway';
    if (status === 503) return 'Service Unavailable';
    if (status === 504) return 'Gateway Timeout';
    if (status === 505) return 'HTTP Version Not Supported';
    return 'Indeterminable Error';
};

export const jsonSafeParse = (str) => {
    const response = { success: false, result: null };
    try {
        response.result = JSON.parse(str);
        response.success = true;
    } catch (e) {
        return response;
    }
    return response;
};

const englishIntegers = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
];

export function numToWords(n) {
    return englishIntegers[n];
}

export const feedbackScript = (user, matterId, senderRef, matterStage, lender, broker, originator) => {
    if (!matterStage || user.userType !== 1) return null;
    return `
    !function(e,t,r,n,a){if(!e[a]){for(var i=e[a]=[],c=0;c<r.length;c++){var s=r[c];i[s]=i[s]||function(e){return function(){var t=Array.prototype.slice.call(arguments);i.push([e,t])}}(s)}i.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+n+"/"+a+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],"YYHjBhxPwK4coy1c","delighted");

    delighted.survey({
      email: "${user.email}",
      name: "${user.firstName} ${user.lastName}",
      properties: {
        senderRef: "${senderRef}",
        matterId: "${matterId}",
        matterStage: "${matterStage}",
        lender: "${lender}",
        broker: "${broker || 'None'}",
        originator: "${originator || 'None'}"
      },
      initialDelay: 0,
    });
  `;
};

export const httpRequest = async (url, method, payload, headers) => {
    const config = {
        method,
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    };
    if (method.toLowerCase() === 'post' && payload) {
        config.body = JSON.stringify(payload);
    }
    if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
        config.headers = { ...config.headers, ...headers };
    }

    const baseUrl = process.env.REACT_APP_BASE_URL;

    return fetch(`${baseUrl}${url}`, config).then(async (response) => {
        if (response.ok) {
            if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                return response.json().then((json) => {
                    if (json.succeeded) return json.result;
                    const error = json.errors.length > 0 ? json.errors[0] : 'Data access error';
                    return Promise.reject(error);
                });
            }
            return response.blob();
        }
        //redirect to login if unauthorized
        if (response.status === 401) {
            localStorage.removeItem('_orion_tt_credential');
            window.location.replace(window.location.host)
            return;
        }
        
        return Promise.reject(getFriendlyErrorMessage(response.status));
    });
};

export const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

export const doDownload = (progress, setDownloading, sectionType, docId, authToken, voiFile = false) => {
    setDownloading(true);
    let fileEndpoint = progress.availableForDownload ? 'finalisedFile' : 'file';
    if (voiFile) fileEndpoint = 'voi';
    httpRequest(`/api/${sectionType}documents/${docId}/${fileEndpoint}`, 'get', null, { Authorization: `Bearer ${authToken}` })
        .then((blob) => {
            const fileName = voiFile ? 'VOI Certificate.pdf' : progress.fileName || `${progress.name}.pdf`;
            downloadFile(blob, fileName);
        })
        .catch(() => {
            throw new Error(`Unable to download file for document (${docId}).`);
        })
        .finally(() => {
            setDownloading(false);
        });
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export function useWindowSize() {
    const isClient = typeof window === 'object';

    const getSize = useCallback(
        () => ({
            width: isClient ? window.innerWidth : 0,
            height: isClient ? window.innerHeight : 0,
        }),
        [isClient]
    );

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        let debounceTimeoutId = null;
        if (!isClient) return false;

        const handleResize = () => {
            clearTimeout(debounceTimeoutId);
            debounceTimeoutId = setTimeout(() => {
                setWindowSize(getSize());
            }, 100);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export const base64EncodeUnicode = (str) => btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)));

export const objectToKeyValue = (obj) => {
    const keyValueArray = [];
    Object.entries(obj).forEach(([key, value]) => {
        keyValueArray.push({ key, value });
    });
    return keyValueArray;
};

export const isValidDate = (date) => date instanceof Date && !Number.isNaN(Number.parseInt(date?.getTime()?.toString(), 10));

export const humanReadableFormat = (date, options) => {
    if (!isValidDate(date)) return 'Invalid date';

    const SECONDS = {
        IN_A_YEAR: 31536000,
        IN_A_MONTH: 2592000,
        IN_A_DAY: 86400,
        IN_AN_HOUR: 3600,
        IN_A_MINUTE: 60,
    };

    const { suffix = '', prefix = '', depth = 1, offset = { seconds: 60, label: 'Just now' } } = options;

    let remainingSeconds = Math.floor((+new Date() - +date) / 1000);

    const years = Math.floor(remainingSeconds / SECONDS.IN_A_YEAR);
    remainingSeconds -= years * SECONDS.IN_A_YEAR;

    const months = Math.floor(remainingSeconds / SECONDS.IN_A_MONTH);
    remainingSeconds -= months * SECONDS.IN_A_MONTH;

    const days = Math.floor(remainingSeconds / SECONDS.IN_A_DAY);
    remainingSeconds -= days * SECONDS.IN_A_DAY;

    const hours = Math.floor(remainingSeconds / SECONDS.IN_AN_HOUR);
    remainingSeconds -= hours * SECONDS.IN_AN_HOUR;

    const minutes = Math.floor(remainingSeconds / SECONDS.IN_A_MINUTE);
    remainingSeconds -= minutes * SECONDS.IN_A_MINUTE;

    if (offset.seconds) {
        const dateWeight = years * SECONDS.IN_A_YEAR + months * SECONDS.IN_A_MONTH + days * SECONDS.IN_A_DAY + minutes * SECONDS.IN_A_MINUTE;
        if (dateWeight <= offset.seconds) return offset.label;
    }

    const segments = {
        year: years,
        month: months,
        day: days,
        hour: hours,
        minute: minutes,
    };

    const humanFormat = Object.entries(segments)
        .filter(([, value]) => value)
        .slice(0, depth)
        .reduce((acc, [key, value]) => {
            const formatPlural = (singularStr, number) => (number && number > 1 ? `${singularStr}s` : `${singularStr}`);
            const text = `${acc} ${value} ${formatPlural(key, value)}`;
            return text.trim();
        }, '');

    return `${prefix} ${humanFormat} ${suffix}`.trim();
};


const queryStringBuilder = (key, value, initial) => {
    if (value === undefined || value === '') return initial;
    const segment = Array.isArray(value) ? value.map((val) => queryStringBuilder(key, val, '')).join('&') : `${key}=${encodeURIComponent(value)}`;

    if (!segment) return initial;

    const newQuery = initial !== '' ? `${initial}&${segment}` : segment;
    return newQuery;
};

export const queryBuilder = (queryObject) => Object.entries(queryObject || {}).reduce((acc, [key, value]) => queryStringBuilder(key, value, acc), '');

export const getUTCDate = (input) => {
    if(!isValidDate(input)) return null;
   
    const year = input.getUTCFullYear();
    const month = input.getUTCMonth();
    const date = input.getUTCDate();
    const hours = input.getUTCHours();
    const minutes = input.getUTCMinutes();

    return new Date(year, month, date, hours, minutes, 0);
}

export const isValidEmail = (email) => {
    const emailRegex = /.+@[^@]+\.[^@]{2,}$/;
    return emailRegex.test(email);
}