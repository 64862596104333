import { Flex, CircleIcon, P } from '@galilee/lilee';
import styled from 'styled-components/macro';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { Role } from 'utils/constants';

const userRoleMapping = {
    [Role.GroupDelegationAdmin]: 'Group delegation admin',
    [Role.CustomerSupportStaff]: 'Customer support staff'
}

const UserTitle = styled(P)`
    margin: 0;
    margin-right: ${(p) => p.theme.space[9]};
    color: ${(p) => p.theme.colors.base40};
    font-family: ${(p) => p.theme.fonts.title};

    overflow: hidden;
    text-overflow: ellipsis;
`;

const SizedCircleIcon = styled(CircleIcon)`
    width: ${(p) => p.theme.space[5]};
    height: ${(p) => p.theme.space[5]};

    > svg {
        width: ${(p) => p.theme.space[3]} !important;
        height: ${(p) => p.theme.space[3]} !important;
    }
`;

const LoggedInUser = () => {
    const { user } = useTrackNTrace();

    return (
        <Flex alignItems="center" mt="4">
            <SizedCircleIcon name="User" color="base80" background="base05" mr="2" />
            <UserTitle>{user?.email} - {user?.roles.map(r => userRoleMapping[r] ?? r).join(', ')}</UserTitle>
        </Flex>
    );
};

export default LoggedInUser;
