import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Flex, Text, mediaQueries } from '@galilee/lilee';
import styled from 'styled-components/macro';
import format from 'date-fns/format';

const { greaterThan } = mediaQueries;

const Card = styled(Panel)`
    max-width: 700px;
    margin-bottom: ${(p) => p.theme.space[3]};
    border-radius: ${(p) => p.theme.sizes[3]};
    cursor: pointer;
`;

const LastUpdateWrapper = styled(Text)`
    display: none;
    margin-right: ${(p) => p.theme.space[3]};
    ${greaterThan[0]`
    display: block;
  `}
`;

export default function MatterCard({ names, suburbs, lastUpdateDate, onClick }) {
    return (
        <Card mx="auto" onClick={onClick}>
            <Flex justifyContent="space-between">
                <Flex maxWidth="60%" flexDirection={['column', 'row']}>
                    <Text ellipsis mr="4">
                        {names.join(' & ')}
                    </Text>
                    <Text ellipsis fontWeight="medium">
                        {suburbs.join(' & ')}
                    </Text>
                </Flex>
                <Flex>
                    <LastUpdateWrapper>Last Update:</LastUpdateWrapper>
                    <Text>{format(new Date(lastUpdateDate), 'dd/MM/yyyy')}</Text>
                </Flex>
            </Flex>
        </Card>
    );
}

MatterCard.propTypes = {
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
    suburbs: PropTypes.arrayOf(PropTypes.string).isRequired,
    lastUpdateDate: PropTypes.string,
    onClick: PropTypes.func,
};

MatterCard.defaultProps = {
    lastUpdateDate: null,
    onClick: () => {},
};
