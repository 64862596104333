import { Flex, Image, H1, H3 } from '@galilee/lilee';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import LogoImage from 'images/logo.svg';

const OldH1 = styled(H1)`
    font-family: ${(p) => p.theme.fonts.titleOld};
`;

const LogoH1 = styled(H1)`
    font-family: 'Zilla Slab', serif;
    color: ${(p) => p.theme.colors.primary};
`;
const LogoH3 = styled(H3)`
    font-family: 'Zilla Slab', serif;
    color: ${(p) => p.theme.colors.primary};
`;

const LogoTitle = ({ title }) => (
    <>
        <Image maxWidth="56px" src={LogoImage} alt="logo" />
        <Flex>
            <LogoH1 mr="40px">ORION.</LogoH1>
            <OldH1>{title ?? 'Instantly track progress updates for loan matters'}</OldH1>
        </Flex>
    </>
);

const LogoStacked = () => (
    <Flex flexDirection="column" justifyContext="center" alignItems="flex-start">
        <Image maxWidth="40px" src={LogoImage} alt="logo" />
        <LogoH3>ORION.</LogoH3>
    </Flex>
);

const Logo = ({ title }) => (title ? <LogoTitle title={title} /> : <LogoStacked />);

Logo.propTypes = {
    title: PropTypes.string,
};

export default Logo;
