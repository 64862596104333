import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Layout from 'components/Layout';
import Logo from 'components/Logo';
import LoginForm from '../components/LoginForm';
import AccessCodeForm from '../components/AccessCodeForm';

import { Flex, H1 } from '@galilee/lilee';
import styled from 'styled-components/macro';

const OldH1 = styled(H1)`
    font-family: ${(p) => p.theme.fonts.titleOld};
`;

const HeaderContainer = styled(Flex)`
    position: sticky;
    top: 0;
    height: 212px;

    justify-content: center;
    align-items: center;

    padding-left: 250px;
    padding-right: 250px;

    .Header__Mobile {
        display: none;
    }

    @media screen and (max-width: 1024px) {
        padding-left: 180px;
        padding-right: 180px;
    }
    @media screen and (max-width: 640px) {
        padding-left: 54px;
        padding-right: 54px;

        h1 {
            font-size: ${(p) => p.theme.fontSizes[4]};
        }
    }
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding-left: 24px;
        padding-right: 24px;
    }
    @media screen and (max-width: 360px) {
        padding-left: 28px;
        padding-right: 28px;

        flex-direction: row;

        h1 {
            font-size: ${(p) => p.theme.fontSizes[4]};
        }

        .Header__Desktop {
            display: none;
        }

        .Header__Mobile {
            display: flex;
        }
    }
`;

// TODO(Danny): Show/Hide
const Header = () => (
    <HeaderContainer bg="base" color="white">
        <Flex flexDirection="column" justifyContent="center" className="Header__Mobile">
            <Logo />
            <OldH1>Instantly track progress updates for loan matters</OldH1>
        </Flex>
        <Flex flexDirection="column" justifyContent="center" className="Header__Desktop">
            <Logo title="Instantly track progress updates for loan matters" />
        </Flex>
    </HeaderContainer>
);

const Login = () => {
    const match = useRouteMatch();

    return (
        <Layout header={<Header />}>
            <Switch>
                <Route exact path={match.path} component={LoginForm} />
                <Route path={`${match.path}/withCode`} component={AccessCodeForm} />
            </Switch>
        </Layout>
    );
};
export default Login;
