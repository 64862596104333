import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@galilee/lilee';
import styled from 'styled-components/macro';
import DataCard from './DataCard';

const DataCardContainer = styled(Flex)`
    gap: 16px;
    flex-direction: column;
    margin: 0 auto;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[3]}) {
        margin: 0;
    }
`;

const DataList = ({ data, onClick, idField }) => (
    <DataCardContainer>
        { data.map((i, idx) => <DataCard key={i[idField]} bg={idx % 2 === 0 ? 'white' : 'base05' } data={i} onClick={onClick}/>) }
    </DataCardContainer>
);

DataList.defaultProps = {
    data: [],
    onClick: () => {}
};

DataList.prototype = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    idField: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default DataList;