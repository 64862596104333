import { Flex } from '@galilee/lilee';
import { CardTitle, StyledSubtitle, UnmarginedPanel, BackToTopLink, SmallP } from "./index";
import { format } from 'date-fns';
import { getUTCDate } from 'utils';

const LoanCard = ({ loans }) => {
    return (
        <UnmarginedPanel id="loanDetails">
            <Flex justifyContent="space-between">
                <StyledSubtitle>Loan Details</StyledSubtitle>
                <BackToTopLink />
            </Flex>
            {loans?.map((loan, position) => (
                <div key={position}>
                    <CardTitle>Loan {position + 1}</CardTitle>
                    <SmallP>${loan.total}</SmallP>
                    <SmallP>Account number: {loan.accountNo}</SmallP>
                    <SmallP>Loan disclosure date: {!!loan.disclosureDate ? format(getUTCDate(new Date(loan.disclosureDate)), 'dd LLL yyyy'): ''}</SmallP>
                    <SmallP>Loan expiry date: {!!loan.expDate ? format(getUTCDate(new Date(loan.expDate)), 'dd LLL yyyy'): ''}</SmallP>
                </div>
            ))}
        </UnmarginedPanel>
    );
};

export default LoanCard;