import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Flex, Icon } from '@galilee/lilee';

export const sortPropTypes = PropTypes.shape({
    sortBy: PropTypes.string,
    ascending: PropTypes.bool,
});

export const columnPropTypes = PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    sortable: PropTypes.bool,
    visible: PropTypes.bool,
    isDate: PropTypes.bool,
});

const HeaderContainer = styled(Flex)`
    justify-content: flex-start;
    align-items: center;
    cursor: ${(p) => (p.column.sortable ? 'pointer' : 'default')};
    color: ${(p) => (p.selected ? p.theme.colors.tertiary : p.theme.colors.base80 )};
    white-space: nowrap;
`;

const HeaderIcon = styled(Icon)`
    width: 12px;
    height: 12px;
    margin-left: 8px;
    position: relative;
    top: 1px;
`;

const Header = ({ column, sort, onSort }) => {
    if (!column.visible) return null;
    const isSelected = sort.sortBy === column.name;
    const headerClick = async () => {
        if (column.sortable) {
            const newSort = { sortBy: column.name, sortAscending: isSelected ? !sort.sortAscending : false, };
            onSort(newSort);
        }
    };

    return (
        <HeaderContainer column={column} selected={isSelected} onClick={headerClick}>
            {column.displayName}
            {column.sortable && <HeaderIcon color={isSelected ? 'tertiary' : 'base20'} name={isSelected && sort.sortAscending ? 'ArrowUp' : 'ArrowDown'} />}
        </HeaderContainer>
    );
};

Header.propTypes = {
    column: columnPropTypes.isRequired,
    sort: sortPropTypes.isRequired,
    onSort: PropTypes.func.isRequired,
};

export default Header;
