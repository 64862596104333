import { theme } from '@galilee/lilee';

export const OrionTheme = {
    ...theme,
    name: 'orion',
    breakpointsMin: ['0px', '360px', '480px', '640px', '1024px', '1440px'],
    breakpointsMax: ['359px','479px', '639px', '1023px', '1439px'],
    breakpoints: ['360px', '480px', '640px', '1024px', '1440px'],
    breakpointsPlus: ['361px', '481px', '641px', '1025px', '1441px'],
    space: ['0', '2px', '4px', '8px', '12px', '16px', '24px', '32px', '48px', '64px', '72px', '128px', '256px'],
    fonts: {
        body: "'Rubik', sans-serif",
        title: "'mr-eaves-xl-modern', sans-serif",
        titleOld: "'Zilla Slab', serif",
    },
    fontSizes: ['14px', '16px', '18px', '21px', '28px', '36px', '48px', '58px'],
    colors: {
        ...theme.colors,
        primary: '#FF8800',
        primary80: '#FFA033',
        primary60: '#FFB866',
        primary40: '#FFCF99',
        primary20: '#FFE7CC',
        primary10: '#FFF3E6',
        primary05: '#FFFFFF',
        secondary: '#40F9FF',
        secondary80: '#66FAFF',
        secondary60: '#8CFBFF',
        secondary40: '#B3FDFF',
        secondary20: '#D9FEFF',
        secondary10: '#ECFEFF',
        secondary05: '#FFFFFF',
        tertiary: '#14CC9E',
        tertiary110: '#0FB78D',
        tertiary80: '#43D6B1',
        tertiary60: '#72E0C5',
        tertiary40: '#A1EBD8',
        tertiary30: '#FAEF2E2',
        tertiary20: '#D0F5EC',
        tertiary10: '#E8FAF5',
        tertiary05: '#FFFFFF',
        warn: '#FF5119',
        link: '#1C27DA'
    },
};
