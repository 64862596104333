import React, { useEffect, useState } from 'react';
import { Button, Text, Panel, Input, Box, H6, FieldError, PageLoading } from '@galilee/lilee';

import { useTrackNTrace } from 'state/track/TrackNTraceProvider';

import styled from 'styled-components/macro';

const LoginPanel = styled(Panel)`
    max-width: 700px;
    margin-bottom: ${(p) => p.theme.space[8]};
    border-radius: ${(p) => p.theme.sizes[3]};

    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding: 0px;
    }
`;

const LoginWrapper = styled(Box)`
    padding-top: ${(p) => p.theme.space[6]};
    padding-bottom: ${(p) => p.theme.space[6]};
    padding-left: 48px;
    padding-right: 48px;
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const LoginForm = () => {
    const { loginPendingUser, isLoading, actions } = useTrackNTrace();
    const { requireAccessCode } = actions;
    const [email, setEmail] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        if (!loginPendingUser) return;
        setEmail(loginPendingUser);
    }, [loginPendingUser]);

    const { user, actions: { logout } } = useTrackNTrace();
    const handleButtonClick = async () => {
        if (!email || isInvalid) return;

        if (user)
            await logout(user.email);

        await requireAccessCode(email);
    };

    const handleValidation = () => {
        const emailRegex = /.+@[^@]+\.[^@]{2,}$/;
        const result = emailRegex.test(email);
        setIsInvalid(!result);
    };

    return (
        <>
            {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
            <LoginPanel mx="auto">
                <LoginWrapper>
                    <Text maxWidth="500px" mb="8" mx="auto" textAlign="center">
                        Please enter the email address you have associated with a matter.
                    </Text>
                    <H6 mt="5" mb="5">
                        Email Address
                    </H6>
                    <Input autoFocus fontSize="2" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={handleValidation} />
                    {isInvalid && <FieldError error="Invalid address" />}
                    <Button mt="8" onClick={handleButtonClick} disabled={isLoading} color="tertiary">
                        Continue
                    </Button>                  
                </LoginWrapper>
            </LoginPanel>
        </>
    );
};

export default LoginForm;
