import { Flex } from '@galilee/lilee';

import styled from 'styled-components/macro';

const HeaderContainer = styled(Flex)`
    height: 200px;

    justify-content: center;
    align-items: center;

    padding-left: 84px;
    padding-right: 36px;
    padding-top: 44px;
    padding-bottom: 44px;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[1]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        padding-left: 36px;
        padding-right: 36px;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        padding-left: 24px;
        padding-right: 24px;

        flex-direction: row;
    }
`;

const AuthenticatedHeader = ({ children }) => {
    return (
        <HeaderContainer bg="base" color="white">
            {children}
        </HeaderContainer>
    );
};

AuthenticatedHeader.propTypes = {};

export default AuthenticatedHeader;
