import React from 'react';
import { Flex, CustomThemeProvider } from '@galilee/lilee';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { OrionTheme } from 'theme';

const BodyContainer = styled(Flex)`
    padding: 24px 40px;
    justify-content: center;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[4]}) {
        padding: 24px 12px;
    }
`;

const Layout = ({ header, children }) => {
    return (
        <CustomThemeProvider theme={OrionTheme}>
            {header}
            <BodyContainer flexDirection="column">{children}</BodyContainer>
        </CustomThemeProvider>
    );
};

Layout.propTypes = {
    header: PropTypes.node,
};

Layout.defaultProps = {};

export default Layout;
