import styled from 'styled-components/macro';
import { Flex, Panel, Table, TD, TR,TH, P, Icon, Box, Text, utils } from '@galilee/lilee';

const { useScrollToTop } = utils;

export const CardTitle = styled(Flex)`
    color: ${(p) => p.theme.colors.base40};
    text-transform: uppercase;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 12px;
    border-bottom: 1px solid ${(p) => p.theme.colors.base10};
    margin-bottom: ${(p) => p.theme.space[5]};;
`;

export const UnpaddedPanelSection = styled(Panel.Section)`
    padding: 0;
`;

export const UnmarginedPanel = styled(Panel)`
    margin-bottom: 0;
    max-width: 100%;
    padding: 24px;
`;

export const StyledSubtitle = styled(Panel.Subtitle)`
    color: ${(p) => p.theme.colors.tertiary};
    text-transform: none;
    font-size: ${(p) => p.theme.fontSizes[1]};
`;

export const BorderAllowedTable = styled(Table)`
    border-collapse: collapse;
`;


export const InlineText = styled(P)`
    display: inline;
    margin: 0;
    font-size: ${(p) => p.theme.fontSizes[0]};
`;

export const SizedIcon = styled(Icon)`
    width: ${(p) => p.width ?? '18px'} !important;
    height: ${(p) => p.height ?? '18px'} !important;
`;

export const StyledP = styled(P)`
    color: ${(p) => p.theme.colors.base60};
    margin: 0;
    white-space: nowrap;
    font-size: ${(p) => p.theme.fontSizes[0]};
`;

export const StyledTD = styled(TD)`
    padding: 12px 0;
    border-bottom: none !important;
`;

export const StyledTR = styled(TR)`
    border-bottom: 1px solid ${(p) => p.theme.colors.base10};
`;

export const StyledTH = styled(TH)`
    text-transform: uppercase;
    font-weight: ${(p) => p.theme.fontWeights.bold} !important;
    color: ${(p) => p.theme.colors.base40} !important;
    font-size: ${(p) => p.theme.fontSizes[0]} !important;

    padding: 0 0 ${(p) => (p.noPadding ? '0' : '12px')} 0;
`;

export const MobileWrapper = styled(Box)`
       @media screen and (max-width: ${(p) => p.theme.breakpointsMin[1]}) {
        display: none;
    }
`;

export const ItemTitle = styled(Text)`
    text-transform: uppercase;
    font-weight: ${(p) => p.theme.fontWeights.bold} !important;
    color: ${(p) => p.theme.colors.base40} !important;
    font-size: 12px !important;
`;

export const MobileOnlyContainer = styled(Flex)`
    @media screen and (max-width: ${(p) => p.theme.breakpoints[1]}) {
        display: block;
    }
`;

const BackToTopP = styled(P)`
    cursor: pointer;
    margin:0;
    text-decoration: underline;
    display: none;
    @media screen and (max-width: ${(p) => p.theme.breakpointsPlus[2]}) {
        display: block;
    }
`;

export const BackToTopLink = () => {
    const { setScrollToTop } = useScrollToTop(true);
    const handleClick = () => {
        //remove query string without re-rendering the page.
        const url = new URL(window.location);
        url.searchParams.delete('focus');
        window.history.pushState({},'', url);
        setScrollToTop(true);
    }

    return (
        <BackToTopP color="base40" onClick={handleClick}>
            Back to top
        </BackToTopP>);
}


export const SmallP = styled(P)`
    margin-bottom: ${(p) => p.theme.space[0]};
    font-size: ${(p) => p.theme.fontSizes[0]};
`;