import { Panel, P, Flex } from '@galilee/lilee';
import { CardTitle, StyledSubtitle, UnmarginedPanel, UnpaddedPanelSection, BackToTopLink, SmallP } from "./index";

const PartyCard = ({ party }) => {
    return (
        <UnmarginedPanel id="partyDetails">
            <Flex justifyContent="space-between">
                <StyledSubtitle>Party Details</StyledSubtitle>
                <BackToTopLink />
            </Flex>
            <UnpaddedPanelSection borderCollapse p={[0]}>
                <Panel.Content>
                    {!!party.borrowers?.length && (
                        <>
                            <CardTitle>Borrowers</CardTitle>
                            {party.borrowers.map((borrower) => (
                                <div key={borrower.email}>
                                    <SmallP>{`${borrower.firstName} ${borrower.lastName}`}</SmallP>
                                    <SmallP>{borrower.mobile}</SmallP>
                                    <P fontSize={0}>{borrower.email}</P>
                                </div>
                            ))}
                        </>
                    )}
                    {!!party.guarantors?.length && (
                        <>
                            <CardTitle>Guarantors</CardTitle>
                            {party.guarantors.map((guarantor) => (
                                <div key={guarantor.name}>
                                    <SmallP>{guarantor.name}</SmallP>
                                    <SmallP>{guarantor.mobile}</SmallP>
                                    <P fontSize={0}>{guarantor.email}</P>
                                </div>
                            ))}
                        </>
                    )}                    
                    {party.mobileLender && (
                        <>
                            <CardTitle>Mobile Lender</CardTitle>
                            <SmallP>{party.mobileLender.organisation}</SmallP>
                            <SmallP>{party.mobileLender.name}</SmallP>
                            <SmallP>{party.mobileLender.mobile}</SmallP>
                            <P fontSize={0}>{party.mobileLender.email}</P>
                        </>
                    )}
                    {!party.mobileLender && party.broker && (
                        <>
                            <CardTitle>Broker</CardTitle>
                            <SmallP>{party.broker.organisation}</SmallP>
                            <SmallP>{party.broker.name}</SmallP>
                            <SmallP>{party.broker.mobile}</SmallP>
                            <P fontSize={0}>{party.broker.email}</P>
                        </>
                    )}                   
                    {party.borrowerSolicitor && (
                        <>
                            <CardTitle>Borrower's Solicitor</CardTitle>
                            <SmallP>{party.borrowerSolicitor.organisation}</SmallP>
                            <SmallP>{party.borrowerSolicitor.name}</SmallP>
                            <SmallP>{party.borrowerSolicitor.mobile}</SmallP>
                            <P fontSize={0}>{party.borrowerSolicitor.email}</P>
                        </>
                    )}
                   {!!party.dischargingMortgagees?.length && (
                        <>
                            <CardTitle>Discharging Mortgagee</CardTitle>
                            {party.dischargingMortgagees.map((dischargingMortgagee) => (
                                <div key={dischargingMortgagee.organisation}>
                                    <SmallP>{dischargingMortgagee.organisation}</SmallP>
                                    <SmallP>{dischargingMortgagee.name}</SmallP>
                                    <SmallP>{dischargingMortgagee.mobile}</SmallP>
                                    <P fontSize={0}>{dischargingMortgagee.email}</P>
                                </div>
                            ))}
                        </>
                    )}
                </Panel.Content>
            </UnpaddedPanelSection>
        </UnmarginedPanel>
    );
};

export default PartyCard;