import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import TrackNTraceMatterProvider from 'state/track/TrackNTraceMatterProvider';
import Layout from 'components/Layout';
import {
    PageLoading,
    Box,
    Flex,
    Badge,
    theme,
    Row,
    Column,
} from '@galilee/lilee';
import styled from 'styled-components/macro';
import MatterHeader from 'components/MatterHeader';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import PartyCard from 'components/matterDetails/PartyCard';
import SecurityCard from 'components/matterDetails/SecurityCard';
import LoanCard from 'components/matterDetails/LoadCard';
import MatterLogCard from 'components/matterDetails/MatterLogCard';
import SpecialConditionsCard from 'components/matterDetails/SpecialConditionsCard';
import DocumentsCard from 'components/matterDetails/DocumentsCard';
import OutstandingRequirementsCard from 'components/matterDetails/OutstandingRequirementsCard';
import MatterSummaryCard from 'components/matterDetails/MatterSummaryCard';
import { MatterStatus, Role } from 'utils/constants';
import { useEffect, useLayoutEffect, useRef } from 'react';
import PhoneSupport from 'components/PhoneSupport';

const StatusContainer = styled(Flex)`
    align-self: center;
    position: relative;
    gap: 32px;
    overflow: scroll;
    background: white;
    border-radius: 10px;

    padding: 12px;

    ::-webkit-scrollbar {
        display: none;
    }

    :before {
        content: '';
        position: absolute;
        top: 50%;
        left: 5%;
        border-top: 1px solid ${theme.colors.base40};
        background: ${theme.colors.base40};
        width: 90%;
        transform: translateY(-50%);
        z-index: 0;
    }

    div {
        z-index: 1;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        display: none;
    }
`;

const Status = ({ status }) => {
    return (
        <StatusContainer>
            {MatterStatus.map((s) => (
                <Badge key={s} color={status === s ? 'success' : 'base40'}>
                    {s}
                </Badge>
            ))}
        </StatusContainer>
    );
};

const CardContainer = styled(Box)`
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[4]}) {
        grid-template-columns: 1fr; 
    } 
`;

const GappedRow = styled(Row)`
    gap: ${(p) => p.theme.space[5]};
`;

const GappedColumn = styled(Column)`
    gap: ${(p) => p.theme.space[5]};
`;

const OutStandingRequirementsContainer = styled(Box)`
    display: block;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[2]}) {
        display: none;
    }
`;

const MatterDetailInner = () => {
    const {
        isAdmin,
        user,
    } = useTrackNTrace();
    const { matterDetails : currentMatter, isLoadingMatterDetails, matterId } = useTrackNTraceMatter();
    const {search} = useLocation();
    const urlParams = new URLSearchParams(search);
    const queryString = urlParams.get('focus');
    const focusElementRef = useRef(null);

    const isBorrower = user?.roles.includes(Role.Borrower);
    const showPhoneSupport = !isAdmin && !isBorrower;

    useLayoutEffect(() => {
        const ele = document.getElementById(queryString);
        focusElementRef.current = ele;
    });

    useEffect(() => {
        if(!focusElementRef.current) return;
        focusElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
    });

    if (isLoadingMatterDetails || !currentMatter) return <PageLoading />;

    return (
        <>
            <Layout header={<MatterHeader />}>
            <Status status={currentMatter.status} />
            <Flex px={[0, 0, 0, 0, 4, 8]} py={[0, 0, 0,0, 6]}>
                    <GappedRow collapseIndex="2" gap={10}>
                        <GappedColumn>
                            <MatterSummaryCard matter={currentMatter} />
                            <CardContainer>
                                <PartyCard party={currentMatter.party} />
                                {!!currentMatter.securities?.length && <SecurityCard securities={currentMatter.securities} /> }
                                <LoanCard loans={currentMatter.loans} />
                            </CardContainer>
                            <MatterLogCard logs={currentMatter.logs} />
                        </GappedColumn>
                        <GappedColumn>
                            <OutStandingRequirementsContainer>
                                <OutstandingRequirementsCard matterId={matterId} outstandingRequirements={currentMatter.outstandingRequirements || []} currentStatus={currentMatter.status} />
                            </OutStandingRequirementsContainer>
                            <DocumentsCard documents={currentMatter.documents} />
                            {!!currentMatter.specialConditions?.length && <SpecialConditionsCard specialConditions={currentMatter.specialConditions} />}
                        </GappedColumn>
                    </GappedRow>
                </Flex>
            </Layout>
            { showPhoneSupport && <PhoneSupport matterId={matterId} /> }
        </>
    );
};

const MatterDetails = () => {
    const match = useRouteMatch();
    const matterId = match?.params?.id;
    return (
        <TrackNTraceMatterProvider matterId={matterId} loadMatterDetails>
            <MatterDetailInner />
        </TrackNTraceMatterProvider>
    );
};

export default MatterDetails;
