import React, { useState } from 'react';
import { useTrackNTraceAdmin } from 'state/track/TrackNTraceAdminProvider';
import { toast, H5, Flex } from '@galilee/lilee';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import Layout from 'components/Layout'
import HomeHeader from 'components/HomeHeader';
import CallbackCard from './CallbackCard';
import CallbackSearchBar from './CallbackSearchBar';
import { Routes } from 'utils/constants';

const Title = styled(H5)`
    font-family: ${(p) => p.theme.fonts.titleOld};
    text-transform: none;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) {
        font-size: ${(p) => p.theme.fontSizes[5]};
    }
`;

const CallbackHeaderContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 90px;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[2]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        flex-direction: column;
        align-items: center;
        margin-right: 0;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[4]}) {
        flex-direction: row;
        justify-content: flex-start;
        margin-right: 0;
    }
`;

const Header = ({ setError }) => {
    const history = useHistory();
    const handleSubmit = (value) => {
        try {
            history.push(`${Routes.matter.base.replace(':id', value)}`);
        } catch (err) {
            toast.error('Matter ID does not exist');
            setError(err);
        }
    };
    return (
        <HomeHeader title="Admin">
            <CallbackHeaderContainer mb="0" mt={[0, 0, 0, 0, 4]}>
                <Title mr={[0, 0, 0, 0, 8]} mb={[6, 6, 6, 6, 0]}>
                    Callback Priority List
                </Title>
                <CallbackSearchBar onSubmit={handleSubmit} />
            </CallbackHeaderContainer>
        </HomeHeader>
    );
};

const CallbackList = () => {
    const { callbacks } = useTrackNTraceAdmin();
    const [selected, setSelected] = useState('');
    const [error, setError] = useState(null);

    const handleSelect = (value) => {
        if (error) {
            setError(null);
        }
        setSelected(value);
    };

    return (
        <Layout header={<Header setError={setError} />}>
            {callbacks.map((c) => (
                <CallbackCard key={c.callBackId} onSelect={() => handleSelect(c.callBackId)} isSelected={c.callBackId === selected} errored={!!error} {...c} />
            ))}
        </Layout>
    );
};

export default CallbackList;
