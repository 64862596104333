import React, {useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button, Row, Box, Panel, HR, P, Icon, Flex, ResponsiveGroup } from '@galilee/lilee';
import TextBoxField from 'components/TextBoxField';
import { SearchFieldsConfig, FiltersConfig } from 'utils/constants';
import SelectField from './SelectField';
import { StyledLink } from './advancedSearch';

const StyledPanel = styled(Panel)`
    margin-bottom: ${(p) => p.theme.space[8]};
`;

const FormRow = styled(Row)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 80px;

    @media screen and (max-width: ${(p) => p.theme.breakpoints[3]}) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: ${(p) => p.theme.space[11]};
    }

    @media screen and (max-width: ${(p) => p.theme.breakpoints[2]}) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: ${(p) => p.theme.space[6]};
    }

    @media screen and (max-width: ${(p) => p.theme.breakpoints[1]}) {
        grid-template-columns: 1fr;
    }
`;

const ClearButton = styled(Button)`
    background: ${(p) => p.theme.colors.base05 };
    color: ${(p) => p.theme.colors.base80 };
    :hover {
        background: ${(p) => p.theme.colors.base40 };
        color: ${(p) => p.theme.colors.white };
    }
`;

const DesktopView = styled(Flex)`
    display : block;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[2]}) {
        display: none;
    }
`;

const TabletAndMobileView = styled(Flex)`
    display : none;
    margin-bottom:  ${(p) => p.theme.space[7]};
    @media screen and (max-width: ${(p) => p.theme.breakpoints[2]}) {
        display: block;
    }
`;

const SearchForm = ({ onSearch, searchParams, filters, onSearchParamChange, onFilterChange, onClearAll}) => {
    const [showMoreCategories, setShowMoreCategories] = useState(false);

    return (
        <Flex flexDirection="column">
            <StyledPanel>
                    <Panel.Title color="tertiary" fontSize={[3, 3, 3, 3, 5]}>
                        Search Categories
                    </Panel.Title>
                    <HR />
                    <Box px="7" py="3" mb="5">
                        <P fontSize={[1, 1, 1, 1, 3]} color="base80" >Multiple search categories can be used at the same time</P>
                    </Box>
                    <DesktopView>
                        <FormRow>
                            { SearchFieldsConfig.map((field) => <TextBoxField key={field.name} value={searchParams[field.name] || ''} fieldConfig={field} onChange={(fieldName, value) => onSearchParamChange({[fieldName]: value})} />)}
                        </FormRow>
                        <FormRow>
                            { FiltersConfig.map((field) => (
                                <SelectField
                                    key={field.name}
                                    fieldConfig={field}
                                    defaultValue={field.options[0].value}
                                    value={filters[field.name]}
                                    onChange={(fieldName, value) => onFilterChange({[fieldName]: value})} />)
                                )}
                        </FormRow>
                    </DesktopView>
                    <TabletAndMobileView>
                        <FormRow>
                            { SearchFieldsConfig
                                .filter((i) => i.group === 1)
                                .sort((a,b) => a.orderInGroup - b.orderInGroup)
                                .map((field) => <TextBoxField key={field.name} value={searchParams[field.name] || ''} fieldConfig={field} onChange={(fieldName, value) => onSearchParamChange({[fieldName]: value})} />)
                            }
                        </FormRow>
                        <FormRow>
                        { FiltersConfig
                                .filter((i) => i.group === 1)
                                .sort((a,b) => a.orderInGroup - b.orderInGroup)
                                .map((field) => (
                                <SelectField
                                    key={field.name}
                                    fieldConfig={field}
                                    defaultValue={field.options[0].value}
                                    value={filters[field.name]}
                                    onChange={(fieldName, value) => onFilterChange({[fieldName]: value})} />)
                            )}
                        </FormRow>
                        <Flex alignItems="center" mt="4" mb="8" onClick={() => setShowMoreCategories((preState) => !preState)}>
                            <StyledLink color="link">{`View ${showMoreCategories ? 'less' : 'more'} categories`}</StyledLink>
                            { !showMoreCategories && <Icon name="NavDown" size="12px" ml="4" cursor="pointer"/> }
                            { showMoreCategories && <Icon name="NavUp" size="12px" ml="4" cursor="pointer"/> }
                        </Flex>
                        {showMoreCategories && (
                            <>
                                <FormRow>
                                { SearchFieldsConfig
                                    .filter((i) => i.group === 2)
                                    .sort((a,b) => a.orderInGroup - b.orderInGroup)
                                    .map((field) => <TextBoxField key={field.name} value={searchParams[field.name] || ''} fieldConfig={field} onChange={(fieldName, value) => onSearchParamChange({[fieldName]: value})} />)
                                }
                                </FormRow>
                                <FormRow>
                                { FiltersConfig
                                    .filter((i) => i.group === 2)
                                    .sort((a,b) => a.orderInGroup - b.orderInGroup)
                                    .map((field) => (
                                    <SelectField
                                        key={field.name}
                                        fieldConfig={field}
                                        defaultValue={field.options[0].value}
                                        value={filters[field.name]}
                                        onChange={(fieldName, value) => onFilterChange({[fieldName]: value})} />)
                                )}
                                </FormRow>
                            </>
                        )}
                    </TabletAndMobileView>
                    <ResponsiveGroup fullWidthBreakpointIndex={2} width={['100%', '100%','100%', '100%', '720px']}>
                        <ClearButton fullWidth mr="6" mb={[6, 6, 6, 6, 0]} onClick={onClearAll} >
                            Clear all
                        </ClearButton>
                        <Button fullWidth color="tertiary" onClick={async() => await onSearch()}>
                            Search
                        </Button>
                    </ResponsiveGroup >
            </StyledPanel>
        </Flex>
    );
};

SearchForm.defaultProps = {
    onSearch: () => {}
}

SearchForm.prototype = {
    onSearch: PropTypes.func,
    searchParams: PropTypes.shape({}).isRequired,
    onSearchParamChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({}).isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onClearAll: PropTypes.func.isRequired,
}

export default SearchForm;
