import React, { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import FourOhFour from 'components/FourOhFour';
import { PageLoading, utils } from '@galilee/lilee';
import TrackNTraceProvider, { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import ProtectedRoute from 'components/ProtectedRoute';
import Login from './login';
import MatterList from './matterList';
import MatterDetails from './matterDetails';
import Admin from './admin';
import MatterJourney from './matterJourney';
import Search from './search';
import { Routes, Role } from 'utils/constants';
import DelegateMatters from './delegateMatters';
import DelegationInstruction from './delegationInstruction';
import GroupDelegation from './groupDelegation';

const { useScrollToTop } = utils;

const Tracker = () => {
    useScrollToTop(true);
    const { user, isAdmin } = useTrackNTrace();

    // Admin user Home = Admin
    // Borrower user Home = MatterList
    // Broker user Home = MatterList
    // Lender user Home = Advanced Search
    // Lendi group delegation admin and support staff Home =  Advanced Search
    
    const getHomeScreen = useCallback(() => {
        if (!user) return PageLoading;
        if (isAdmin) return Admin;
        if (user?.roles.includes(Role.Lender) || user?.roles.includes(Role.GroupDelegationAdmin)|| user?.roles.includes(Role.CustomerSupportStaff)) return Search;
        return MatterList;
    }, [user, isAdmin]);

    return (
        <Switch>
            <Route path={Routes.login.base} component={Login} />
            <Route exact path={Routes.admin.base} component={Admin} />
            <Route exact path={Routes.delegationInstruction} component={DelegationInstruction}/>
            <ProtectedRoute exact path={Routes.delegate} component={DelegateMatters} />
            <ProtectedRoute path={Routes.groupDelegation} component={GroupDelegation} />
            <ProtectedRoute path={Routes.matter.detail} component={MatterDetails} />
            <ProtectedRoute path={Routes.search} component={Search} />
            <ProtectedRoute path={Routes.matter.base} component={MatterJourney} />
            <ProtectedRoute path={Routes.home} component={getHomeScreen()} />
            <Route component={FourOhFour} />
        </Switch>
    );
};

const Track = () => (
    <TrackNTraceProvider>
        <Tracker />
    </TrackNTraceProvider>
);

export default Track;
