import { httpRequest } from '../utils';

export const getDelegationsAsync = async (token) => {
    const response = await httpRequest('/api/delegation', 'get', null,  { Authorization: `Bearer ${token}` });
    return response;
};

export const addDelegationAsync = async (token, delegateeEmail) => {
    const response = await httpRequest(`/api/delegation`, 'post', delegateeEmail, { Authorization: `Bearer ${token}` });
    return response;
};

export const deleteDelegationAsync = async (token, delegationId) => {
    const response = await httpRequest(`/api/delegation/${delegationId}`, 'delete', null, { Authorization: `Bearer ${token}` });
    return response;
};
