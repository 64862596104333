import React, { useEffect, useRef, useState } from 'react';
import { PageLoading, H1,  H6, Flex,  Row } from '@galilee/lilee';
import styled from 'styled-components/macro';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { useHistory } from 'react-router';
import Layout from 'components/Layout';
import MatterCard from '../components/MatterCard';
import HomeHeader from 'components/HomeHeader';
import { Routes, Role } from 'utils/constants';

const UserSelectRow = styled(Row)`
    @media screen and (min-width: ${(p) => p.theme.breakpoints[1]}) {
        justify-content: center;
        align-items: center;
    }

    flex-direction: column;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[4]}) {
        flex-direction: row;
    }
`;

const TitleSmall = styled(H6)`
    font-family: 'Zilla Slab', serif;
    text-transform: none;
    margin-bottom: ${(p) => p.theme.space[4]};
    text-align: left;

    @media screen and (min-width: ${(p) => p.theme.breakpoints[1]}) {
        text-align: center;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) {
        display: none;
    }
`;

const TitleLarge = styled(H1)`
    font-family: 'Zilla Slab', serif;
    margin-right: ${(p) => p.theme.space[5]};

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        padding-bottom: ${(p) => p.theme.space[5]};
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        display: none;
    }
`;

const HeaderContainer = styled(Flex)`
    flex-direction: column;
    margin-bottom: 0;
`;

const Header = ({ mattersToDisplay }) => {
    return (
        <HomeHeader title="Home" middleColSpan={3}>
            <HeaderContainer>
                <TitleSmall textAlign="center">{mattersToDisplay?.length} Matters Found</TitleSmall>
                <UserSelectRow>
                    <TitleLarge textAlign="center">{mattersToDisplay?.length} Matters Found</TitleLarge>                    
                </UserSelectRow>
            </HeaderContainer>
        </HomeHeader>
    );
};

const MatterList = () => {
    const [search] = useState('');
    const { isLoading, matters, isAdmin, selectedUser, user, actions } = useTrackNTrace();

    const history = useHistory();

    const getMattersRef = useRef();

    useEffect(() => {
        if(getMattersRef.current) return;
        getMattersRef.current = actions.getMatters;
    }, [actions])

    useEffect(() => {
        if (isAdmin) {
            history.push(Routes.admin.base);
        }
    }, [isAdmin, history]);

    useEffect(() => {
        async function fetchMatters() {
            if (isAdmin) return;
            await getMattersRef.current();
        }
        fetchMatters();
    }, [isAdmin]);

    const handleClick = (id) => {
        // Lenders and brokers go to detail by default, everyone else goes to journey
       
        let url = (user?.roles.includes(Role.Lender) || user?.roles.includes(Role.Broker) || user?.roles.includes(Role.GroupDelegationAdmin) || user?.roles.includes(Role.CustomerSupportStaff)) ? Routes.matter.detail.replace(':id', id) : Routes.matter.base.replace(':id', id);
        history.push(url);
    };

    let mattersToDisplay = [...matters];

    if (selectedUser) {
        mattersToDisplay = mattersToDisplay.filter((m) => m.owner === selectedUser);
    }

    mattersToDisplay = mattersToDisplay.filter(
        (i) => i.names.some((n) => n.toLowerCase().includes(search)) || i.suburbs.some((s) => s.toLowerCase().includes(search))
    );

    // <SearchBox mx="auto">
    //     <SearchBar onChange={(val) => setSearch(val.toLowerCase())} position="relative" placeHolder="Find a particular matter" />
    // </SearchBox>
    return (
        <Layout header={<Header mattersToDisplay={mattersToDisplay} />}>
            {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
            {mattersToDisplay.map((matter) => (
                <MatterCard key={matter.goldId} {...matter} onClick={() => handleClick(matter.goldId)} />
            ))}
        </Layout>
    );
};

export default MatterList;
