import React from 'react';
import PropTypes from 'prop-types';
import { Table, TBody, TR, TD } from '@galilee/lilee';
import SortableHeader from './components/SortableHeader';
import { columnPropTypes } from './components/Header';
import styled from 'styled-components/macro';
import { format } from 'date-fns/esm';
import { StyledLink } from 'components/advancedSearch';

const StyledTD = styled(TD)`
    font-size: ${(p) => p.theme.fontSizes[1]};
    color: ${(p) => p.theme.colors.base};
    padding: ${(p) => p.theme.space[3]};
`;

const DataRow = ({data, columnDefinition, rowIndex, onClick}) => {
    return (
        <TR>
            {
                columnDefinition.map((c) => (
                    <StyledTD key={c.name} fontSize="1" bg={rowIndex % 2 === 0 ? 'white' : 'base05'}>
                        {(c.isDate && !!data[c.name]) ? format(new Date(data[c.name]), 'dd/MM/yyyy') : (c.isLink ? <StyledLink onClick={() => onClick(data[c.name])}>{data[c.name]}</StyledLink> : data[c.name])}
                    </StyledTD> )
                )
            }
        </TR>
    );
}

DataRow.propTypes = {
    data: PropTypes.shape({}),
    columnDefinition: PropTypes.arrayOf(columnPropTypes),
    rowIndex: PropTypes.number,
};


const DataGrid = ({  columns, data, onSort, sort, onClick }) => (
    <Table>
        <SortableHeader columns={columns} onSort={onSort} sort={sort} />
        <TBody>
            {data.map((i, idx) => <DataRow key={idx.toString()} data={i} columnDefinition={columns} rowIndex={idx} onClick={onClick}/>)}
        </TBody>
    </Table>
);

DataGrid.defaultProps = {
    data: [],
    onClick: () => {}
};

DataGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    columns: PropTypes.arrayOf(columnPropTypes).isRequired,
    onClick: PropTypes.func,
    ...SortableHeader.propTypes,
};

export default DataGrid;
