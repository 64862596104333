import React, { useRef, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { Flex, Text, Icon, UL, LI, Box, ResponsiveGroup, Button, utils } from '@galilee/lilee';
import DataGrid from './DataGrid';
import Dropdown from './Dropdown';
import DataList from './advancedSearch/DataList';
import { Pagination, BoldText, StyledLink } from './advancedSearch';
import { AdvancedSearchSortRules, SearchResultColumnDefinition } from 'utils/constants';
import { Routes, Role } from 'utils/constants';

const { useScrollToTop } = utils;

const DataGridContainer = styled(Flex)`
    flex-direction: column;
    background-color: white;
    padding: ${(p) => p.theme.space[9]};
    margin: 0 -40px;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[3]}) {
        display: none;
    }
`;

const DataGridHeader = styled(Flex)`
    margin-bottom: ${(p) => p.theme.space[8]};
    align-items: center;
    justify-content: space-between;
`;

const DataGridFooter = styled(Flex)`
    margin-top: ${(p) => p.theme.space[8]};
`;

const DropdownWrapper = styled(Box)`
    position: relative;
    width: 85px;
    margin-left: ${(p) => p.theme.space[4]};
`;

const BackToButton = styled(Button)`
    background: ${(p) => p.theme.colors.base80 };
`;

const NoResultsBannerContainer = styled(Flex)`
    align-items: flex-start;
    flex-direction: column;
    background-color: ${(p) => p.theme.colors.base10 };
    padding: ${(p) => p.theme.space[6]};
    margin-bottom: ${(p) => p.theme.space[7]};
    border-radius: ${(p) => p.theme.radii[3]};
    max-width: 800px;
`;

const NoResultsButtonWrapper = styled(Box)`
    display: none;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[3]}) {
        display: block;
    }
`;

const DataListContainer = styled(Flex)`
    flex-direction: column;
    display: none;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[3]}) {
        display: block;
    }
`;

const DataListHeader = styled(Flex)`
    flex-direction: column;
    background: ${(p) => p.theme.colors.base05 };
`;

const DataSummary = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[2]}) {
        flex-direction: column;
        gap: ${(p) => p.theme.space[4]};
    }
`;

const SortDropdownWrapper = styled(Box)`
   position: relative;
   width: 100%;
`;

const BackToTopLink = styled(Text)`
    text-decoration: underline;
    cursor: pointer;
`;

const SearchResults = ({ onSearch, sort, onSortChange, pagination, onPaginationChange, onExport }) => {
    const history = useHistory(); 
    const { setScrollToTop } = useScrollToTop(true);
    const { searchResults, user } = useTrackNTrace();

    const resultsRef = useRef(null);
    
    const {total, currentPage, matters,t24AccountsTotal, ultracsAccountsTotal } = searchResults;

    const { itemsPerPage: pageCount } = pagination; 

    useEffect(() => {
        resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start'});
    },[searchResults])

    const pageDropdownOptions = useMemo(() => ([10, 20, 30]).map((opt,idx) => ({
        id: `${opt}_${idx}`,
        value: opt,
        label: <Text fontSize="1" fontWeight="medium">{opt}</Text>,
        controlLabel: <Text fontSize="0" fontWeight="medium">{opt}</Text>,
      })),[]);

    const sortRulesOptions = AdvancedSearchSortRules.map((opt) => ({
        id: opt.value,
        value: opt.value,
        label: <Text fontSize="1" fontWeight="medium">{opt.title}</Text>,
        controlLabel: <Text fontSize="0" fontWeight="medium" uppercase>{opt.title}</Text>,
      }));
      
    const hasResults = matters && !!matters.length;

    const handleClick = (id) => {
        // Lenders, brokers, team admin and support staff go to detail by default, everyone else goes to journey
        let url = (user?.roles.includes(Role.Lender) || user?.roles.includes(Role.Broker) || user?.roles.includes(Role.GroupDelegationAdmin) || user?.roles.includes(Role.CustomerSupportStaff))? Routes.matter.detail.replace(':id', id) : Routes.matter.base.replace(':id', id);
        history.push(url);
    };

    return (
        <Box ref={resultsRef}>
            {
                hasResults && (
                    <>
                        <DataGridContainer>
                            <DataGridHeader>
                                <BoldText>{total} Matters found—displaying records { (currentPage - 1) * pageCount + 1} to {Math.min(currentPage * pageCount, total)}</BoldText>
                                <Flex alignItems="center" >
                                    <BoldText >Items per page</BoldText>
                                    <DropdownWrapper>
                                        <Dropdown
                                            controlStyles={{justifyContent: 'space-between'}}
                                            rightIcon={<Icon color="base" name="NavDown" size="12px"/>}
                                            value={pagination.itemsPerPage}
                                            options={pageDropdownOptions}
                                            onChange={({value}) => {
                                                onPaginationChange({ itemsPerPage : value });
                                            }}
                                        />
                                    </DropdownWrapper>
                                </Flex>
                               {user?.email?.includes('@mebank.com.au') && <BoldText>{t24AccountsTotal} T24 Accounts; {ultracsAccountsTotal} Ultracs Accounts</BoldText>}
                               {!user?.email?.includes('@mebank.com.au') && <BoldText>{t24AccountsTotal + ultracsAccountsTotal} Loan Accounts</BoldText>}
                                <StyledLink onClick={onExport}>Export to Excel</StyledLink>
                            </DataGridHeader>
                            <Text mb="6" ml="4">Sort search results by clicking on the headers below</Text>
                            <DataGrid
                                columns={SearchResultColumnDefinition}
                                data={matters}
                                sort={{sortBy : sort.sortBy, sortAscending: sort.sortByOrder === "asc" }}
                                onSort={(sort) => {
                                    const { sortBy, sortAscending } = sort;
                                    onSortChange({sortBy: sortBy, sortByOrder: sortAscending ? 'asc': 'desc'})
                                }}
                                onClick={(val) => handleClick(val)}
                            />
                            <DataGridFooter>
                                <Pagination
                                    count={Math.ceil(total/pageCount)}
                                    currentPage={currentPage}
                                    onClick={({page}) => onPaginationChange({ searchPage : +page }) }/>
                            </DataGridFooter>
                        </DataGridContainer>
                        <DataListContainer>
                            <DataListHeader px={[2, 2, 2, 9]} pb="8">
                                <DataSummary mb="6">
                                    <BoldText>{total} Matters found</BoldText>
                                    <Text>12 T24 Accounts; 86 Ultracs Accounts</Text>
                                    <StyledLink>Export to Excel</StyledLink>
                                </DataSummary>
                                <ResponsiveGroup fullWidthBreakpointIndex={2} width="100%">
                                    <SortDropdownWrapper>
                                        <Dropdown
                                            controlStyles={{justifyContent: 'center', bg: 'tertiary20', mt: '6' }}
                                            rightIcon={<Icon color="base" name="NavDown" size="12px"/>}
                                            value={`${sort.sortBy}-${pagination.sortByOrder ? 'asc' : 'desc'}`}
                                            options={sortRulesOptions}
                                            onChange={({value}) => {
                                                const sort = AdvancedSearchSortRules.find((i) => i.value === value);
                                                if(!sort) return;
                                                const {sortBy, sortAscending } = sort.rule;
                                                onSortChange({ sortBy: sortBy, sortByOrder: sortAscending ? 'asc': 'desc' });
                                            }}
                                        />
                                    </SortDropdownWrapper>
                                    <Button fullWidth color="tertiary" mt="6" ml={[0, 0, 0, 6]} onClick={() => onSearch()}>
                                        Search Again
                                    </Button>
                                </ResponsiveGroup >
                            </DataListHeader>
                            <Flex flexDirection="column" bg="white" p="9" mx="-40px" my="0">
                                <DataList data={matters} idField="matterID" onClick={(val) => history.push(Routes.matter.base.replace(':id', val))}/>
                                <BackToTopLink color="base40" fontSize="1" mt="6" mb="8" onClick={() => setScrollToTop(true)}>Back to top</BackToTopLink>
                                <Pagination
                                    count={Math.ceil(total/pageCount)}
                                    currentPage={currentPage}
                                    onClick={({page}) => onPaginationChange({ searchPage : +page }) }
                                />
                            </Flex>
                        </DataListContainer>
                    </>
                )
            }
            { !hasResults && (
                <Flex flexDirection="column" bg="white" p="8" mx="-40px">
                    <NoResultsBannerContainer>
                            <Flex>
                                <Icon name="WarningCircle" color="error" size="24px" mr="4"/>
                                <Text color="error" fontSize="1" fontWeight="medium">No search results found!</Text>
                            </Flex>
                            <UL fontSize="1" ml="6">
                                <LI>Have you entered correct values in the search fields?</LI>
                                <LI>Remove or add search categories to widen or narrow your search</LI>
                            </UL>
                    </NoResultsBannerContainer>
                    <NoResultsButtonWrapper>
                        <ResponsiveGroup fullWidthBreakpointIndex={2} width={['100%', '100%','100%', '100%', '720px']}>
                            <Button fullWidth color="tertiary" mr="6" mb={[6, 6, 6, 6, 0]} onClick={() => onSearch()}>
                                Search Again
                            </Button>
                            <BackToButton fullWidth onClick={() => history.push('/')}>
                                Back to home
                            </BackToButton>
                        </ResponsiveGroup >
                    </NoResultsButtonWrapper>
                </Flex>
            )}
        </Box>     
    );
}

export default SearchResults;


