import React, { useState } from 'react';
import { Icon, Input, Flex } from '@galilee/lilee';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled(Flex)`
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${(p) => p.theme.colors.base20};
`;

const StyledInput = styled(Input)`
    background: transparent;
    border: none;
    box-shadow: none;
    margin-right: ${(p) => p.theme.space[4]};
    color: white;
    &:focus {
        outline: 0;
        border: none;
        border-color: transparent;
        box-shadow: none;
    }

    ::placeholder {
        font-weight: 500;
        font-size: ${(p) => p.theme.fontSizes[0]};
        color: white;
        text-transform: uppercase;
        opacity: 1;
        padding: ${(p) => p.theme.space[4]};
    }

    padding: 0 0 ${(p) => p.theme.space[4]} 0;
`;

const StyledIcon = styled(Icon)`
    margin: 0 0 ${(p) => p.theme.space[4]} 0;
`;

export default function CallbackSearchBar({ onSubmit }) {
    const [search, setSearch] = useState('');
    return (
        <Wrapper>
            <StyledInput onChange={(e) => setSearch(e.target.value)} placeholder="Matter Lookup" />
            <StyledIcon name="Search" size="16px" color="white" cursor="pointer" onClick={() => onSubmit(search)} />
        </Wrapper>
    );
}

CallbackSearchBar.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
