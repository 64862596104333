import { useMemo } from 'react';
import { Absolute, Icon, CircleIcon, P, Flex, Row, H3, Popover, IconSection } from '@galilee/lilee';
import styled from 'styled-components/macro';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { useHistory } from 'react-router';
import { Routes, Role } from 'utils/constants';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import { useRouteMatch } from 'react-router';

const Label = styled.label``;

const Container = styled.span`
    position: absolute;
    right: 0;
    z-index: 101;
    width: 72px;
    height: 72px;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    top: -36px;
`;

const HamburgerIcon = styled.span`
    position: absolute;
    top: 48px;
    right: 0;
    width: 40px;
    height: 5px;
    background: ${(p) => p.theme.colors.base05};
    display: block;
    transform-origin: center;
    transition: 0.5s ease-in-out;
    border-radius: 10px;

    :after,
    :before {
        transition: 0.5s ease-in-out;
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: ${(p) => p.theme.colors.base05};
        border-radius: 10px;
    }

    :before {
        top: -16px;
    }

    :after {
        bottom: -16px;
    }
`;

const Input = styled.input`
    display: none;

    :checked + span > span {
        transform: rotate(45deg);
    }

    :checked + span > span:after {
        transform: rotate(90deg);
        bottom: 0;
    }

    :checked + span > span:before {
        transform: rotate(90deg);
        top: 0;
    }

    :checked + span + div {
        opacity: 1;
        user-select: auto;
        pointer-events: auto;
    }
`;

const Items = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-top: ${(p) => p.theme.space[8]};

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) {
        margin-top: ${(p) => p.theme.space[9]};
    }
`;

const ItemContainer = styled.li`
    padding: ${(p) => p.theme.space[5]} 0;
    border-bottom: 1px solid ${(p) => p.theme.colors.base80};

    :last-child {
        border-bottom: 0;
    }
`;

const ChildItemContainer = styled.li`
    padding: ${(p) => p.theme.space[4]} 0;
    text-decoration: underline;

    * {
        font-weight: ${(p) => p.theme.fontWeights.medium};
    }
`;

const ChildItems = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const Menu = styled.div`
    pointer-events: none;
    user-select: none;
    opacity: 0;
    background: ${(p) => p.theme.colors.base};
    color: ${(p) => p.theme.colors.white};
    transition: 0.25s 0s ease-in-out;
    position: absolute;
    top: -44px;
    right: -24px;
    z-index: 100;
    width: 300px;

    transform: translate(0, 0);

    padding: 36px 24px 0;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) {
        padding: 36px 36px 0;
        right: -36px;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[2]}) {
        width: 440px;
    }
`;

const UserTitle = styled(P)`
    margin: 0;
    margin-right: ${(p) => p.theme.space[9]};
    color: ${(p) => p.theme.colors.base40};
    font-family: ${(p) => p.theme.fonts.title};

    overflow: hidden;
    text-overflow: ellipsis;
`;

const SizedCircleIcon = styled(CircleIcon)`
    width: ${(p) => p.theme.space[5]};
    height: ${(p) => p.theme.space[5]};

    > svg {
        width: ${(p) => p.theme.space[3]} !important;
        height: ${(p) => p.theme.space[3]} !important;
    }
`;

const User = ({ name }) => {
    return (
        <Flex alignItems="center">
            <SizedCircleIcon name="User" color="base80" background="base05" mr="2" />
            <UserTitle>{name}</UserTitle>
        </Flex>
    );
};

const ItemTitle = styled(H3)`
    text-decoration-color: ${(p) => p.theme.colors.base20};

    font-size: ${(p) => p.theme.fontSizes[1]};

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[2]}) {
        font-size: ${(p) => p.theme.fontSizes[2]};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) {
        font-size: ${(p) => p.theme.fontSizes[3]};
    }
`;

const ItemSubtitle = styled(P)`
    margin: ${(p) => p.theme.space[4]} 0 0 0;
    font-family: ${(p) => p.theme.fonts.title};
    color: ${(p) => (p.active ? p.theme.colors.tertiary60 : p.theme.colors.base40)};

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        display: none;
    }
`;

const ItemAnchor = styled.a`
    color: ${(p) => (p.active ? p.theme.colors.tertiary60 : p.theme.colors.base20)};
    svg:first-of-type {
        color: ${(p) => (p.active ? p.theme.colors.tertiary60 : p.theme.colors.base20)};
    }

    text-decoration: none;
    cursor: pointer;

    :hover,
    :active,
    :focus {
        color: ${(p) => (p.error ? p.theme.colors.warn : p.theme.colors.tertiary60)};
        text-decoration: none;

        h3,
        p {
            color: ${(p) => p.theme.colors.tertiary60};
        }

        svg:first-of-type {
            color: ${(p) => (p.error ? p.theme.colors.warn : p.theme.colors.tertiary60)};
        }
    }

    :visited {
        color: ${(p) => (p.active ? p.theme.colors.tertiary60 : p.theme.colors.base20)};
    }
`;

const SizedIcon = styled(Icon)`
    width: ${(p) => p.theme.space[5]};
    height: ${(p) => p.theme.space[5]};
    color:  ${(p) => p.theme.colors[p.color]} !important;
    @media screen and (min-width: ${(p) => p.theme.breakpointsPlus[2]}) {
        width: ${(p) => p.theme.space[5]};
        height: ${(p) => p.theme.space[5]};
    }
`;

const ItemChildren = styled(Flex)`
    flex-direction: column;
    margin-left: ${(p) => p.theme.space[6]};
    @media screen and (min-width: ${(p) => p.theme.breakpointsPlus[2]}) {
        display: none;
    }
`;

const ChildItem = ({ item }) => {
    return (
        <ChildItemContainer>
            <ItemAnchor onClick={item.action ? item.action : undefined} active={false}>
                <Row alignItems="center">
                    <SizedIcon name={item.icon} color="base20" mr="3" />
                    <ItemTitle>{item.title}</ItemTitle>
                </Row>
            </ItemAnchor>
        </ChildItemContainer>
    );
};

const Item = ({ item }) => {
    const isError = 'error' in item && item.error;
    const isActive = 'isActive' in item ? item.isActive() : false;
    return (
        <ItemContainer>
            <ItemAnchor onClick={item.action ? item.action : undefined} active={isActive} error={isError}>
                <Row alignItems="center">
                    {isError ? (
                        <Popover position="bottom" bg="errorLight" width="100%">
                            <SizedIcon name="WarningCircle" color="warn" mr="3" />
                            <ItemTitle>{item.title}</ItemTitle>
                            <Popover.Content>
                                <IconSection iconColor="error" titleColor="error" iconName="WarningCircle" title={item.error} />
                            </Popover.Content>
                        </Popover>
                    ) : (
                        <>
                            <SizedIcon name={item.icon} color="base20" mr="3" />
                            <ItemTitle>{item.title}</ItemTitle>
                        </>
                    )}
                </Row>
                <ItemSubtitle active={isActive}>{item.subtitle}</ItemSubtitle>
            </ItemAnchor>
            {item.children?.length && (
                <ItemChildren>
                    <ChildItems>
                        {item.children.map((child) => (
                            <ChildItem key={child.title} item={child} />
                        ))}
                    </ChildItems>
                </ItemChildren>
            )}
        </ItemContainer>
    );
};

// Broker sees: Home, Reports, Log out
// Lender sees: Advanced Search (Home), Reports, Log out
// Admin sees: Home, Advanced Search, Reports, Log out
// Borrower sees: Home, Matter Journey, Matter detail, Log out
const getMenuItems = (history, match, matterId, user, matterDetailError, forceShow) => [
    {
        title: 'Home',
        icon: 'Home',
        subtitle: '',
        filter: () => forceShow || !user?.roles.includes(Role.Lender),
        action: () => history.push('/'),
        isActive: () => match.path === '/' || match.path === Routes.search,
    },
    {
        title: 'Advanced Search',
        icon: 'Search',
        subtitle: 'Locate matters via multiple search categories',
        filter: () => forceShow || user?.roles.some(r => [Role.Lender, Role.Admin, Role.Broker, Role.GroupDelegationAdmin, Role.CustomerSupportStaff].includes(r)),
        action: () => history.push(Routes.search),
        // If at the /search version, not the home version, only /search is valid
        isActive: () => match.path === Routes.search,
    },
    {
        title: 'Matter Journey',
        icon: 'BulletList',
        subtitle: 'Visual snap shot of a matter’s progress',
        filter: () => forceShow || !!matterId,
        action: () => history.push(`${Routes.matter.base.replace(':id', matterId)}`),
        isActive: () => match.path === Routes.matter.base,
    },
    {
        title: 'Matter Details',
        icon: 'InfoCircle',
        subtitle: 'Detailed overview including outstanding requirements',
        filter: () => forceShow || (!user?.roles.includes(Role.Borrower) && !!matterId),
        action: () => history.push(`${Routes.matter.detail.replace(':id', matterId)}`),
        isActive: () => match.path === Routes.matter.detail,
        error: matterDetailError,
        children: [
            {
                title: 'Documents',
                icon: 'Stack',
                action: () =>
                    history.push({
                        pathname: `${Routes.matter.detail.replace(':id', matterId)}`,
                        search: new URLSearchParams({ focus: 'documents' }).toString(),
                    }),
            },
            {
                title: 'Special conditions',
                icon: 'Certificate',
                action: () =>
                    history.push({
                        pathname: `${Routes.matter.detail.replace(':id', matterId)}`,
                        search: new URLSearchParams({ focus: 'specialConditions' }).toString(),
                    }),
            },
            {
                title: 'Party Details',
                icon: 'User',
                action: () =>
                    history.push({
                        pathname: `${Routes.matter.detail.replace(':id', matterId)}`,
                        search: new URLSearchParams({ focus: 'partyDetails' }).toString(),
                    }),
            },
            {
                title: 'Security Details',
                icon: 'Home',
                action: () =>
                    history.push({
                        pathname: `${Routes.matter.detail.replace(':id', matterId)}`,
                        search: new URLSearchParams({ focus: 'securityDetails' }).toString(),
                    }),
            },
            {
                title: 'Loan Details',
                icon: 'Document',
                action: () =>
                    history.push({
                        pathname: `${Routes.matter.detail.replace(':id', matterId)}`,
                        search: new URLSearchParams({ focus: 'loanDetails' }).toString(),
                    }),
            },
            {
                title: 'Matter Log',
                icon: 'BulletList',
                action: () =>
                    history.push({
                        pathname: `${Routes.matter.detail.replace(':id', matterId)}`,
                        search: new URLSearchParams({ focus: 'matterLog' }).toString(),
                    }),
            },
        ],
    },
    {
        title: 'Delegate Matters',
        icon: 'User',
        subtitle: 'Delegate your matters to others',
        filter: () => forceShow || user?.roles?.includes(Role.Broker),
        action: () => history.push(Routes.delegate),
        isActive: () => match.path === Routes.delegate,
    },
    {
        title: 'Group Delegation',
        icon: 'User',
        subtitle: 'Set up group delegation for your organization',
        filter: () => forceShow || user?.roles?.includes(Role.GroupDelegationAdmin) || user?.roles?.includes(Role.CustomerSupportStaff),
        action: () => history.push(Routes.groupDelegation),
        isActive: () => match.path === Routes.groupDelegation,
    },
];

const HamburgerAbsolute = styled(Absolute)`
    top: 24px;
    right: 24px;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) {
        top: 36px;
        right: 36px;
    }
`;

const HamburgerMenu = () => {
    const history = useHistory();
    const match = useRouteMatch();

    const {
        user,
        actions: { logout },
    } = useTrackNTrace();

    // When we are in a listing where there is no individual matter selected
    // this will be empty, so guard for it
    let matterNumber = undefined;
    let currentMatter = undefined;

    const matterContext = useTrackNTraceMatter();
    if(matterContext) {
        const { matterId, isMatterDetailsPage, matterDetails, matterJourney } = matterContext;
        matterNumber = matterId;
        currentMatter = isMatterDetailsPage ? matterDetails : matterJourney;
    }
   
    const matterDetailError = currentMatter?.outstandingRequirements?.length ? 'There are outstanding requirements' : undefined;

    const menuItems = useMemo(
        () =>
            getMenuItems(history, match, matterNumber, user, matterDetailError)
                .filter((item) => ('filter' in item ? item.filter() : true))
                .map((item) => <Item key={item.title} item={item} />),
        [history, match, matterNumber, user, matterDetailError]
    );
    
    const handleLogOutClick = () => {        
        logout(user.email);        
    };

    return (
        <HamburgerAbsolute>
            <Label>
                <Input type="checkbox" />
                <Container>
                    <HamburgerIcon role="button" aria-pressed={false} />
                </Container>
                <Menu>
                    <User name={user?.email ?? 'Lender Lenny'} />
                    <Items>
                        {menuItems}
                        <Item
                            item={{
                                title: 'Log Out',
                                icon: 'Logout',
                                subtitle: '',
                                action: handleLogOutClick,
                            }}
                        />
                    </Items>
                </Menu>
            </Label>
        </HamburgerAbsolute>
    );
};

export default HamburgerMenu;
