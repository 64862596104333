import React from 'react';
import PropTypes from 'prop-types';
import { THead, TR, TH } from '@galilee/lilee';
import Header, { columnPropTypes, sortPropTypes } from './Header';
import styled from 'styled-components/macro';

const StyledTH = styled(TH)`
    border-bottom: 1px solid ${(p) => p.theme.colors.base80 };
    padding: ${(p) => p.theme.space[6]} ${(p) => p.theme.space[3]};
`;

const SortableHeader = ({ onSort, columns, sort }) => {
    if (!columns) return null;
    return (
        <THead>
            <TR>
                {columns.map((col) => (
                    <StyledTH key={col.name}>
                        <Header onSort={onSort} column={col} sort={sort} />
                    </StyledTH>
                ))}
            </TR>
        </THead>
    );
};

SortableHeader.defaultProps = {
    columns: null,
};

SortableHeader.propTypes = {
    columns: PropTypes.arrayOf(columnPropTypes),
    sort: sortPropTypes.isRequired,
    onSort: PropTypes.func.isRequired,
};

export default SortableHeader;
