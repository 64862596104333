import { useState, useMemo } from 'react';
import { Label, Input, TextArea, Text, Modal, Button, Dropdown, Flex, H3, P, StepsItem, Icon, Box, PageLoading} from '@galilee/lilee';
import { UnmarginedPanel, SizedIcon } from "./index";
import styled from 'styled-components/macro';
import { format } from 'date-fns';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import { fileNotes } from 'utils/constants';

const UnpaddedDropdown = styled(Dropdown)`
    padding: 14px;
    text-align: center;

    flex: 1;
    width: unset;

    border-radius: 32px;

    text-transform: uppercase;
    font-size: ${(p) => p.theme.fontSizes[0]};
    font-weight: medium;
    line-height: 24px;
    letter-spacing: 0.06em;

    margin: ${(p) => p.margin ?? 0};
`;

const ORFlex = styled(Flex)`
    margin-top: 24px;

    // Dropdown styles the select, not the parent div
    > div {
        width: unset;
        flex: 1;
    }
`;

const ModalFormContainer = styled(Flex)`
    flex-direction: column;
    width: 70%;

    div:first-child {
        margin-bottom: 32px;
    }
`;

const OutstandingHeaderContainer = styled.div`
    background-color: ${(p) => p.bg};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 36px 36px;
    color: ${(p) => p.color ?? 'white'};
`;

const ReminderContainer = styled(Flex)`
    align-items: flex-start;
    border: 1px solid ${(p) => p.theme.colors.base20};
    border-radius: 10px;
    padding: ${(p) => p.theme.space[6]}; ${(p) => p.theme.space[7]};
    margin-top: -${(p) => p.theme.space[8]};
`;

const OutstandingHeader = ({ title, subtitle, type }) => {
    let bg = (p) => p.theme.colors.tertiary60;
    let icon = 'InfoCircle';

    switch (type) {
        case 'success':
            bg = 'success';
            icon = 'Tick';
            break;
        case 'error':
            bg = (p) => p.theme.colors.warn;
            icon = 'WarningCircle';
            break;
        case 'warn':
            bg = (p) => p.theme.colors.warn;
            icon = 'WarningCircle';
            break;
        case 'not-proceeding':
                bg = (p) => p.theme.colors.base40;               
                break;
        default:
            break;
    }

    return (
        <OutstandingHeaderContainer bg={bg}>
            <SizedIcon name={icon} color={'white'} mr={[4]} height="64px" width="64px" />
            <Flex flexDirection="column">
                <H3 mb="3">{title}</H3>
                <H3>{subtitle}</H3>
            </Flex>
        </OutstandingHeaderContainer>
    );
};

export const OutstandingRequirements = ({ matterId, outstandingRequirements, currentStatus}) => {
    const { addFileNoteAction, isAddingFileNote } = useTrackNTraceMatter();
    const [showModal, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);    
    const [fileNoteParams, setFileNoteParams] = useState({
        name: "",
        proposedSettlementDate: undefined,
        additionalComments: "",
        accountName: "",
        bsb: "",
        accountNumber: "",
        borrowersSolicitor: "",
        contactPerson: "",
        emailAddress: "",
        mobileNumber: "",
        retainForConstruction: "",
        description: "",        
      });

      const clearFileNoteParams = () => {        
        setFileNoteParams({
            name: "",
            proposedSettlementDate: undefined,
            additionalComments: "",
            accountName: "",
            bsb: "",
            accountNumber: "",
            borrowersSolicitor: "",
            contactPerson: "",
            emailAddress: "",
            mobileNumber: "",
            retainForConstruction: "",
            description: ""
          });          
      };

    const getHeaderForStatus = (status) => {
        switch (status) {
            case 'Not proceeding matter':
                return <OutstandingHeader title="Not Proceeding Matter " type="not-proceeding" />;
            case 'Settled':
                return <OutstandingHeader title="Matter Settled " type="info" />;
            case 'InProgress':
                return <OutstandingHeader title="Matter in progress: " subtitle="No outstanding requirements" type="info" />;                
            case 'Delayed':
                return <OutstandingHeader title="Matter delayed: " subtitle="Outstanding requirements" type="error" />;
            default:
                return <></>;
        }
    };

    const fileNoteNames = fileNotes.map((x) => x.name);
    const onFileNoteParamsChange = e => { setFileNoteParams({...fileNoteParams, [e.target.name]: e.target.value})}    
    const renderField = (field) => {
        switch (field.type) {
            case 'text':
                return (
                    <div key={field.id + '_' + field.key}>
                        <Label htmlFor={field.id}>{field.label}</Label>
                        <Input id={field.id} name={field.id} type="text" value={fileNoteParams[field.name]} onChange={onFileNoteParamsChange}/>
                    </div>
                );
            case 'date':
                return (
                    <div key={field.id + '_' + field.key}>
                        <Label htmlFor={field.id}>{field.label}</Label>
                        <Input id={field.id} name={field.id} type="date" value={fileNoteParams[field.name]} onChange={onFileNoteParamsChange}/>
                    </div>
                );
            case 'textArea':
                return (
                    <div key={field.id + '_' + field.key}>
                        <Label htmlFor={field.id}>{field.label}</Label>
                        <TextArea id={field.id} name={field.id} value={fileNoteParams[field.name]} onChange={onFileNoteParamsChange}/>
                    </div>
                );
            case 'email':
                return (
                    <div key={field.id + '_' + field.key}>
                        <Label htmlFor={field.id}>{field.label}</Label>
                        <Input id={field.id} name={field.id} type="email" value={fileNoteParams[field.name]} onChange={onFileNoteParamsChange}/>
                    </div>
                );
            case 'currency':
                return (
                    <div key={field.id + '_' + field.key}>
                        <Label htmlFor={field.id}>{field.label}</Label>
                        <Input id={field.id} name={field.id} type="text" value={fileNoteParams[field.name]} onChange={onFileNoteParamsChange}/>
                    </div>
                );
            default:
                return <Text>Field</Text>;
        }
    };

    const outstandingItems = useMemo(() => outstandingRequirements.map((item, index) => { 
        const content = (<Flex flexDirection="column">
            <Flex mb="2">
                <P fontWeight="bold" mr="4" fontSize={0}>To be provided by:</P>
                <P fontSize={0}>{item.provider}</P>
            </Flex>
            <Flex mb="2">
                <P fontWeight="bold" mr="4" fontSize={0}>Date advised:</P>
                {!!item.dateAdvised && <P fontSize={0}>{format(new Date(item.dateAdvised), 'dd LLL yy HH:mm')}</P>}
            </Flex>
            <Flex mb="2">
                <P fontWeight="bold" mr="4" fontSize={0}>Advised Via:</P>
                <P fontSize={0}>{item.advisedVia}</P>                
            </Flex>
            <Flex mb="2">
                <P fontWeight="bold" mr="4" fontSize={0}>Document:</P>
                <P fontSize={0}>{item.document}</P>
            </Flex>
            <Flex mb="2">
                <P fontWeight="bold" mr="4" fontSize={0}>Comments:</P>
                <P fontSize={0}>{item.comments}</P>
            </Flex>
            <Button onClick={() => window.open(item.link, '_blank')} color="outline" leftIcon={<Icon name="Document" size="12px"/>} iconColor="warn" iconSmall>
                <Box width={[10, 10, 'auto']} overflow="hidden" >
                    <Text color="warn" uppercase fontSize="12px" ellipsis>Upload outstanding document(s)</Text>
                </Box>
            </Button>
        </Flex>);

        return {
            id: index.toString(),
            heading: item.document,
            icon: 'FlagCircle',
            iconColor: 'warn',
            content: content,
            open: false,
        };
    }), [outstandingRequirements]);

    const hasOutstandingRequirements = !(currentStatus === "Not proceeding matter") && !!outstandingRequirements.length;

    return (
        <>
            {(isAddingFileNote) && <PageLoading backgroundColor="baseOpacity60" />}   
            {getHeaderForStatus((currentStatus === "Not proceeding matter") ? currentStatus :(hasOutstandingRequirements? 'Delayed' : ((currentStatus === "Settled")? currentStatus : 'InProgress')))}
            {hasOutstandingRequirements && <StepsItem>
                <StepsItem.List items={outstandingItems} p="2" />
            </StepsItem>}
            { hasOutstandingRequirements  && <ReminderContainer>
                <Icon name="WarningCircle" color="base20" size="18px" mr="4"/>
                <Text color="base60" fontSize="0">Please ignore any outstanding notifications if you have successfully actioned them—they will clear after 48 hours.</Text>
            </ReminderContainer>}
            { <ORFlex>
                <UnpaddedDropdown value="-1"
                    onChange={(e) => {       
                        if(isNaN(e.target.value)) return;                                     
                        setShowModal(true);   
                        setSelectedIndex(e.target.value);
                        clearFileNoteParams();
                        setFileNoteParams({...fileNoteParams, name: fileNoteNames[e.target.value]})                           
                    }}
                >
                    <option>Add File Note</option>
                    {fileNoteNames.map((x, index) => (
                        <option key={x} value={index}>
                            {x}
                        </option>
                    ))}
                </UnpaddedDropdown>                
            </ORFlex> }
            {showModal && (
                <Modal>
                    <Modal.Close onClose={() => {setShowModal(false);}} />
                    <Modal.Body>
                        <Modal.Title>New file note</Modal.Title>
                        <ModalFormContainer>
                            <UnpaddedDropdown defaultValue={selectedIndex}
                                onChange={(e) => {
                                    if(isNaN(e.target.value)) return; 
                                    setSelectedIndex(e.target.value);
                                    clearFileNoteParams();
                                    setFileNoteParams({...fileNoteParams, name: fileNoteNames[e.target.value]});                                    
                                }}
                            >
                                {fileNoteNames.map((x, index) => (
                                    <option key={x} value={index}>
                                        {x}
                                    </option>
                                ))}
                            </UnpaddedDropdown>
                            {fileNotes[selectedIndex].fields.map(renderField)}
                        </ModalFormContainer>
                        <Modal.ActionContainer>
                            <Button color="base" mr={[4]} onClick={() => {
                                setShowModal(false); 
                                clearFileNoteParams();
                                }}>
                                Cancel
                            </Button>
                            <Button
                                color="tertiary"  
                                onClick={async () => {                                                                                                         
                                    await addFileNoteAction(matterId, fileNoteParams);
                                    setShowModal(false);    
                                    clearFileNoteParams();                                                                                                      
                                }}                                                                                        
                            >
                                Save
                            </Button>
                        </Modal.ActionContainer>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

const OutstandingRequirementsCard =  ({ matterId, outstandingRequirements, currentStatus }) => (
    <UnmarginedPanel>
        <OutstandingRequirements matterId={matterId} outstandingRequirements={outstandingRequirements} currentStatus = {currentStatus}/>
    </UnmarginedPanel>
)

export default OutstandingRequirementsCard;