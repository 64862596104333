import React from 'react';
import PropTypes from 'prop-types';
import { PageLoading } from '@galilee/lilee';
import TrackNTraceMatterProvider, { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import { matchPath } from 'react-router-dom';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import MatterHistory from '../components/MatterHistory';
import MatterCurrentStep from '../components/MatterCurrentStep';
import Layout from 'components/Layout';
import PhoneSupport from 'components/PhoneSupport';
import MatterHeader from 'components/MatterHeader';
import { Routes, Role } from 'utils/constants';

const Journey = ({ matterId }) => {
    const {
        isAdmin,
        user,
    } = useTrackNTrace();
    const { isLoadingMatterJourney } = useTrackNTraceMatter();

    if (isLoadingMatterJourney) return <PageLoading />;

    const isBorrower = user?.roles.includes(Role.Borrower);
    const showPhoneSupport = !isAdmin && !isBorrower;

    return (
        <>
            <Layout header={<MatterHeader />}>
                <MatterCurrentStep />
                <MatterHistory />
            </Layout>
            {showPhoneSupport && <PhoneSupport matterId={matterId} />}
        </>
    );
};

const MatterJourney = () => {
    const pathMatch = matchPath(window.location.pathname, { path: '/:id', exact: true });
    const isDelegateRoute = pathMatch.url === Routes.delegate;

    const matterId = isDelegateRoute ? undefined : pathMatch?.params?.id;

    return (
        <TrackNTraceMatterProvider matterId={matterId}>
            <Journey matterId={matterId} />
        </TrackNTraceMatterProvider>
    );
};

Journey.propTypes = {
    matterId: PropTypes.string,
};

export default MatterJourney;
