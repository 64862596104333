import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import RootProvider from 'state/RootProvider';

ReactDOM.render(
    <RootProvider>
        <App />
    </RootProvider>,
    document.getElementById('root')
);
