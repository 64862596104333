import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { toast } from '@galilee/lilee';
import { useApplication } from 'state/ApplicationProvider';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import {
    getCallbacksAsync,
    pickupCallbacksAsync,
    deleteCallbacksAsync,
    finalizeCallbacksAsync,
    setCallbackUnansweredAsync,
    releaseCallbacksAsync,
} from 'actions/Track';
import { useHistory } from 'react-router';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Routes } from 'utils/constants';

const CallbackStatus = {
    Queued: 'Queued',
    Active: 'Active',
    Deleted: 'Deleted',
    Finalised: 'Finalised',
    ERRORED: 'Errored',
};

const TrackNTraceAdminContext = createContext();

function useTrackNTraceAdminHook() {
    const { dispatch: applicationDispatch } = useApplication();
    const {
        user,
        actions: { getToken },
    } = useTrackNTrace();
    const [me, setMe] = useState(null);
    const [callbacks, setCallbacks] = useState([]);
    const [callbackStatus, setCallbackStatus] = useState('');

    const history = useHistory();

    const getTokenRef = useRef();

    useEffect(() => {
        setMe(user);
    }, [user]);

    useEffect(() => {
        if (!getTokenRef.current) {
            getTokenRef.current = getToken;
        }
    }, [getToken]);

    // Create websocket connection
    useEffect(() => {
        if (!me?.email) return;

        const createHubConnection = async () => {
            const connection = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_BASE_URL}/adminhub`, { accessTokenFactory: () => getTokenRef.current() })
                .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 120000, 180000])
                .build();

            connection.onreconnecting(() => applicationDispatch({ type: 'SET_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Callback' }));
            connection.onreconnected(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Callback' }));

            connection.on('UpdateCallback', (callback) => {
                setCallbacks((prevCallbacks) => {
                    if (callback.status === CallbackStatus.Deleted || callback.status === CallbackStatus.Finalised) {
                        return prevCallbacks.filter((c) => c.callBackId !== callback.callBackId);
                    }

                    const existing = prevCallbacks.find((c) => c.callBackId === callback.callBackId);
                    if (!existing) {
                        return [...prevCallbacks, callback];
                    }

                    return prevCallbacks.map((c) => (c.callBackId === existing.callBackId ? callback : c));
                });
            });

            connection
                .start()
                .then(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_ERROR', payload: 'Callback' }))
                .catch(() => applicationDispatch({ type: 'SET_WEBSOCKET_ERROR', payload: 'Callback' }));
        };

        createHubConnection();
    }, [applicationDispatch, me?.email]);

    useEffect(() => {
        async function getCallbacks() {
            try {
                const token = await getTokenRef.current();
                const result = await getCallbacksAsync(token);
                const activeCallbacks = (result || []).filter((i) => i.finalisedDateTime === null);
                setCallbacks(activeCallbacks);
            } catch (err) {                
                if(`${err}` === `Unauthorized`) history.push(Routes.login.base);
                else toast.error(`Failed to get callbacks. ${err}`);                
            }
        }
        if (!me) return;
        getCallbacks();
    }, [callbackStatus, history, me]);

    const pickupCallback = async (callbackId) => {
        try {
            const token = await getTokenRef.current();
            if (!token) return;

            await pickupCallbacksAsync(me?.email, callbackId, token);
        } catch (err) {
            toast.error(`Failed to start callback. ${err}`);
            setCallbackStatus(CallbackStatus.ERRORED);
        }
    };

    const deleteCallback = async (callbackId) => {
        try {
            const token = await getTokenRef.current();
            if (!token) return;

            await deleteCallbacksAsync(me?.email, callbackId);
        } catch (err) {
            toast.error(`Failed to delete callback ${callbackId}`);
            setCallbackStatus(CallbackStatus.ERRORED);
        }
    };

    const finalizeCallback = async (callbackId) => {
        try {
            const token = await getTokenRef.current();
            if (!token) return;

            await finalizeCallbacksAsync(me?.email, callbackId, token);
        } catch (err) {
            toast.error(`Failed to compete callback ${callbackId}`);
            setCallbackStatus(CallbackStatus.ERRORED);
        }
    };

    const releaseCallback = async (callbackId) => {
        try {
            const token = await getTokenRef.current();
            if (!token) return;

            await releaseCallbacksAsync(me?.email, callbackId, token);
        } catch (err) {
            toast.error(`Failed to return the callback ${callbackId} to callback queue.`);
            setCallbackStatus(CallbackStatus.ERRORED);
        }
    };

    const setCallbackUnanswered = async (callbackId) => {
        try {
            const token = await getTokenRef.current();
            if (!token) return;

            await setCallbackUnansweredAsync(me?.email, callbackId, token);
        } catch (err) {
            toast.error(`Failed to set callback ${callbackId} unanswered.`);
            setCallbackStatus(CallbackStatus.ERRORED);
        }
    };

    const actions = {
        pickupCallback,
        deleteCallback,
        finalizeCallback,
        releaseCallback,
        setCallbackUnanswered,
    };

    return {
        me,
        callbacks,
        actions,
    };
}

export const useTrackNTraceAdmin = () => useContext(TrackNTraceAdminContext);

export default function TrackNTraceAdminProvider(props) {
    const value = useTrackNTraceAdminHook();
    return <TrackNTraceAdminContext.Provider value={value} {...props} />;
}
