import React from 'react';
import { Route, Switch } from 'react-router';
import Track from './screens';
import { ToastNotifications } from '@galilee/lilee';
import ConnectionError from 'components/ConnectionError';
import { useApplication } from 'state/ApplicationProvider';
import ThirdPartyScripts from 'components/ThirdPartyScripts';

const App = () => {
    const { state } = useApplication();
    return (
        <>
            <ToastNotifications autoCloseDuration={5} />
            <ConnectionError disconnectionErrors={state.disconnectionErrors} socketConnectionErrors={state.socketConnectionErrors} />
            <ThirdPartyScripts />
            <Switch>
                <Route path="/" component={Track} />
            </Switch>
        </>
    );
};

App.displayName = App.name;

export default App;
