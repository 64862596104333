import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import styled from 'styled-components/macro';

import { H6, Hide } from '@galilee/lilee';
import { OrionTheme } from 'theme';
import { Routes } from 'utils/constants';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import { useRouteMatch } from 'react-router';

const Title = styled(H6)`
    font-family: ${(p) => p.theme.fonts.title};
    font-size: ${(p) => p.theme.fontSizes[0]};
`;

const DetailSwitch = () => {
    const history = useHistory();
    const match = useRouteMatch();

    const { matterId } = useTrackNTraceMatter();

    const isInDetailPage = match.path.includes(Routes.matter.detail);

    if (!matterId) return null;

    return (
        <Hide breakpoints={[0, 1, 2, 3]}>
            <Switch
                checked={!isInDetailPage}
                onChange={() => {
                    isInDetailPage
                        ? history.push(Routes.matter.base.replace(':id', matterId))
                        : history.push(Routes.matter.detail.replace(':id', matterId));
                }}
                uncheckedIcon={<Title mb={[0]}>Matter Journey: Off</Title>}
                checkedIcon={<Title mb={[0]}>Matter Journey: On</Title>}
                uncheckedHandleIcon={null}
                checkedHandleIcon={null}
                width={245}
                height={45}
                handleDiameter={37}
                onColor={OrionTheme.colors.tertiary}
            />
        </Hide>
    );
};

export default DetailSwitch;
