import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { toast } from '@galilee/lilee';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { getMatterJourneyAsync, getMatterDetailsAsync, downloadDocument, addFileNoteAsync } from 'actions/Track';

import PropTypes from 'prop-types';

const TrackNTraceMatterContext = createContext();

function useTrackNTraceMatterHook(matterId, loadMatterDetails) {
    const {
        actions: { getToken },
    } = useTrackNTrace();

    const [matterJourney, setMatterJourney] = useState(null);
    const [matterDetails, setMatterDetails] = useState(null);    

    const [isLoadingMatterJourney, setIsLoadingMatterJourney] = useState(false);
    const [isLoadingMatterDetails, setIsLoadingMatterDetails] = useState(false);
    const [isAddingFileNote, setIsAddingFileNote] = useState(false);

    const getTokenRef = useRef();

    useEffect(() => {
        if (!getTokenRef.current) {
            getTokenRef.current = getToken;
        }
    }, [getToken]);

    useEffect(() => {
        async function fetchMatterJourney() {
            try {
                const token = await getTokenRef.current();
                if (!token) return;

                setIsLoadingMatterJourney(true);
                //TODO: the backend needs to rename the property.
                const { matterDetail } = await getMatterJourneyAsync(matterId, token);
                if (!matterDetail) {
                    toast.error(`Matter ${matterId} does not exist.`);
                    return;
                }
                setMatterJourney(matterDetail);
            } catch (err) {
                toast.error(`Failed to get matter ${matterId}`);
            } finally {
                setIsLoadingMatterJourney(false);
            }
        }
        if (!matterId || loadMatterDetails) return;

        fetchMatterJourney();
    }, [matterId, loadMatterDetails]);

    useEffect(() => {
        async function fetchMatterDetails() {
            try {
                const token = await getTokenRef.current();
                if (!token) return;
                
                setIsLoadingMatterDetails(true);
                const { matterDetails }  = await getMatterDetailsAsync(matterId, token);
                console.log(matterDetails);
                if (!matterDetails) {
                    toast.error(`Matter ${matterId} does not exist.`);
                    return;
                }
                setMatterDetails(matterDetails);
            } catch (err) {
                toast.error(`Failed to get details for matter ${matterId}`);
            } finally {
                setIsLoadingMatterDetails(false);
            }
        }
        if (!matterId || !loadMatterDetails) return;

        fetchMatterDetails();
    }, [matterId, loadMatterDetails]);

    const downloadDocumentAction = async (documentId, fileName) => {
        const token = await getTokenRef.current();
        if(!token) return;

        const blob= await downloadDocument(documentId, token);
            const blobUrl = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
    }

    const addFileNoteAction = async (matterId, fileNoteParams) => {
        try {
            const token = await getTokenRef.current();
            if(!token) return;
            setIsAddingFileNote(true);            
            const fileNote = await addFileNoteAsync(matterId, fileNoteParams, token);   
            if(fileNote.id > 0) 
            {
                toast.success(`File note added for matter ${matterId}`);    
                const { matterDetails }  = await getMatterDetailsAsync(matterId, token);          
                setMatterDetails(matterDetails);
            }
            return fileNote;
        } catch (err) {
            toast.error(`Failed to add file note for matter ${matterId}`);
        } finally {
            setIsAddingFileNote(false);
        }         
    }

    return {
        matterId,
        isMatterDetailsPage : !!loadMatterDetails,
        matterJourney,
        matterDetails,
        isLoadingMatterJourney,
        isLoadingMatterDetails,
        downloadDocumentAction,
        addFileNoteAction,
        isAddingFileNote        
    };
}

export const useTrackNTraceMatter = () => useContext(TrackNTraceMatterContext);

export default function TrackNTraceMatterProvider({ children, matterId, loadMatterDetails }) {
    const value = useTrackNTraceMatterHook(matterId, loadMatterDetails);
    return <TrackNTraceMatterContext.Provider value={value}>{children}</TrackNTraceMatterContext.Provider>;
}

TrackNTraceMatterProvider.propTypes = {
    matterId: PropTypes.string,
    children: PropTypes.node.isRequired,
    loadMatterDetails: PropTypes.bool
};
