import React from 'react';
import { Flex,Badge,Text } from '@galilee/lilee';
import styled from 'styled-components/macro';
import format from 'date-fns/format';
import { StyledLink } from '.';

const Container = styled(Flex)`
    flex-direction: column;
    max-width: 600px;
    gap: ${(p) => p.theme.space[5]};
    box-shadow: 0px 0px 12px rgba(7, 36, 56, 0.05);
    border-radius: 10px;
    padding: ${(p) => p.theme.space[6]} ${(p) => p.theme.space[4]};
`;

const DataRow = styled(Flex)`
    justify-content: space-between;
`;

const DataColumn = styled(Flex)`
    flex-direction: column;
    font-size: ${(p) => p.theme.fontSizes[1]};
    color: ${(p) => p.theme.colors.base};
`;

const DataCard = ({ data, onClick, ...props }) => {
    const {matterID, status, borrower, security, titleRef, loanAccount, type, instructionDate, settlementDate } = data;
    return (
        <Container {...props}>
            <DataRow>
                <StyledLink onClick={() => onClick(matterID)}>
                    Matter {matterID}
                </StyledLink>
                <Badge color="tertiary" icon="CheckCircle" minWidth="100px">
                    {status}
                </Badge>
            </DataRow>
            <DataColumn>
                <Text>
                    Borrower
                </Text>
                <Text>
                    {borrower}
                </Text>
            </DataColumn>
            <DataColumn>
                <Text>
                    Security
                </Text>
                <Text>
                    {security}
                </Text>
            </DataColumn>
            <DataColumn>
                <Text>
                    Title Ref
                </Text>
                <Text>
                    {titleRef}
                </Text>
            </DataColumn>
            <DataRow width={['100%', '100%', '100%', '60%']}>
                <DataColumn width="50%">
                    <Text>
                        Loan Acc
                    </Text>
                    <Text>
                        {loanAccount}
                    </Text>
                </DataColumn>
                <DataColumn width="50%">
                    <Text>
                        Type
                    </Text>
                    <Text>
                        {type}
                    </Text>
                </DataColumn>
            </DataRow>
            <DataRow width={['100%', '100%', '100%', '60%']}>
                <DataColumn width="50%">
                    <Text>
                        Instruction
                    </Text>
                    {!!instructionDate && (
                        <Text>
                        { format(new Date(instructionDate), 'dd/MM/yyyy') }
                    </Text>
                    )}
                </DataColumn>
                <DataColumn width="50%">
                    <Text>
                        Settlement
                    </Text>
                   {!!settlementDate && (
                    <Text>
                        { format(new Date(settlementDate), 'dd/MM/yyyy') }
                    </Text>
                   )} 
                </DataColumn>
            </DataRow>
        </Container>
    );
}

export default DataCard;