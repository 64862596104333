import PropTypes from 'prop-types';
import { Hide, H1, Column, Row } from '@galilee/lilee';
import styled from 'styled-components/macro';
import Logo from 'components/Logo';
import AuthenticatedHeader from 'components/AuthenticatedHeader';
import AdvancedSearchButton from 'components/AdvancedSearchButton';
import HamburgerMenu from 'components/HamburgerMenu';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import Breadcrumbs from './Breadcrumbs';
import LoggedInUser from 'components/LoggedInUser';
import { Role } from 'utils/constants';

const HeaderMainRow = styled(Row)`
    flex-direction: row;
    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[0]}) and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        flex-direction: column;
    }
`;

const HeaderMiddleColumn = styled(Column)`
    justify-content: center;
    margin-bottom: 0;
    flex: ${(p) => p.colspan ?? 2};
`;

const HeaderLeftTitle = styled(H1)`
    margin-top: ${(p) => p.theme.space[4]};
    margin-bottom: ${(p) => p.theme.space[4]};

    @media screen and (max-width: ${(p) => p.theme.breakpoints[1]}) {
        margin-top: ${(p) => p.theme.space[1]};
    }
`;

const HomeHeader = ({ title, middleColSpan, showBreadcrumbs, showSearch, children }) => {
    const { user } = useTrackNTrace();
    const showButton = showSearch === undefined ? [Role.Admin, Role.Lender, Role.Broker].includes(user?.role) : showSearch;
    return (
        <>
            <AuthenticatedHeader>
                <HeaderMainRow>
                    <Column colspan="1" mb="0">
                        <Logo />
                        <HeaderLeftTitle>{title}</HeaderLeftTitle>
                        {showButton && (
                            <Hide breakpoints={[0, 1]}>
                                <AdvancedSearchButton tight />
                            </Hide>
                        )}
                        {showBreadcrumbs && <Breadcrumbs />}      
                        <LoggedInUser />                             
                    </Column>
                    <HeaderMiddleColumn colspan={middleColSpan}>{children}</HeaderMiddleColumn>
                    <Column colspan="1" hideOn={[0]}>
                        <div />
                    </Column>
                </HeaderMainRow>
            </AuthenticatedHeader>
            <HamburgerMenu />
        </>
    );
};

HomeHeader.propTypes = {
    title: PropTypes.string.isRequired,
    middleColSpan: PropTypes.number,
    showBreadcrumbs: PropTypes.bool,
    showSearch: PropTypes.bool,
};

export default HomeHeader;
