import { PageLoading, Box } from '@galilee/lilee';
import Layout from 'components/Layout';
import HomeHeader from 'components/HomeHeader';
import SearchForm from 'components/SearchForm';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import SearchResults from 'components/SearchResults';
import styled from 'styled-components/macro';
import { toast } from '@galilee/lilee';
import { useState, useEffect } from 'react';

const Toggle = styled(Box)`
    display: block;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[3]}) {
        display: ${(p) => p.show ? 'block' : 'none'};
    }
`;

const Search = () => {
    const { actions: { search: searchAction, exportSearchResults: exportSearchResultsAction  } } = useTrackNTrace();
    const [isSearching, setIsSearching] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [searched, setSearched] = useState(false);
    const [allCleared, setAllCleared] = useState(false);

    const [searchParams, setSearchParams] = useState({
        loanAccountNumber: "",
        matterNo: 0,
        facilityNumber: "",
        borrowerName: "",
        propertyAddress: "",
        titleReference: "",
        instructionDate: undefined,
        settlementDate: undefined,
        broker: "",
        returnAllResults: false
      });

      const [filters, setFilters] = useState({
        loanPurpose: "All Types",
        settlementType: "All Types",
        flags: "None Selected",
        matterType: "All Types",
        matterStatus: "All Active Statuses",
        quickSearches: "Nothing Selected",
      });

      const [sort, setSort] = useState({
        sortBy: "Borrower",
        sortByOrder: "desc",
      });

      const [pagination, setPagination] = useState({
        itemsPerPage: 10,
        searchPage: 1,
      });

      const doSearch = async () => {
        try {
            setIsSearching(true);
            await searchAction({...searchParams, ...filters, ...sort, ...pagination});
        }
        catch (err) {
            toast.error(`Failed to load matters. ${err}`);
        }
        finally {
            setIsSearching(false);
            setSearched(true);
        }
      };

      const exportSearchResults = async () => {
        try {
            setIsExporting(true);
            await exportSearchResultsAction({...searchParams, ...filters, ...sort, ...pagination, returnAllResults: true });
        }
        catch (err) {
            toast.error(`Failed to export search results. ${err}`);
        }
        finally {
            setIsExporting(false);
        }
      }

      useEffect(() => {
        if(isSearching || !searched || allCleared) return; //  If CLEAR ALL button hit no new search should run unless we hit the SEARCH button again.
        doSearch();
      },[sort, pagination, allCleared]);

      const handleClearAll = () => {
        setAllCleared(true);
        setSearchParams({
            loanAccountNumber: "",
            matterNo: 0,
            facilityNumber: "",
            borrowerName: "",
            propertyAddress: "",
            titleReference: "",
            instructionDate: undefined,
            settlementDate: undefined,
            broker: "",
            returnAllResults: false
          });

          setFilters({
            loanPurpose: "All Types",
            settlementType: "All Types",
            flags: "None Selected",
            matterType: "All Types",
            matterStatus: "All Active Statuses",
            quickSearches: "Nothing Selected",
          });

          setSort({
            sortBy: "Borrower",
            sortByOrder: "desc",
          });

          setPagination({
            itemsPerPage: 10,
            searchPage: 1,
          });
      };

    return (
        <Layout header={<HomeHeader title="Advanced Search" showBreadcrumbs showSearch={false} />}>
            {(isSearching || isExporting) && <PageLoading backgroundColor="baseOpacity60" />}
            <Toggle show={!searched || isSearching}>
                <SearchForm
                    onSearch={() => doSearch()}
                    searchParams={searchParams}
                    onSearchParamChange={(newValue) => setSearchParams({...searchParams, ...newValue}) }
                    filters={filters}
                    onFilterChange={(newValue) => setFilters({...filters, ...newValue})}
                    onClearAll={handleClearAll}
                />
            </Toggle>
            {(searched && !isSearching) && (
                <SearchResults
                    onSearch={() => {
                        setSearched(false);
                        doSearch();
                    }} 
                    sort={sort}
                    onSortChange={(newValue) => {
                      setAllCleared(false);
                      setSort({...sort, ...newValue})
                    }}
                    pagination={pagination}
                    onPaginationChange={(newValue) => {
                      setAllCleared(false);
                      setPagination({...pagination, ...newValue})
                    }}
                    onExport={exportSearchResults}
                />

            )}
        </Layout>);
};

export default Search;
