import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, H5, Icon } from '@galilee/lilee';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Routes,Role } from 'utils/constants';
import { useRouteMatch } from 'react-router';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';

const UnselectedTitle = styled(H5)`
    text-decoration: underline;
    text-transform: none;
    font-family: ${(p) => p.theme.fonts.title};
    font-size: ${(p) => p.theme.fontSizes[1]};
`;

const SelectedTitle = styled(H5)`
    text-transform: none;
    font-family: ${(p) => p.theme.fonts.title};
    font-size: ${(p) => p.theme.fontSizes[1]};
`;

const BreadcrumbLinkButton = styled.button`
    background: none;
    cursor: pointer;
    user-select: none;
    border: none;
    outline: none;
    color: ${(p) => p.theme.colors.white};

    * {
        font-weight: ${(p) => p.theme.fontWeights.light};
    }

    // Drop the button padding when it is the first button to align it with everything else
    :first-of-type {
        padding-left: 0;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
    }
    padding: 0;

    :focus {
        border: 2px solid #0075bc;
        margin: -2px;
    }

    :active {
        color: ${(p) => p.theme.colors.base40};
    }
`;

const getCrumbs = (user, matterId) => [
    {
        path: '/',
        isRoute: (pn) => pn === '/',
        title: 'Home',
    },
    {
        path: Routes.matter.base,
        isRoute: (pn) => pn === Routes.matter.base,
        title: 'Matter Journey',
        onClickProp: 'matterId',
        onClickReplace: ':id',
        filter: () => !!matterId,
    },
    {
        path: Routes.matter.detail,
        isRoute: (pn) => pn.endsWith(Routes.matter.debugBase) || pn === Routes.matter.detail,
        title: 'Matter Details',
        onClickProp: 'matterId',
        onClickReplace: ':id',
        filter: () => !user?.roles.includes(Role.Borrower) && !!matterId,
    },
];

const BreadcrumbsContainer = styled(Flex)`
    align-items: baseline;
    > svg:last-of-type {
        display: none;
    }
`;

const Breadcrumbs = (props) => {
    const history = useHistory();
    const match = useRouteMatch();

    const { user } = useTrackNTrace();

    let matterId = undefined;

    const context = useTrackNTraceMatter();

    if(context) {
        matterId = context.matterId;
    }
   
    return (
        <BreadcrumbsContainer>
            {getCrumbs(user, matterId)
                .filter((bc) => ('filter' in bc ? bc.filter() : true))
                .map((bc) => {
                    return (
                        <React.Fragment key={bc.title}>
                            <BreadcrumbLinkButton
                                key={bc.title}
                                onClick={
                                    bc.onClickProp && props[bc.onClickProp]
                                        ? () => history.push(bc.path.replace(bc.onClickReplace, props[bc.onClickProp]))
                                        : () => history.push(bc.path)
                                }
                            >
                                {('isRoute' in bc ? bc.isRoute(match.path) : match.path === bc.pathName) ? (
                                    <SelectedTitle>{bc.title}</SelectedTitle>
                                ) : (
                                    <UnselectedTitle>{bc.title}</UnselectedTitle>
                                )}
                            </BreadcrumbLinkButton>
                            <Icon name="NavRight" color="white" width={8} height={8} mx={3} />
                        </React.Fragment>
                    );
                })}
        </BreadcrumbsContainer>
    );
};

Breadcrumbs.propTypes = {
    matterId: PropTypes.string,
};

export default Breadcrumbs;
