import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { Routes } from 'utils/constants';

function ProtectedRoute({ component: Component, path, location, ...rest }) {
    const { isAuthenticated, actions } = useTrackNTrace();

    useEffect(() => {
        if (!actions.setReturnUrl || !location) return;
        
        const returnUrl = location.pathname?.replace('/','') || "";
        actions.setReturnUrl(returnUrl);
    }, [location, actions])
    
    return <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to={Routes.login.base} />)} />;
}

ProtectedRoute.propTypes = {
    component: PropTypes.func.isRequired,
};

export default React.memo(ProtectedRoute);
