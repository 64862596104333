import { Box, Text, Flex, Hide, P } from '@galilee/lilee';
import { StyledSubtitle, UnmarginedPanel, InlineText, SizedIcon, ItemTitle, BackToTopLink } from "./index";
import { format } from 'date-fns';
import styled from 'styled-components/macro';
import { getUTCDate } from 'utils';

const DocumentContainer = styled(Box)`
    display : grid;
    grid-template-rows: 1fr;
    grid-template-columns: 50% auto auto;
    justify-content: space-between;
    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[4]}) {
        grid-template-columns: 1fr;
    }
`;

const Header = styled(Box)`
    display : grid;
    grid-template-rows: 1fr;
    grid-template-columns: 60% auto auto;
    justify-content: space-between;
    border-bottom: 1px solid ${(p) => p.theme.colors.base10};
`;

const SpecialConditionsCard = ({ specialConditions }) => {
    const renderStatus = (status) => {
        switch (status) {
            case 'ConditionSatisfied':
                return <Text color="success" fontSize="0">Condition satisfied</Text>;
            case 'ConditionUnsatisfied':
                return <Text color="warn" fontSize="0">Not satisfied</Text>;
            default:
                return <Text fontSize="0">{status}</Text>;;
        }
    };

    return (
        <UnmarginedPanel id="specialConditions">
            <Flex justifyContent="space-between">
                <StyledSubtitle>Special Conditions</StyledSubtitle>
                <BackToTopLink />
            </Flex>
            <Header>
                <ItemTitle>Description</ItemTitle>
                <Hide breakpoints={[0, 1, 2, 3, 4]}>
                    <ItemTitle>Status</ItemTitle>
                </Hide>
                <Hide breakpoints={[0, 1, 2, 3, 4]}>
                    <ItemTitle>Last Updated</ItemTitle>
                </Hide>
            </Header>
            <Flex flexDirection="column">
                {specialConditions?.map((specialCondition, idx) => (
                    <DocumentContainer key={specialCondition.description + idx} mt="4">
                        <Flex alignItems="flex-start" mb="2">
                            <SizedIcon mr="4" color="base40" name={specialCondition.icon} />
                            <InlineText>{specialCondition.description}</InlineText>
                        </Flex>
                        <Box ml={[7, 7, 7, 7, 7, 0]} mb="2">{renderStatus(specialCondition.status)}</Box>
                        {specialCondition.date && <P ml={[7, 7, 7, 7, 7, 0]} mb="2" color="base60">{format(getUTCDate(new Date(specialCondition.date)), 'dd LLL yy HH:mm a')}</P>}
                    </DocumentContainer>
                ))}
            </Flex>
        </UnmarginedPanel>
    );
};

export default SpecialConditionsCard;