import React, { useEffect, useState } from 'react';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import Layout from 'components/Layout';
import PhoneSupport from 'components/PhoneSupport';
import Logo from 'components/Logo';
import { Flex, Row, Column, H1, H6, Button, Text, Input, Panel, Box, FieldError, Icon, toast, HR, PageLoading } from '@galilee/lilee';
import styled from 'styled-components/macro';
import HamburgerMenu from 'components/HamburgerMenu';
import Breadcrumbs from 'components/Breadcrumbs';
import LoggedInUser from 'components/LoggedInUser';
import { isValidEmail } from 'utils';
import DelegationProvider, { useDelegation } from 'state/DelegationProvider';
import { Role } from 'utils/constants';

const Title = styled(H1)`
    margin-top: ${(p) => p.theme.space[2]};
    font-size: ${(p) => p.theme.fontSizes[3]};

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        display: none;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        margin-top: ${(p) => p.theme.space[2]};
        font-size: ${(p) => p.theme.fontSizes[4]};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[4]}) {
        font-size: ${(p) => p.theme.fontSizes[5]};
    }
`;

const HeaderContainer = styled(Flex)`
    height: 200px;
    justify-content: center;
    align-items: center;
    padding-left: 84px;
    padding-right: 36px;
    padding-top: 44px;
    padding-bottom: 44px;

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[1]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        padding-left: 36px;
        padding-right: 36px;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        padding-left: 24px;
        padding-right: 24px;

        flex-direction: row;
    }
`;

const DelegatePanel = styled(Panel)`
    max-width: 700px;
    margin-top: ${(p) => p.theme.space[8]};
    margin-bottom: ${(p) => p.theme.space[8]};
    border-radius: ${(p) => p.theme.sizes[3]};
    padding: 24px 48px;
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding: 0px;
    }
`;

const StyledBox = styled(Box)`
    padding-top: ${(p) => p.theme.space[6]};
    padding-bottom: ${(p) => p.theme.space[6]};
    @media screen and (max-width: ${(p => p.theme.breakpoints[1])}) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const DelegateList = styled(Flex)`
    flex-direction: column;
    margin-top: ${(p) => p.theme.space[8]};
    gap: ${(p) => p.theme.space[4]};
`;

const RemoveLink = styled(Text)`
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-weight: 350;
    text-decoration: underline;
    cursor: pointer;
    color: #1C27DA;
`;

const DelegateItem = ({ delegate, onDelete}) => {
    return (
        <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
                <Icon size="5" color="black" name="User" mr="4"/>
                <Text fontSize="1">{delegate.email}</Text>
            </Flex>
            <RemoveLink onClick={() => onDelete(delegate)}>Remove</RemoveLink>
        </Flex>
    );
}

const Header = () => (
    <>
        <HeaderContainer bg="base" color="white">
            <Column colspan={10} justifyContent="space-between" mb="0">
                <Flex flexDirection="column" justifyContent="center">
                    <Logo />
                </Flex>
                <Title>
                    Delegate Matters
                </Title>
                <Row mt="6">
                    <Breadcrumbs />
                </Row>
                <Row>
                    <LoggedInUser />
                </Row>
            </Column>
        </HeaderContainer>
        <HamburgerMenu />
    </>
);

const Delegate = () => {
    const [email, setEmail] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const { user: me } = useTrackNTrace();
    const { getDelegations, addDelegation, deleteDelegation, revokeGroupDelegation, delegations } = useDelegation();

    useEffect(() => {
        async function fetchData() {
            if (!me || !me.roles.includes(Role.Broker)) return;
            await getDelegations();

        }
        fetchData();
    }, [me, getDelegations]);

    const handleValidation = () => {
        if (!email) {
            setValidationMessage('Please enter an email');
            return;
        }

        const isValid = isValidEmail(email);
        if (!isValid) {
            setValidationMessage('Invalid email address');
            return;
        }

        setValidationMessage('');
    }

    const handleAddDelegation = async () => {
        if (isAdding || validationMessage) return;

        if (me?.email.toLowerCase() === email.toLowerCase()) {
            toast.error('You cannot delegate to yourself.');
            return;
        }
        
        if (delegations.find((d) => d.email.toLowerCase() === email.toLowerCase())) {
            toast.error(`You've already granted access to ${email}`);
            return;
        }
        try {
            setIsAdding(true);
            await addDelegation(email);
        } catch (e) {
            toast.error(`Failed to grant access to ${email}`);
        } finally {
            setIsAdding(false);
            setEmail('');
        }
    }

    const handleRemoveDelegation = async (delegate) => {
        if (delegate.isGroupDelegation) {
            await revokeGroupDelegation(delegate.id);
            await getDelegations();
            return;
        }
        
        await deleteDelegation(delegate.id);
    }

    return (
        <>
            {isAdding && <PageLoading backgroundColor="baseOpacity60" />}
            <Layout header={<Header />}>
                <DelegatePanel mx="auto">
                    <StyledBox>
                        <H1 fontSize="4" color="tertiary" mb="5">Delegate access to all your matters</H1>
                        <Text mb="7" mx="auto" textAlign="left" fontSize="1">
                            Orion allows you to delegate access to admin or support staff within your organisation so anyone who needs up to date information can access it easily. Simply add their email address and we will send them a notification informing them that they have been delegated access to matters from you.
                        </Text>
                        <Text mb="7" mx="auto" textAlign="left" fontSize="1">
                            Note: delegation gives access to all active matters in Orion and any future matters. Do not grant access to anyone outside of your organisation.
                        </Text>
                        <H6 mt="8" mb="5" fontSize="0">
                            Email Address
                        </H6>
                        <Input fontSize="2" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={handleValidation} disabled={isAdding}/>
                        {validationMessage && <FieldError error={validationMessage} />}
                        <Button mt="6" onClick={handleAddDelegation} disabled={isAdding} color="tertiary">
                            Add
                        </Button>
                        {!!delegations.length && <DelegateList>
                            <Box>
                                <Text fontSize="0" fontWeight="500" color="base40" uppercase>Users</Text>
                                <HR mt="2" />
                            </Box>
                            {
                                delegations.map((delegate) => <DelegateItem delegate={delegate} onDelete={handleRemoveDelegation} />)
                            }
                        </DelegateList>}
                    </StyledBox>
                </DelegatePanel>
            </Layout>
            <PhoneSupport />
        </>
    );
};

const DelegateMatters = () => {
    return (
        <DelegationProvider>
            <Delegate />
        </DelegationProvider>
    );
};

export default DelegateMatters;
