import { Button, Icon, Hide } from '@galilee/lilee';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { Routes } from 'utils/constants';

const HeaderIcon = styled(Icon)`
    height: 12px !important;
    width: 12px !important;
    color: ${(p) => p.theme.colors.secondary} !important;
`;

const SearchButton = styled(Button)`
    justify-content: flex-start;
    font-size: 10px;
    border-width: 2px;

    > div {
        padding-left: ${(p) => p.theme.space[4]};
    }

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        font-size: ${(p) => (p.tight ? '10px' : '12px')};
        > div {
            padding-left: ${(p) => (p.tight ? p.theme.space[2] : p.theme.space[4])};
        }
    }

    // padding: 14px 36px;
    padding: 0;
    :hover,
    :focus,
    :active {
        // padding: 14px 36px;
        padding: 0;
    }

    :hover {
        color: ${(p) => p.theme.colors.secondary60};
    }

    :focus {
        border: 2px solid #0075bc;
        border-radius: 0;
    }

    :active {
        color: ${(p) => p.theme.colors.secondary110};
    }
`;

const AdvancedSearchButton = (props) => {
    const history = useHistory();
    return (
        <Hide breakpoints={[0, 1]}>
            <SearchButton {...props} color="link" leftIcon={<HeaderIcon name="Search" />} onClick={() => history.push(Routes.search)}>
                Advanced Search
            </SearchButton>
        </Hide>
    );
};

export default AdvancedSearchButton;
