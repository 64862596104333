/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Flex, Input ,utils} from '@galilee/lilee';

const { useOnClickOutside } = utils;

const Control = styled(Flex)`
  cursor: pointer;
  position: relative;
  border-radius: 32px !important;
  padding: ${(p) => p.theme.space[5]};
  height: 45px;
  border-width: 0px;
  border-style: solid;
  border-radius: ${(p) => p.theme.radii[2]};
  border-color: ${(p) => p.isFocused ? p.theme.colors.base40 : p.theme.colors.base20};
  box-shadow : ${(p) => p.isFocused ? `0 0 0 2px ${p.theme.colors.base40}` : `0 0 0 1px ${p.theme.colors.base20}`};
`;

const Menu = styled(Flex)`
  border-radius: 10px;
  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%);
  display: flex;
  height: auto;
  overflow: auto;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 10px;
  max-height: 300px;
`;

const Option = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.base10};
  }
`;

const IconWrapper = styled(Box)`
  position: absolute;
  right: 20px;

`;

const StyledInput = styled(Input)`
  border-radius: unset;
`;

const Wrapper = styled(Box)`
  width: ${(p) => p.width};
`;

const Dropdown = ({
  rightIcon, onChange, options, enableSearch, controlStyles, optionStyles, type, width, value, props
}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const option = options.find((o) => o.value === value) || options[0];
        setSelectedOption(option);
    },[value, options])

    const selectRef = useRef();

    useOnClickOutside(selectRef, () => {
        setShowMenu(false);
        setIsFocused(false);
    });

    const handleChange = (option) => {
        setShowMenu(false);
        setSearchValue('');
        setSelectedOption(option);
        onChange(option);
    };

  return (
    <Wrapper ref={selectRef} width={width} onClick={() => setIsFocused(true)} overflow="hidden">
      <Control {...controlStyles} alignItems="center" flexDirection="row" isFocused={isFocused} onClick={() => setShowMenu(!showMenu)} type={type} overflow="hidden" {...props}>
        {selectedOption?.controlLabel || selectedOption?.label}
        {rightIcon ? (
          <IconWrapper>
            {rightIcon}
          </IconWrapper>
        ) : null}
      </Control>
      {showMenu && (
        <Menu flexDirection="column" p={3}>
          {enableSearch ? <StyledInput onChange={(evt) => setSearchValue(evt.currentTarget?.value)} placeholder="Search..." value={searchValue} /> : null}
          {enableSearch
            ? options?.map((option, index) => {
              const searchMatch = option.searchBy
                ? option.searchBy?.toLowerCase().indexOf(searchValue?.toLowerCase()) === 0
                : option.label?.toLowerCase().indexOf(searchValue?.toLowerCase()) === 0;
              const filteredOption = (
                <Option
                  {...optionStyles}
                  key={option.id}
                  onClick={() => { handleChange(option); }}
                >
                  {option.label}
                </Option>
              );
              if ((searchValue === '') || (searchValue && searchMatch)) {
                return filteredOption;
              }
              return null;
            })
            : options?.map((option) => (
              <Option
                {...optionStyles}
                key={option.id}
                onClick={() => { handleChange(option); }}
              >
                {option.label}
              </Option>
            ))}
        </Menu>
      )}
    </Wrapper>
  );
};

Dropdown.defaultProps = {
  enableSearch: false,
  onChange: () => {},
  options: [],
  controlStyles: {
    justifyContent: 'center'
  },
  optionStyles: {
    p: 4,
    bg: 'white',
    width: '100%',
  },
  rightIcon: null,
  width: '100%',
  value: null,
};

Dropdown.propTypes = {
  enableSearch: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.node,
      controlLabel: PropTypes.node,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      searchBy: PropTypes.string,
    }),
  ),
  controlStyles: PropTypes.shape({}),
  optionStyles: PropTypes.shape({}),
  rightIcon: PropTypes.node,
  width: PropTypes.string,
  value: PropTypes.any,
};

export default Dropdown;
