import { H1, H2, Flex, Column, Row } from '@galilee/lilee';
import styled from 'styled-components/macro';
import Logo from 'components/Logo';

import DetailSwitch from 'components/DetailSwitch';
import HamburgerMenu from 'components/HamburgerMenu';
import Breadcrumbs from 'components/Breadcrumbs';
import AuthenticatedHeader from 'components/AuthenticatedHeader';
import AdvancedSearchButton from 'components/AdvancedSearchButton';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import LoggedInUser from 'components/LoggedInUser';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { Role } from 'utils/constants';

const SwitchContainer = styled(Column)`
    align-self: flex-end;
    margin: 0;
`;

const TitleLarge = styled(H1)`
    margin-top: ${(p) => p.theme.space[2]};
    font-size: ${(p) => p.theme.fontSizes[3]};

    @media screen and (max-width: ${(p) => p.theme.breakpointsMax[1]}) {
        display: none;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[3]}) and (max-width: ${(p) => p.theme.breakpointsMax[3]}) {
        margin-top: ${(p) => p.theme.space[2]};
        font-size: ${(p) => p.theme.fontSizes[4]};
    }

    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[4]}) {
        font-size: ${(p) => p.theme.fontSizes[5]};
    }
`;

const TitleSmall = styled(H2)`
    margin-top: ${(p) => p.theme.space[2]};
    margin-bottom: ${(p) => p.theme.space[7]};
    @media screen and (min-width: ${(p) => p.theme.breakpointsMin[2]}) {
        display: none;
    }
`;

const MatterHeader = () => {
    const { matterId, isMatterDetailsPage, matterJourney, matterDetails } = useTrackNTraceMatter(); 
    const { user } = useTrackNTrace();

    const showAdvancedSearchButton = user?.roles.some(r => [Role.Lender, Role.Admin, Role.Broker].includes(r));
    const showMatterDetailSwitch = !user?.roles.includes(Role.Borrower);

    const formatName = (borrower) => {
        if (!borrower) return null;
        if ('firstName' in borrower && borrower.firstName) {
            let s = borrower.firstName[0];

            if ('middleNames' in borrower && borrower.middleNames.length) {
                s += ` ${borrower.middleNames[0][0]}`;
            }

            if ('lastName' in borrower && borrower.lastName) {
                s += ` ${borrower.lastName}`;
            }

            return s;
        }

        return borrower.name;
    };

    const partyName = isMatterDetailsPage ? matterDetails?.party?.borrowers[0].name : matterJourney?.names?.at(0);

    return (
        <>
            <AuthenticatedHeader>
                <Column colspan={10} justifyContent="space-between" mb="0">
                    <Flex flexDirection="column" justifyContent="center">
                        <Logo />
                    </Flex>
                    <TitleLarge>
                        Matter {matterId} {!!partyName ? ` — ${partyName}` : ''}
                    </TitleLarge>
                    <TitleSmall>
                        {matterId} — <br />
                        {isMatterDetailsPage ? formatName(matterDetails?.party?.borrowers[0]) : partyName}
                    </TitleSmall>
                    <Row mt="6">
                        <Breadcrumbs matterId={matterId} />
                        {showAdvancedSearchButton && <AdvancedSearchButton ml="8" />}
                    </Row>
                    <Row>
                        <LoggedInUser /> 
                    </Row>
                </Column>
                <SwitchContainer>                    
                    {showMatterDetailSwitch && <DetailSwitch />}
                </SwitchContainer>
            </AuthenticatedHeader>
            <HamburgerMenu />
        </>
    );
};

export default MatterHeader;
