export const Routes = {
    home: '/',
    search: '/search',
    login: {
        base: '/login',
        code: '/login/withCode',
    },
    admin: {
        base: '/admin',
    },
    delegate: '/delegate',
    report: '/admin/report',
    matter: {
        base: '/:id',
        detail: '/detail/:id',
        list: '/',
    },
    delegationInstruction : '/feature/delegation',
    groupDelegation: '/group-delegation'
};


export const MatterStatus = ['Instructed', 'Documents Issued', 'Documents Returned', 'Documents Checked', 'Ready to Book', 'Settlement Booked', 'Settled'];

//group and orderInGroup are used in tablet and mobile view. 
export const SearchFieldsConfig = [
    {
        name: 'loanAccountNumber',
        displayName: 'loan account number',
        group: 1,
        orderInGroup: 2
    },
   {
        name: 'matterNo',
        displayName: 'file / matter number',
        group: 1,
        orderInGroup: 1
    },
  {
        name: 'facilityNumber',
        displayName: 'win/facility number',
        group: 2,
        orderInGroup: 1
    },
  {
        name: 'borrowerName',
        displayName: 'borrower name',
        group: 1,
        orderInGroup: 3
    },
  {
        name: 'propertyAddress',
        displayName: 'property address',
        group: 1,
        orderInGroup: 4
    },
     {
        name: 'titleReference',
        displayName: 'title reference',
        placeholder: 'e.g. 1033:44, 21/091222 or 506777811',
        group: 2,
        orderInGroup: 2
    },
    {
        name: 'instructionDate',
        displayName: 'instruction date start',
        isDate: true,
        placeholder: 'DD/MM/YYYY',
        validator: (v) => !v || !isNaN(Date.parse(v)),
        group: 2,
        orderInGroup: 3
    },
    {
        name: 'instructionDateEnd',
        displayName: 'instruction date end',
        isDate: true,
        placeholder: 'DD/MM/YYYY',
        validator: (v) => !v || !isNaN(Date.parse(v)),
        group: 2,
        orderInGroup: 4
    },
    {
        name: 'broker',
        displayName: 'Broker',
        placeholder: "Name or Organisation",
        group: 2,
        orderInGroup: 5
    },
    {
        name: 'settlementDate',
        displayName: 'settlement date start',
        isDate: true,
        placeholder: 'DD/MM/YYYY',
        validator: (v) => !v || !isNaN(Date.parse(v)),
        group: 2,
        orderInGroup: 6
    },
    {
        name: 'settlementDateEnd',
        displayName: 'settlement date end',
        isDate: true,
        placeholder: 'DD/MM/YYYY',
        validator: (v) => !v || !isNaN(Date.parse(v)),
        group: 2,
        orderInGroup: 7
    },    
];

export const FiltersConfig = [
    {
        name: 'loanPurpose',
        displayName: 'loan purpose',
        group: 2,
        orderInGroup: 2,
        options:[
            {
                value: 'All Types',
                name: 'All Types'
            },
            {
                value: 'purchase',
                name: 'Purchase'
            },
            {
                value: 'refinance',
                name: 'Refinance'
            },
            {
                value: 'top Up/Internal Refinance',
                name: 'Top Up/Internal Refinance'
            },
            {
                value: 'unencumbered',
                name: 'Unencumbered'
            },
            {
                value: 'construction',
                name: 'Construction'
            },
            {
                value: 'substitution',
                name: 'Substitution'
            },
            {
                value: 'vacant Land',
                name: 'Vacant Land'
            },
            {
                value: 'unregistered Plan',
                name: 'Unregistered Plan'
            },
        ]
    },
    {
        name: 'settlementType',
        displayName: 'settlement type',
        group: 2,
        orderInGroup: 3,
        options:[
            {
                value: 'All Types',
                name: 'All Types'
            },
            {
                value: 'pexa',
                name: 'PEXA'
            },
            {
                value: 'paper',
                name: 'Paper'
            },
            {
                value: 'pexa And Paper',
                name: 'PEXA and Paper'
            },
        ]
    },
    {
        name: 'flags',
        displayName: 'flags',
        group: 2,
        orderInGroup: 4,
        options:[
            {
                value: 'None Selected',
                name: 'None Selected'
            },
            {
                value: 'urgent',
                name: 'Urgent'
            }
        ]
    },
    {
        name: 'matterType',
        displayName: 'matter type',
        group: 2,
        orderInGroup: 1,
        options:[
            {
                value: 'All Types',
                name: 'All Types'
            },
            {
                value: 'commercial',
                name: 'Commercial'
            },
            {
                value: 'mortgages',
                name: 'Mortgages'
            },
        ]
    },
    {
        name: 'matterStatus',
        displayName: 'matter status',
        group: 1,
        orderInGroup: 1,
        options:[
            {
                value: 'All Types',
                name: 'All Active Statuses'
            },
            {
                value: 'instructed',
                name: 'Instructed'
            },
            {
                value: 'documents Issued',
                name: 'Documents Issued'
            },
            {
                value: 'documents Checked',
                name: 'Documents Checked'
            },
            {
                value: 'ready To Book',
                name: 'Ready To Book'
            },
            {
                value: 'settlement Booked',
                name: 'Settlement Booked'
            },
            {
                value: 'matter Settled',
                name: 'Matter Settled'
            },
            {
                value: 'registration',
                name: 'Registration'
            },
            {
                value: 'Non proceeding in the last 3 months',
                name: 'Non proceeding in the last 3 months'
            },
            {
                value: 'Archived',
                name: 'Archived'
            }
        ]
    },
    {
        name: 'quickSearches',
        displayName: 'quick searches',
        group: 1,
        orderInGroup: 2,
        options:[
            {
                value: 'Nothing Selected',
                name: 'Nothing Selected'
            },
            {
                value: 'Matters due to settle in the next 7 days',
                name: 'Matters due to settle in the next 7 days'
            },
            {
                value: 'Matters due to settle in the next 14 days',
                name: 'Matters due to settle in the next 14 days'
            },
            {
                value: 'Matters Instructed in the last 7 days',
                name: 'Matters Instructed in the last 7 days'
            },

            {
                value: 'Matters Settled in the last 7 days',
                name: 'Matters Settled in the last 7 days'
            },
            {
                value: 'Matters with Outstanding Documents',
                name: 'Matters with Outstanding Documents'
            },
            {
                value: 'Matters with Document Preparation Delays',
                name: 'Matters with Document Preparation Delays'
            },
            {
                value: 'Matters with Docs Sent Today (weekdays)',
                name: 'Matters with Docs Sent Today (weekdays)'
            },
            {
                value: 'Matters with Docs Sent Yesterday (weekdays)',
                name: 'Matters with Docs Sent Yesterday (weekdays)'
            },
            {
                value: 'Surplus Account Details Required',
                name: 'Surplus Account Details Required'
            },
        ]
    },
]

export const AdvancedSearchSortRules = [
    {
        title: 'Sort by Borrower-Ascending',
        value: 'borrower-asc',
        rule: { sortBy: 'borrowers', sortAscending: true }
    },
    {
        title: 'Sort by Borrower-Descending',
        value: 'borrower-desc',
        rule: { sortBy: 'borrowers', sortAscending: false }
    },
    {
        title: 'Sort by Status-Ascending',
        value: 'status-asc',
        rule: { sortBy: 'status', sortAscending: true }
    },
    {
        title: 'Sort by Status-Descending',
        value: 'status-desc',
        rule: { sortBy: 'status', sortAscending: false }
    },
    {
        title: 'Sort by Matter No-Ascending',
        value: 'id-asc',
        rule: { sortBy: 'id', sortAscending: true }
    },
    {
        title: 'Sort by Matter No-Descending',
        value: 'id-desc',
        rule: { sortBy: 'id', sortAscending: false }
    },
    {
        title: 'Sort by Loan Acc-Ascending',
        value: 'loanAccount-asc',
        rule: { sortBy: 'loanAccount', sortAscending: true }
    },
    {
        title: 'Sort by Loan Acc-Descending',
        value: 'loanAccount-desc',
        rule: { sortBy: 'loanAccount', sortAscending: false }
    },
    {
        title: 'Sort by Matter Type-Ascending',
        value: 'type-asc',
        rule: { sortBy: 'type', sortAscending: true }
    },
    {
        title: 'Sort by Matter Type-Descending',
        value: 'type-desc',
        rule: { sortBy: 'type', sortAscending: false }
    },
    {
        title: 'Sort by Instruction Date-Ascending',
        value: 'instructionDate-asc',
        rule: { sortBy: 'instructionDate', sortAscending: true }
    },
    {
        title: 'Sort by Instruction Date-Descending',
        value: 'instructionDate-desc',
        rule: { sortBy: 'instructionDate', sortAscending: false }
    },
    {
        title: 'Sort by Settlement Date-Ascending',
        value: 'settlementDate-asc',
        rule: { sortBy: 'settlementDate', sortAscending: true }
    },
    {
        title: 'Sort by Settlement Date-Descending',
        value: 'settlementDate-desc',
        rule: { sortBy: 'settlementDate', sortAscending: false }
    },
]

export const SearchResultColumnDefinition = [
    {
        name: 'matterID', displayName: 'Matter', sortable: true, visible: true, isLink: true
    },
    {
        name: 'instructionDate', displayName: 'Instruction', sortable: true, visible: true, isDate: true
    },
    {
        name: 'settlementDate', displayName: 'Settlement', sortable: true, visible: true, isDate: true
    },
    {
        name: 'status', displayName: 'Status', sortable: true, visible: true,
    },
    {
        name: 'type', displayName: 'Type', sortable: true, visible: true,
    },
    {
        name: 'loanAccount', displayName: 'Loan Acc', sortable: true, visible: true,
    },
    {
        name: 'borrower', displayName: 'Borrower', sortable: true, visible: true,
    },
    {
        name: 'security', displayName: 'Security', sortable: false, visible: true,
    },
    {
        name: 'titleRef', displayName: 'Title Ref', sortable: false, visible: true,
    },
  ];

  export const fileNotes = [
    {
        name: 'Request settlement date',
        fields: [
            {
                key: 1,
                id: 'proposedSettlementDate',
                label: 'Proposed settlement date',
                type: 'date',
                validations: [
                    {
                        value: 'DD/MM/YY',
                        message: 'Must be in DD/MM/YY format',
                    },
                ],
            },
            {
                key: 2,
                id: 'additionalComments',
                label: 'Additional comments',
                type: 'textArea',
            },
        ],
    },
    { 
        name: 'Surplus account details',
        fields: [
            {
                key: 3,
                id: 'accountName',
                label: 'Account name',
                type: 'text',
            },
            {
                key: 4,
                id: 'bsb',
                label: 'BSB',
                type: 'text',
            },
            {
                key: 5,
                id: 'accountNumber',
                label: 'Account number',
                type: 'text',
            },
            {
                key: 6,
                id: 'additionalComments',
                label: 'Additional comments',
                type: 'textArea',
            },
        ],
    },
    {
        name: 'Update party details',
        fields: [
            {
                key: 7,
                id: 'borrowersSolicitor',
                label: "Borrower's Solicitor",
                type: 'text',
            },
            {
                key: 8,
                id: 'contactPerson',
                label: 'Contact person',
                type: 'text',
            },
            {
                key: 9,
                id: 'emailAddress',
                label: 'Email Address',
                type: 'email',
            },
            {
                key: 10,
                id: 'mobileNumber',
                label: 'Mobile Number',
                type: 'text',
            },
            {
                key: 11,
                id: 'additionalComments',
                label: 'Additional comments',
                type: 'textArea',
            },
        ],
    },
    {
        name: 'Construction amount',
        fields: [
            {
                key: 12,
                id: 'retainForConstruction',
                label: 'Retain for construction',
                type: 'currency',
            },
            {
                key: 13,
                id: 'additionalComments',
                label: 'Additional comments',
                type: 'textArea',
            },
        ],
    },
    {
        name: 'Status updates',
        fields: [
            {
                key: 14,
                id: 'description',
                label: 'Description',
                type: 'textArea',
            },
        ],
    },
    {
        name: 'Doc Preparation Enquiry',
        fields: [
            {
                key: 15,
                id: 'description',
                label: 'Description',
                type: 'textArea',
            },
        ],
    },
    {
        name: 'Resolutions Enquiry',
        fields: [
            {
                key: 16,
                id: 'description',
                label: 'Description',
                type: 'textArea',
            },
        ],
    },
    {
        name: 'Funding Enquiry',
        fields: [
            {
                key: 17,
                id: 'description',
                label: 'Description',
                type: 'textArea',
            },
        ],
    },
    {
        name: 'Certifications Enquiry',
        fields: [
            {
                key: 18,
                id: 'description',
                label: 'Description',
                type: 'textArea',
            },
        ],
    },
];

export const Role = {
    Admin: 'Admin',
    Broker: 'Broker',
    Borrower: 'Borrower',

    // This is inserted for clarity to implement menu items, If the backend needs to send this out, then that needs to be done
    Lender: 'Lender',
    GroupDelegationAdmin: 'GroupDelegationAdmin',
    CustomerSupportStaff: 'CustomerSupportStaff'
};

export const GroupDelegationStatus = {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    CANCELLED: 'Cancelled',
    REVOKED: 'Revoked',
};